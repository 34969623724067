import { Alert, Card, CardContent, Grid, Snackbar } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectSellerModel } from "../../apis/general-client";
import CustomTextField from "../../components/custom-text-field";
import DialogComponent from "../../components/dialog-component";
import { CustomText } from "../../components/styled-custom";
import { ReactComponent as PhoneIcon } from "../../assets/icons/Phone.svg";
import EmailIcon from "../../assets/icons/email.svg";
import EmailGreenIcon from "../../assets/icons/email-green.svg";
import { appLoading, currentLanguage } from "../../helpers/utils";
import HomeRepositories from "../home/home-controllers/home-repositories";
import validator from "validator";
import PersonIcon from "../../assets/images/person-icon.png";

export type ProjectDetailSellerType = {
  projectSellers: ProjectSellerModel[] | undefined;
};

export type SellerViewType = {
  avatarUrl: string;
  firstName: string;
  lastName: string;
  phoneNumber1: string;
  email: string;
  titleVi: string;
  titleEn: string;
  titleDescriptionVi: string;
  titleDescriptionEn: string;
};

function ProjectDetailSellers(props: ProjectDetailSellerType) {
  const { t } = useTranslation();
  const [projectSellersData, setProjectSellersData] =
    useState<ProjectDetailSellerType>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [sellerView, setSellerView] = useState<SellerViewType>({
    avatarUrl: "",
    firstName: "",
    lastName: "",
    phoneNumber1: "",
    email: "",
    titleVi: "",
    titleEn: "",
    titleDescriptionVi: "",
    titleDescriptionEn: "",
  });
  const [type, setType] = useState<number>(0);
  const [disable, setDisable] = useState<boolean>(true);
  const [open, setOpenNoti] = useState(false);

  const initialInput: string[] = useMemo(
    () => [
      "subject",
      "nameAndSurname",
      "email",
      "phone",
      "message",
    ],
    []
  );
  const [dataContact, setDataContact] = useState<any>({
    subject: { value: "", error: "" },
    nameAndSurname: { value: "", error: "" },
    email: { value: "", error: "" },
    phone: { value: "", error: "" },
    message: { value: "", error: "" },
  });
  useEffect(() => {
    getProjectSellers();
  }, [props]);

  function getProjectSellers() {
    setProjectSellersData(props);
  }

  const getSellerView = (seller: ProjectSellerModel) => {
    sellerView.avatarUrl = seller.avatarUrl ? seller.avatarUrl : "";
    sellerView.firstName = seller.firstName ? seller.firstName : "";
    sellerView.lastName = seller.lastName ? seller.lastName : "";
    sellerView.phoneNumber1 = seller.phoneNumber1 ? seller.phoneNumber1 : "";
    sellerView.email = seller.email ? seller.email : "";
    sellerView.titleVi = seller.titleVi ? seller.titleVi : "";
    sellerView.titleEn = seller.titleEn ? seller.titleEn : "";
    sellerView.titleDescriptionVi = seller.titleDescriptionVi ? seller.titleDescriptionVi : "";
    sellerView.titleDescriptionEn = seller.titleDescriptionEn ? seller.titleDescriptionEn : "";

    setSellerView(sellerView);
    setDataContact({
      subject: { value: "", error: "" },
      nameAndSurname: { value: "", error: "" },
      email: { value: "", error: "" },
      phone: { value: "", error: "" },
      message: { value: "", error: "" },
    });
  };
  const getDataContact = (e: ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    dataContact[targetName].value = targetValue;
    dataContact[targetName].error = "";
    // dataContact[e.target.name] = e.target.value;
    setDataContact({ ...dataContact });
    const objContact = Object.entries(dataContact).filter(
      ([object, key]) => key === "" && object !== "phone"
    );

    setDisable(objContact.length !== 0);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };
  async function onSent() {
    onValidate();

    let contact: any = {
      subject: dataContact.subject.value,
      name: dataContact.nameAndSurname.value,
      phone: dataContact.phone.value,
      email: dataContact.email.value,
      message: dataContact.message.value,
      projectId: projectSellersData?.projectSellers
        ? projectSellersData?.projectSellers[0]?.projectId
        : "",
      propertyId: "",
      contactType: type,
    };

    if (
      !dataContact["subject"].error &&
      !dataContact["nameAndSurname"].error &&
      !dataContact["email"].error &&
      !dataContact["message"].error
    ) {
      appLoading.show();
      const resData = await HomeRepositories.createContact(contact);
      resData &&
        setDataContact({
          subject: { value: "", error: "" },
          nameAndSurname: { value: "", error: "" },
          email: { value: "", error: "" },
          phone: { value: "", error: "" },
          message: { value: "", error: "" },
        });
      if (resData) {
        setOpenNoti(true);
        setOpenDialog(false);
      }
      // setOpenNoti(true);
      // setDisable(true);
      // setOpenDialog(false)
      appLoading.dismiss();
    }
  }
  const onValidate = () => {
    Object.keys(dataContact).forEach((key: any, index: number) => {
      const _inputValue = dataContact[key];

      if (key !== "phone" && (type === 4 && key !== "subject") && _inputValue.value.length === 0) {
        _inputValue.error = "requiredField";
      } else {
        if (key === "email") {
          _inputValue.error = validator.isEmail(_inputValue.value)
            ? ""
            : "emailInvalidate";
        }
      }
    });

    setDataContact({ ...dataContact });
  };
  return (
    <>
      {projectSellersData?.projectSellers &&
      projectSellersData?.projectSellers.length > 0 ? (
        projectSellersData?.projectSellers.map(
          (item: ProjectSellerModel, index: number) => (
            <Card key={index} className="card-item seller-card">
              <CardContent className="seller">
                <div className="seller-avatar">
                  <img src={item.avatarUrl || PersonIcon} alt="seller avatar" />
                </div>

                <CustomText className="semiBold greenLight textCenter fontMP">
                  {item.firstName} {item.lastName}
                </CustomText>
                <CustomText className="textCenter">
                  {currentLanguage.isEN ? (item.titleEn ? item.titleEn : t("broker")) : (item.titleVi ? item.titleVi : t("broker"))}
                </CustomText>
                <CustomText className="textCenter semiBold">
                  {currentLanguage.isEN ? (item.titleDescriptionEn ? item.titleDescriptionEn : t("vietNameHomesLuxuryBrokerage")) 
                  : (item.titleDescriptionVi ? item.titleDescriptionVi : t("vietNameHomesLuxuryBrokerage"))}
                </CustomText>

                <div className="buttons">
                  <div
                    className="button button-green dFlex itemsCenter mt12"
                    onClick={() => {
                      setOpenDialog(true);
                      getSellerView(item);
                      setType(3);
                    }}
                  >
                    <div className="button-image">
                      <img src={EmailIcon} alt="Email" />
                    </div>
                    <CustomText>{t("email")}</CustomText>
                  </div>
                  <div
                    className="button button-green dFlex itemsCenter mt12 textCenter"
                    onClick={() => {
                      setOpenDialog(true);
                      getSellerView(item);
                      setType(4);
                    }}
                  >
                    <CustomText>{t("bookShowing")}</CustomText>
                  </div>
                </div>

                <hr className="mt12 mb12" />

                <div className="seller-contact">
                  <div className="seller-contact-item dFlex">
                    <div className="seller-contact-item-icon">
                      <PhoneIcon className="phone-icon" />
                    </div>
                    <CustomText>{item.phoneNumber1}</CustomText>
                  </div>
                  <div className="seller-contact-item dFlex">
                    <div className="seller-contact-item-icon">
                      <img src={EmailGreenIcon} alt="email" />
                    </div>
                    <CustomText>{item.email}</CustomText>
                  </div>
                </div>

                <div className="social-network dFlex justifyCenter">
                  {item.socialNetworkUsers &&
                    item.socialNetworkUsers.map((socialIcon, index) => (
                      <div key={index} className="social-network-item">
                        <img src={socialIcon.socialNetworkIconUrl} alt="" />
                      </div>
                    ))}
                </div>
              </CardContent>
            </Card>
          )
        )
      ) : (
        <Card className="card-item">
          <CardContent className="seller textCenter">
            <CustomText>{t("noSellers")}</CustomText>
          </CardContent>
        </Card>
      )}
      <DialogComponent
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        containerClassName="seller-dialog"
      >
        <div className="seller">
          <div className="seller-title">
            <CustomText className="fontL textCenter">
              {t("email")} {t("vietNamHomeDotca")}
            </CustomText>
          </div>
          <div className="seller-content">
            <div className="dBlock md:dFlex mb32">
              <div className="seller-avatar mb12">
                <img
                  src={sellerView.avatarUrl || PersonIcon}
                  alt="seller avatar"
                />
              </div>
              <div className="seller-contact-info">
                <CustomText className="semiBold fontMP mb8">
                  To: {sellerView.firstName} {sellerView.lastName}
                </CustomText>
                <CustomText className="mb8">
                  {currentLanguage.isEN ? (sellerView.titleEn ? sellerView.titleEn : t("broker")) : (sellerView.titleVi ? sellerView.titleVi : t("broker"))}
                </CustomText>
                <CustomText className="mb8">
                  {currentLanguage.isEN ? (sellerView.titleDescriptionEn ? sellerView.titleDescriptionEn : t("vietNameHomesLuxuryBrokerage")) 
                    : (sellerView.titleDescriptionVi ? sellerView.titleDescriptionVi : t("vietNameHomesLuxuryBrokerage"))}
                </CustomText>
                <div className="seller-contact mb8">
                  <div className="seller-contact-item dFlex itemsCenter">
                    <div className="seller-contact-item-icon">
                      <PhoneIcon className="phone-icon" />
                    </div>
                    <CustomText>{sellerView.phoneNumber1}</CustomText>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side">
              <Grid container spacing={2}>
                {initialInput.map((item, index) => (
                  <Grid
                    key={index}
                    item
                    md={index === 2 || index === 3 ? 6 : 12}
                    xs={12}
                  >
                    <CustomText className="mb8">
                      {t(item) + (index === 3 ? "" : " *")}
                    </CustomText>
                    <CustomTextField
                      key={index}
                      label=""
                      inputProps={{
                        placeholder: type === 4 && index === 0 ? "BOOK SHOWING REQUEST" 
                        : t(index > 1 && currentLanguage.isEN ? "your": "") 
                          + t(item) 
                          + t(index > 1 && currentLanguage.isVI ? "your": "") 
                          + (index === 3 ? "" : " *"),
                        value: type === 4 && index === 0 ? "BOOK SHOWING REQUEST" : dataContact[item].value,
                        error: dataContact[item].error?.length > 0,
                        disabled: (type === 4 && index === 0)
                      }}
                      className={index === 2 || index === 3 ? "half-item" : ""}
                      multiline={index === initialInput.length - 1}
                      row={6}
                      name={item}
                      onChange={getDataContact}
                      type={index === 3 ? "number" : "text"}
                      helperText={
                        dataContact[item].error && t(dataContact[item].error)
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <div className="buttons dFlex justifyCenter">
                <div
                  className="button button-white mt12"
                  onClick={() => setOpenDialog(false)}
                >
                  <CustomText>{t("cancel")}</CustomText>
                </div>
                <div
                  onClick={() => onSent()}
                  className="button button-green mt12"
                >
                  <CustomText>{t("send")}</CustomText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogComponent>
      {open === true && (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {t("bookShowingsuccess")}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ProjectDetailSellers;
