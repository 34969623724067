import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Pagination,
  Stack,
  Tab,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import { appLoading, currentLanguage, getSiteDomain } from "../../helpers/utils";
import NewsCategoryScreen from "./news-category";
import NewsRepositories from "./news-controllers/news-list-repositories";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SendIcon from "@mui/icons-material/Send";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import WellReadScreen from "./wellread-lastest";
import LatestScreen from "./news-latest";
import NewsSearch from "./news-search";
import AppBreadcrumbs from "../../components/app-breadcrumbs";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";

function NewsListScreen(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const local = location?.state as any;
  const navigate = useNavigate();
  const domain = getSiteDomain();
  // const [masterData, setMasterData] = useState<any>({});
  // const [resDataNewsCaterory, setNewsCategory] = useState<any[]>();
  const [page, setPage] = React.useState(1);
  const [isload, setLoad] = React.useState<boolean>(false);
  const [resDataList, setMasterDataList] = useState<any>({});
  const [value, setValue] = React.useState("1");
  const [firstItem, setFirstitem] = useState<any>({});

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  async function getDatasNewsList() {
    appLoading.show();
    let pagingModel = {
      pageNumber: page,
      pageSize: 7,
    };
    let id = local?.id;
    const resDataList = await NewsRepositories.getRelatedNews(id, pagingModel);
    if (page === 1 && resDataList && resDataList.items) {
      setFirstitem(resDataList.items[0]);
    }
    setLoad(true);
    setMasterDataList(resDataList);
    appLoading.dismiss();
  }

  useEffect(() => {
    getDatasNewsList();
  }, [page]);

  const onSearch = () => {};

  const onNavigate = (slug: string) => {
    navigate("/news/" + slug);
    window.scrollTo(0, 0);
  };

  // paginate
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // paginate
  const handleChangePage = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    // changePage(value);
    window.scrollTo(0, 900);
  };

  return (
    <div className="w100">
      <HeaderBar></HeaderBar>
      <Container>
        <div className="header-project-list">
          {resDataList?.items && (
            <CustomText className="txt-exclusive">
              {resDataList?.items.map(
                (item: any, index: number) =>
                  index === 0 && (
                    <AppBreadcrumbs
                      detailTitle={
                        currentLanguage.isEN
                          ? item.category.categoryNameEn
                          : item.category.categoryNameVi
                      }
                    />
                  )
              )}
            </CustomText>
          )}

          <div style={{ width: 666 }}>
            <NewsSearch isMappage onSearch={onSearch} />
          </div>
        </div>
        <hr className="mb20 mt28 line-news" />
        <div>
          {resDataList?.items && (
            <div className="container-new">
              <div>
                <Grid
                  container
                  rowSpacing={8}
                  columnSpacing={{ xs: 1, sm: 4, md: 4 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    lg={8.5}
                    style={{ marginBottom: "30px;" }}
                  >
                    <Grid item xs={12} sm={12} lg={12} className="mb60">
                      <div className="border-new">
                        <div className="card-new-0">
                          <CardMedia
                            className="img-new-0"
                            component="img"
                            height="600"
                            image={firstItem.imagePathUrl}
                            alt="Image"
                            onClick={() => onNavigate(firstItem.slug)}
                          />
                          <div className="w100 mt20 mb20 card-content">
                            <div className="date-listnew-index0 mr32"  onClick={() => onNavigate(firstItem.slug)}>
                              <div className="title-news">
                                <CustomText>
                                  {currentLanguage.isEN
                                    ? firstItem.titleEn
                                    : firstItem.titleVi}
                                </CustomText>
                              </div>
                              <div className="date-views">
                                <CustomText className="mr12">
                                  {moment(firstItem.approveDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </CustomText>
                                <CustomText>
                                  {firstItem.viewCount} {t("views")}
                                </CustomText>
                              </div>
                            </div>

                            <div className="txt-new-0"  onClick={() => onNavigate(firstItem.slug)}>
                              {currentLanguage.isEN
                                ? firstItem.descriptionsEn
                                : firstItem.descriptionsVi}
                            </div>
                            {/* <div>{firstItem.id}</div> */}
                            <div className="date-new">
                              <div className="share-new">
                                <CustomText className="txt-share">
                                {t("share")}
                                </CustomText>
                                <div className="share">
                                  <FacebookShareButton
                                    url={
                                      domain + "/news/" +
                                      firstItem.slug
                                    }
                                    className="Demo__some-network__share-button"
                                  >
                                    <Facebook />
                                  </FacebookShareButton>

                                  <TwitterShareButton
                                    // title={"test"}
                                    url={
                                      domain + "/news/" +
                                      firstItem.slug
                                    }
                                    hashtags={["vietnamhome"]}
                                  >
                                    <Twitter />
                                  </TwitterShareButton>
                                </div>
                              </div>

                              <div
                                className="txt-detail"
                                onClick={() => onNavigate(firstItem.slug)}
                              >
                                <CustomText className="to-detail">
                                  {t("detail")}
                                </CustomText>
                                <ArrowForwardIcon className="icon-detail" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mb20  line-news line-news" />
                    </Grid>

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 4, md: 4 }}
                    >
                      <Grid item xs={12} lg={12}>
                        <Grid
                          container
                          rowSpacing={1}
                          columnSpacing={{ xs: 1, sm: 4, md: 4 }}
                        >
                          {resDataList?.items.map(
                            (item: any, index: number) =>
                            (( page ===1 && index !== 0) || (page !== 1 )) && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  lg={6}
                                  key={index}
                                  className="mb20"
                                >
                                  <div
                                    className="border-new item-2"
                                    onClick={() => onNavigate(item.slug)}
                                  >
                                    <div className="card-new-0">
                                      <CardMedia
                                        className="img-new-0"
                                        component="img"
                                        height="300"
                                        image={item.imagePathUrl}
                                        alt="Image"
                                      />
                                      <div className="w100 mt20 mb24 card-content">
                                        <div className="title-news">
                                          <div className="txtPriceMap">
                                            <CustomText>
                                              {currentLanguage.isEN
                                                ? item.titleEn
                                                : item.titleVi}
                                            </CustomText>
                                          </div>
                                        </div>
                                        <div className="txt-new-0">
                                          {currentLanguage.isEN
                                            ? item.descriptionsEn
                                            : item.descriptionsVi}
                                        </div>
                                        <div className="date-new">
                                          <CustomText className="txt-date">
                                            {moment(item.approveDate).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </CustomText>
                                          <div className="txt-detail">
                                            <CustomText className="to-detail">
                                              {t("detail")}
                                            </CustomText>
                                            <ArrowForwardIcon className="icon-detail" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr className="mb20  line-news" />
                                </Grid>
                              )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {resDataList.totalPages >= 2 && (
                      <div className="btnPagination">
                        <Stack spacing={2}>
                          <Pagination
                            showFirstButton
                            showLastButton
                            count={resDataList.totalPages}
                            page={page}
                            onChange={handleChangePage}
                          />
                        </Stack>
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12} lg={3.5}>
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList
                            onChange={handleChange}
                            centered
                            aria-label="lab API tabs example"
                          >
                            {currentLanguage.isEN ? (
                              <Tab label="Well-read" value="1" />
                            ) : (
                              <Tab label="Đọc Nhiều" value="1" />
                            )}
                            {currentLanguage.isEN ? (
                              <Tab label="Latest" value="2" />
                            ) : (
                              <Tab label="Mới Nhất" value="2" />
                            )}
                          </TabList>
                        </Box>
                        <TabPanel value="1">
                          <WellReadScreen />
                        </TabPanel>
                        <TabPanel value="2">
                          <LatestScreen />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
      </Container>
      <FooterComponent />
    </div>
  );
}

export default NewsListScreen;
