import { Button, Grid } from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";
import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../assets/images/BG.jpeg";
import DialogComponent from "../../components/dialog-component";
import { CustomText } from "../../components/styled-custom";
import { getImageWidthWithRef, getToken } from "../../helpers/utils";
import AuthDialog from "../auth/auth-dialog";

function HomeSaleBanner() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bannerRef = useRef<HTMLDivElement>(null);
  const [carouselHeight, setCarouselHeight] = useState<number>(0);
  const [type, setType] = useState<"signUp" | "signIn">("signUp");
  const [openAuthDialog, setOpenAuthDialog] = useState<boolean>(false);
  const [openDialog, setOpenSignIn] = useState<boolean>(false);

  const handleCarouselWidth = () => {
    if (bannerRef.current) {
      const offsetWidth = bannerRef.current.offsetWidth;
      const isTabletVertical = offsetWidth <= 1200;
      const isTabletHorizontal = offsetWidth <= 1024;
      const isMobile = offsetWidth <= 768;
      let ratio = 24 / 7;

      if (isMobile) {
        ratio = 1.2;
      } else if (isTabletHorizontal) {
        ratio = 2.5;
      } else if (isTabletVertical) {
        ratio = 3;
      }

      const calHeight = getImageWidthWithRef(bannerRef.current, ratio);
      setCarouselHeight(calHeight);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleCarouselWidth, false);
    handleCarouselWidth();
    return () =>
      window.removeEventListener("resize", handleCarouselWidth, false);
  }, []);

  const onSellProperty = () =>{
    const token = getToken() !== null;
    if (token) {
      navigate("/sell-home");
      window.scrollTo(0, 0);
    } else {
      setOpenSignIn(true);
    }
  }

  const onCloseAuthDialog = () => {
    setOpenSignIn(false);
    setOpenAuthDialog(false);
  };

  const onCloseDialogSignIn = () => {
    setOpenSignIn(false);
  };

  const setDialogSignUp = () => {
    setOpenSignIn(false);
    setType("signUp");
    setOpenAuthDialog(true);
  };

  const setDialogSignIn = () => {
    setOpenSignIn(false);
    setOpenAuthDialog(true);
    setType("signIn");
  };

  return (
    <div
      ref={bannerRef}
      className="home-sale-banner"
      style={{ height: carouselHeight }}
    >
       <LazyLoad offset={1}>
      <div className="banner-image">
        <img src={Banner} alt="" />
      </div>
      </LazyLoad>
      <div className="banner-content">
        <CustomText className="title">{t("growWithUs")}</CustomText>
        <div className="borderNone" onClick={() =>onSellProperty()}>
          <Button>{t("listYourProperty")}</Button>
        </div>
      </div>
      <AuthDialog
        type={type}
        open={openAuthDialog}
        onClose={() => onCloseAuthDialog()}
      />
      <DialogComponent
        open={openDialog}
        onClose={() => onCloseDialogSignIn()}
        className="save-search-bar-dialog"
      >
        <CustomText className="title-save">
          {t("signInRequired")} <hr className="mb20 mt28" />
        </CustomText>

        <CustomText className="title-message">
          {t("pleaseRegisterOrSignInToContinue")}
        </CustomText>
        <Grid
          container
          spacing={2}
          className="dFlex justifyCenter dialog-save-search-button mt20 content-sign"
        >
          <Grid item xs={12} sm={12} lg={4}>
            <CustomText className="txt-account">
              {t("createAFreeAccount")}
            </CustomText>
            <Button
              className="sign-up"
              variant="outlined"
              onClick={() => setDialogSignUp()}
            >
              {t("signUp")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={4}>
            <CustomText className="txt-rerister">
              {t("alreadyReristered")}
            </CustomText>
            <Button
              className="sign-in"
              variant="outlined"
              onClick={() => setDialogSignIn()}
            >
              {t("signIn")}
            </Button>
          </Grid>
        </Grid>
      </DialogComponent>
    </div>
  );
}

export default HomeSaleBanner;
