import { ForgetPasswordModel, ResetPasswordModel } from "../../../apis/general-client";
import { generalClient } from "../../../helpers/utils";

const ForgotPasswordRepositories = {
  forgetPassword: async (request: ForgetPasswordModel) => {
    const res = await generalClient.user_ForgetPassword(request);
    return res;
  },

  resetPassword: async (request: ResetPasswordModel) => {
    const res = await generalClient.user_ResetPassword(request);
    return res;
  }
};

export default ForgotPasswordRepositories;
