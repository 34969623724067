import { Button, Container } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AppBreadcrumbs from "../../components/app-breadcrumbs";
import CustomCard from "../../components/custom-card";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import {
  initExistedSellHomeModal,
  sellHomeModal,
} from "./controllers/sell-home-modal";
import SellPropertyAddress from "./sell-home-property-address";
import SellHomePropertyDescription from "./sell-home-property-description";
import SellHomePropertyInfomation from "./sell-home-property-infomation";
import SellPropertySummary from "./sell-home-property-summary";
import { ReactComponent as Mail } from "../../assets/icons/Mail.svg";
import { ReactComponent as Phone } from "../../assets/icons/Phone.svg";
import SellHomeRepositories from "./controllers/sell-home-repositories";
import { useLocation, useNavigate } from "react-router-dom";
import FooterComponent from "../../components/footer-component";
import DialogComponent from "../../components/dialog-component";
import { getUser } from "../../helpers/utils";
import LazyLoad from "react-lazy-load";

type PropertySummaryType = {
  propertyType: string;
  transactionType: string;
  addVideoLink: string;
  addVirtualVideoLink: string;
  avatarFile: string;
};

function SellHome(props: any) {
  let location = useLocation();
  const local = location?.state as any;
  const item = local?.item;
  useMemo(() => {
    initExistedSellHomeModal(item);
  }, []);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [dialogDraft, setDialogDraft] = useState<boolean>(false);
  const [dialog, setDialog] = useState<boolean>(false);

  const [required, setRequired] = useState<any>({});
  const user = getUser();
  const [items, setItems] = useState<any>({});
  const [isCommercial, setIsCommercial] = useState<boolean>(false);

  const [hasAvatar, setHasAvatar] = useState<boolean>(false);

  const updateCheckHasAvatar = (check: boolean) => {
    setHasAvatar(check);
  };

  const updateIsSubmit = (is: boolean) => {
    sellHomeModal.isSubmit = is;
    setSubmit(sellHomeModal.isSubmit);
  };

  const onValidator = async (key?: string) => {
    const requiredField = {
      ...(hasAvatar === true
        ? {}
        : {
            avatarFile: !sellHomeModal.avatarFile,
          }),
      // avatarFile: !sellHomeModal.avatarFile,
      propertyType: !sellHomeModal.propertyType,
      transactionType: !sellHomeModal.transactionType,
      proviceCity: !sellHomeModal.proviceCity,
      district: !sellHomeModal.district,
      addressShowVI: !sellHomeModal.addressShowVI,
      title: !sellHomeModal.title,
      descriptions: !sellHomeModal.descriptions,
      view: !(sellHomeModal.view.length > 0),
      currency: !sellHomeModal.currency,
      acreageLotSize: !sellHomeModal.acreageLotSize,
      price: !sellHomeModal.price,
      longitude: !sellHomeModal.longitude,
      latitude: !sellHomeModal.latitude,
      photoFileList: !sellHomeModal.photoFileList || sellHomeModal.photoFileList.length < 4,
      ...(isCommercial
        ? {}
        : {
            bedroomTypes: !sellHomeModal.bedroomTypes,
            bathroomTypes: !sellHomeModal.bathroomTypes,
          }),
    };

    if (key) {
      required[key] = !sellHomeModal[key];
      setRequired({ ...required });
    } else {
      setRequired({ ...requiredField });
    }

    const canSubmit =
      hasAvatar &&
      requiredField.propertyType === false &&
      requiredField.transactionType === false &&
      requiredField.proviceCity === false &&
      requiredField.district === false &&
      requiredField.addressShowVI === false &&
      requiredField.title === false &&
      requiredField.descriptions === false &&
      requiredField.view === false &&
      requiredField.currency === false &&
      (isCommercial
        ? true
        : requiredField.bedroomTypes === false &&
          requiredField.bathroomTypes === false) &&
      requiredField.acreageLotSize === false &&
      requiredField.price === false &&
      requiredField.longitude === false &&
      requiredField.latitude === false;

    return canSubmit;
  };

  const onSubmit = async () => {
    const canSubmit = await onValidator();
    if (canSubmit) {
      if (item) {
        updateIsSubmit(true);
        const res = await SellHomeRepositories.updateProperty(item.id);
        let doUpdateSucceed = () => {
          setDialog(true);
          updateCheckHasAvatar(false);
        };
        res && doUpdateSucceed();
      } else {
        const res = await SellHomeRepositories.createProperty();
        let doSucceed = () => {
          setDialog(true);
          updateCheckHasAvatar(false);
        };
        res && doSucceed();
      }

      window.scrollTo(0, 0);
    }
  };

  const onSave = async () => {
    const canSubmit = await onValidator();
    if (canSubmit) {
      if (item) {
        updateIsSubmit(false);
        const res = await SellHomeRepositories.updateProperty(item.id);
        let doSucceed = () => {
          setDialogDraft(true);
          updateCheckHasAvatar(false);
        };
        res && doSucceed();
      } else {
        const res = await SellHomeRepositories.saveDraftProperty();
        let doSucceed = () => {
          setDialogDraft(true);
          updateCheckHasAvatar(false);
        };
        res && doSucceed();
      }
      window.scrollTo(0, 0);
    }
  };

  const onCloseDialog = () => {
    setDialog(false);
    navigate("/", { replace: true });
  };
  const onCloseDialogDraft = () => {
    setDialogDraft(false);
    navigate("/my-account", { state: { tabIndex: 5 } });
  };

  useEffect(() => {
    if (local?.item) {
    }
  }, [local?.item]);

  const onTransactionChange = (value: string) => {
    if (value === "004" || value === "005") {
      setIsCommercial(true);
    } else {
      setIsCommercial(false);
    }
  };

  return (
    <div className="sell-home">
      <HeaderBar />
      <Container>
        <div className="breadcrum">
          <AppBreadcrumbs projectDetailData={""} />
        </div>

        <div className="sell-home-container">
          <CustomText className="title">
            {t("listingYourProperties")}
          </CustomText>
          {/* {isCommercial ? (
            <CustomText className="title">{t("listingCommercial")}</CustomText>
          ) : (
            <CustomText className="title">
              {t("listingYourProperties")}
            </CustomText>
          )} */}
          <div className="mb44">
            <CustomText className="contact-info">
              {t("hotlineSupport")}
            </CustomText>
            <CustomText className="contact-info">{t("emailInfo")}</CustomText>
          </div>
          <SellPropertySummary
            required={required}
            validator={onValidator}
            updateHasAvtar={updateCheckHasAvatar}
            onTransactionChange={(e: string) => onTransactionChange(e)}
          />
          <SellPropertyAddress required={required} validator={onValidator} />
          <SellHomePropertyDescription
            required={required}
            validator={onValidator}
          />
          <SellHomePropertyInfomation
            isCommercial={isCommercial}
            required={required}
            validator={onValidator}
          />
          <LazyLoad offset={1}>
          <>
          <CustomCard
            containerClassName="contact-info-bottom-group"
            className="contact-info-bottom"
            title={t("contact")}
          >
            <div className="contact-info-bottom-content">
              <div className="contact-name">
                <CustomText className="mr12 fontMP semiBold">
                  {t("name") + ":"}
                </CustomText>
                <CustomText className="mb20 dFlex fontMP">
                  {user.personalInformation.firstName +
                    " " +
                    user.personalInformation.lastName}
                </CustomText>
              </div>
              {user.personalInformation.phoneNumber1 != null ? (
                <div className="content-item">
                  <CustomText>
                    <Phone className="mr12" /> {t("phone") + ":"}
                  </CustomText>
                  <CustomText>
                    {user.personalInformation.phoneNumber1}
                  </CustomText>
                </div>
              ) : (
                ""
              )}

              <div className="content-item">
                <CustomText>
                  <Mail className="mr12" /> {t("email") + ":"}
                </CustomText>
                <CustomText>{user.email}</CustomText>
              </div>
            </div>
          </CustomCard>
          <div className="button-group">
            <Button onClick={onSave} className="save">
              {t("save")}
            </Button>
            <Button className="save-submit" onClick={onSubmit}>
              {t("saveSubmit")}
            </Button>
          </div>
          </>
          </LazyLoad>
        </div>
      </Container>
      <FooterComponent />
      <DialogComponent
        open={dialog}
        onClose={onCloseDialog}
        containerClassName="dialog-create-success"
      >
        <CustomText className="textCenter fontMP greenLight semiBold">
          {t("createSuccess")}
        </CustomText>
      </DialogComponent>
      <DialogComponent
        open={dialogDraft}
        onClose={onCloseDialogDraft}
        containerClassName="dialog-create-success"
      >
        <CustomText className="textCenter fontMP greenLight semiBold">
          {t("saveDraft")}
        </CustomText>
      </DialogComponent>
    </div>
  );
}

export default SellHome;
export type { PropertySummaryType };
