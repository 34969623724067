import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Card, Grid, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AppModal from "../../../apis/app-modal";
import CustomCard from "../../../components/custom-card";
import DialogComponent from "../../../components/dialog-component";
import { CustomText } from "../../../components/styled-custom";
import { AppContext } from "../../../context/app-context";
import { AppActionStates, appSetState } from "../../../context/states";
import { basicHTML, getToken } from "../../../helpers/utils";
import MyAccountRepositories from "./controllers/my-account-repositories";
function MySaveSearch() {
  let location = useLocation();
  const local = location?.state as any;

  const { t } = useTranslation();
  const [page, setPage] = React.useState(1);
  const [masterData, setMasterData] = useState<any>({});
  const navigate = useNavigate();
  const [openDialogDelete, setOpenDelete] = useState<boolean>(false);
  const [openDialogDeleteAll, setOpenDeleteAll] = useState<boolean>(false);
  const [selectedDelete, setSelectedDelete] = useState<any>();
  const [countNoti, setCountNoti] = useState<any>();
  const { state, dispatch } = useContext(AppContext);
  const token = getToken() || null;
  const [sortModelState, setSortModelState] = useState<{
    newest: Boolean;
    oldest: Boolean;
    nameOrder: Boolean;
    nameOrderDescending: Boolean;
  }>({
    newest: true,
    oldest: false,
    nameOrder: false,
    nameOrderDescending: false,
  });

  const onFilter = async (sort: number) => {
    const sortModelState = {
      newest: sort === 1,
      oldest: sort === 2,
      nameOrder: sort === 3,
      nameOrderDescending: sort === 4,
      pageNumber: 1,
      pageSize: 12,
    };

    setSortModelState(sortModelState);
  };

  async function getDatasSaveSearch() {
    const resData = await MyAccountRepositories.getSaveSearch(sortModelState);
    setMasterData(resData);
  }

  async function deleteDatasSaveSearch(id: string) {
    const resData = await MyAccountRepositories.deleteSaveSearch(id);
    getDatasSaveSearch();
  }

  async function deleteAllDatasSaveSearch() {
    const resData = await MyAccountRepositories.deleteAllSaveSearch();
    getDatasSaveSearch();
  }
  async function getCountNoti() {
    const res = await MyAccountRepositories.getCountNoti(false);
    setCountNoti(res);
    state.count = res;
    dispatch(appSetState(AppActionStates.NOTI, state.count));
  }

  useEffect(() => {
    if (token) {
      getDatasSaveSearch();
    }
    getCountNoti();
  }, [page, local?.id, local?.sort, sortModelState]);

  const onNavigate = (keyword: string, type: string) => {
    AppModal.searchingPropertyModel.transactionTypeId = "";
    if (type && type === "004") {
      AppModal.searchingPropertyModel.isManyCommercialProperty = false;
      navigate("/commercial", { state: { keyword: keyword, id: "004" } });
    } else if (type && type !== "004") {
      navigate("/property", { state: { keyword: keyword, id: type } });
    } else if (!type) {
      navigate("/property", { state: { keyword: keyword, id: null } });
    }
  };

  const deleteSaveSearch = (id: string) => {
    setOpenDelete(false);
    deleteDatasSaveSearch(id);
  };

  const deleteAllSaveSearch = () => {
    deleteAllDatasSaveSearch();
  };

  return (
    <div>
      {masterData?.items && (
        <div className="right my-favourite w100 mb40">
          <CustomCard className="pb20" title={t("mySavedSearchs")}>
            <div className="contentCount header-property-item pt8">
              <CustomText>
                {basicHTML(
                  t("resultTotalListings", { number: masterData.totalCount })
                )}
              </CustomText>
            </div>
            <div className="dFlex justifyBetween group-button">
              <div className="content-sort header-property-item pt8">
                <CustomText>{t("sortBy")} </CustomText>
                <Select
                  className="formSelect "
                  id="demo-simple-select-standard"
                  onChange={(e: any) => onFilter(e.target.value)}
                  defaultValue={1}
                >
                  <MenuItem value={1}>
                    <CustomText>{t("newest")}</CustomText>
                  </MenuItem>
                  <MenuItem value={2}>
                    <CustomText>{t("oldest")}</CustomText>
                  </MenuItem>
                  <MenuItem value={3}>
                    <CustomText>{t("atoZ")}</CustomText>
                  </MenuItem>
                  <MenuItem value={4}>
                    <CustomText>{t("ztoA")}</CustomText>
                  </MenuItem>
                </Select>
              </div>
              <div
                className="dFlex icon-delete mt16 py-9 px20"
                onClick={() => setOpenDeleteAll(true)}
              >
                <DeleteIcon className="red" />
                <CustomText className="txt-delete">{t("deleteAll")}</CustomText>
              </div>
            </div>
            <div className="container-card">
              <Grid
                className="save-search-items"
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 4, md: 4 }}
              >
                {masterData.items.map((item: any, index: number) => (
                  <Grid item xs={12} sm={12} md={12} lg={6} key={index}>
                    <Card className="card">
                      <div className="txt-citi-delete">
                        <CustomText
                          className="txt-nameciti"
                          onClick={() => onNavigate(item.name, item.type)}
                        >
                          {item.name}
                        </CustomText>
                        <DeleteIcon
                          className="btn-delete red"
                          onClick={() => {
                            setSelectedDelete(item.id);
                            setOpenDelete(true);
                          }}
                        />
                      </div>
                      <div
                        className="txt-day"
                        onClick={() => onNavigate(item.name, item.type)}
                      >
                        {item.numberOfDaysAgo === 0 ? (
                          <CustomText> {t("today")}</CustomText>
                        ) : (
                          <CustomText>
                            {item.numberOfDaysAgo + " " + t("daysAgo")}
                          </CustomText>
                        )}
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          </CustomCard>
        </div>
      )}
      <DialogComponent
        open={openDialogDelete}
        onClose={() => setOpenDelete(false)}
        className="delete-dialog"
      >
        <div className="delete-content">
          <CustomText className="bold">
            {t("confirmDeleteSaveSearch")}
          </CustomText>
          <div className="action-btn">
            <Button className="cancel" onClick={() => setOpenDelete(false)}>
              {t("cancel")}
            </Button>
            <Button
              className="confirm"
              onClick={() => deleteSaveSearch(selectedDelete)}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </DialogComponent>
      <DialogComponent
        open={openDialogDeleteAll}
        onClose={() => setOpenDeleteAll(false)}
        className="delete-dialog"
      >
        <div className="delete-content">
          <CustomText className="bold">
            {t("confirmDeleteAllSaveSearch")}
          </CustomText>
          <div className="action-btn">
            <Button className="cancel" onClick={() => setOpenDeleteAll(false)}>
              {t("cancel")}
            </Button>
            <Button
              className="confirm"
              onClick={() => {
                deleteAllSaveSearch();
                setOpenDeleteAll(false);
              }}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </DialogComponent>
    </div>
  );
}

export default MySaveSearch;
