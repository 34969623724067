import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from 'react-toastify';
import Scroll from "./components/scroll";
import { AppContext, AppProvider } from "./context/app-context";
import { AppActionStates, appSetState } from "./context/states";
import AppRoutes from "./routes/app-routes";
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);
  const [readyToRender, setReadyToRender] = useState<boolean>(true);

  // useEffect(() => {
  //   async function initialData() {
  //     appLoading.show();
  //     await AppRepositories.getMasterData();
  //     setReadyToRender(true);
  //     appLoading.dismiss();
  //   }
  //   initialData();
  // }, []);

  const onChange = () => {
    dispatch(appSetState(AppActionStates.LOGGED, !state.isLogged));
  };

  return readyToRender ? (
    // <div className="home-page">
    //   <CustomText>{t("homepage")}</CustomText>
    //   <CustomTextField label="abc" />
    //   <Link to="/login">{t("goToLogin")}</Link>
    //   <br />
    //   <Link to="/register">{t("goToRegister")}</Link>
    //   <br />
    //   <button onClick={onChange}>{t("changeLogged")}</button>
    //   <br />
    //   <button onClick={changeLanguage}>{t("changeLanguage")}</button>
    //   <p>{t("changeLogged") + ": " + state.isLogged.toString()}</p>
    // </div>
    <AppProvider>
      <Scroll showBelow={350}></Scroll>

      <AppRoutes />
      <ToastContainer />
    </AppProvider>
  ) : null;
}

export default App;
