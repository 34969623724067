import { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { getToken } from "../helpers/utils";
import { AuthRepositories } from "../views/auth/auth-controllers/auth-repositories";
import { CookieName } from "./../types/cookie.types";
import { toast } from "react-toastify";

export class BaseClient {
  protected transformOptions(options: AxiosRequestConfig) {
    const token = getToken() || null;
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: "Bearer " + token,
      };
    }
    return Promise.resolve(options);
  }
  protected transformResult(
    url: string,
    response: AxiosResponse,
    process: (response: AxiosResponse) => any
  ) {
    if (response.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("accessToken");

      sessionStorage.removeItem("user");
      sessionStorage.removeItem("accessToken");

      const isRemember = localStorage.getItem("rememberMe");

      if (isRemember && isRemember === "true") {
        const email = Cookies.get(CookieName.email) || "";
        const password = Cookies.get(CookieName.password) || "";
        AuthRepositories.login(atob(email), atob(password), true);
      } else if (!isRemember || isRemember === "false") {
        localStorage.removeItem("rememberMe");
        toast.error("Sign In session expired. Please Sign In again!");
      }
    }

    return process(response);
  }
}
