import { generalClient } from "../helpers/utils";

type SearchingPropertyType = {
  propertyKeyWord: string;
  propertyTypeId: string;
  bedroomId: string;
  bathroomId: string;
  administrativeCode: string;
  transactionTypeId: string;
  isManyCommercialProperty?: boolean;
  minPrice: number;
  maxPrice: number;
  landSize: number;
  listedSince: Date;
};

type login = {
  login: boolean;
}

class AppModal {
  static searchingPropertyModel: SearchingPropertyType =
    {} as SearchingPropertyType;

  static isLogin: login = {login: false} as login;
}

export default AppModal;
export type { SearchingPropertyType };
