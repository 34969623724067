import { generalClient } from "../../../helpers/utils";

const ProductDetailRepositories = {
  getProductDetail: async (id: string) => {
    const res = await generalClient.project_GetById(id);
    return res;
  },

  getProductDetailBySlug: async (slug: string) => {
    const res = await generalClient.project_GetBySlug(slug);
    return res;
  },

  viewCountProject: async (projectId: string) => {
    const res = await generalClient.project_CountViewProject(projectId);
    return res;
  },
};

export default ProductDetailRepositories;
