
type SearchingNewsModel = {
  title?: string;
};

class NewsModal {
  static searchingNewsModel: SearchingNewsModel = {} as SearchingNewsModel;
}

export default NewsModal;
export type { SearchingNewsModel };
