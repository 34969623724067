import { generalClient } from "../../../helpers/utils";

const ProjectRepositories = {

  getProjectList: async (projectModel:any) => {
    const resData = await generalClient.project_Search(projectModel)
    return resData;
  },

  getLocationProject:async () => {
    const resData = await generalClient.project_GetAdministrativeByProject()
    return resData;
  },

  getSuggestProject: async (keyword: string) => {
    const res = await generalClient.project_SuggestProject(keyword);
    return res;
  }
}

export default ProjectRepositories