import { Card, CardContent, CardMedia, Container, Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { CustomText } from "../../components/styled-custom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { currentLanguage } from "../../helpers/utils";
import LazyLoad from "react-lazy-load";

function NewsCategoryScreen(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resDataNewsCateroryModels, setNewsCategoryModels] = useState<any[]>();

  useEffect(() => {
    async function getDatas() {
      setNewsCategoryModels(props.resDataNewsCaterory);
    }
    getDatas();
  }, [props.resDataNewsCaterory]);

  const onNavigate = (slug: string) => {
    navigate("/news/" + slug);
    window.scrollTo(0, 0);
  };

  async function goToList(idCategory: string) {
    navigate("/news/news-list"  , { state: { id: idCategory } });
    window.scrollTo(0, 0);
  }

  return (
    <div className="w100">
        <div className="mb80">
          {resDataNewsCateroryModels &&
            resDataNewsCateroryModels.map((item: any, index: number) => (
              <div className="container-new mb20" key={index}>
                 <hr className="mb20 mt28 line-news" />
                <div className="header-category-list">
                  <CustomText className="txt-exclusive">
                  {currentLanguage.isEN ? <div>{resDataNewsCateroryModels[index].categoryNameEn}</div> :  <div>{resDataNewsCateroryModels[index].categoryNameVi}</div>}
                  </CustomText>
                  <div
                    className="content-sort header-project-item"
                    onClick={() =>
                      goToList(resDataNewsCateroryModels[index].id)
                    }
                  >
                    <CustomText className="txt-readmore">
                      {t("readmore")}
                    </CustomText>
                    <ArrowForwardIcon className=" " />
                  </div>
                </div>
               
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 4, md: 4 }}
                >
                  
                  {resDataNewsCateroryModels[index].news.map(
                    (item: any, index: number) => (
                      <Grid item xs={12} sm={12} lg={4} key={index}>
                        <div
                          className="border-new w100"
                          onClick={() => onNavigate(item.slug)}
                        >
                          <div className="card-new-0">
                            <LazyLoad offset={1}>
                            <CardMedia
                              className="img-new"
                              component="img"
                              height="250"
                              image={item.imagePathUrl}
                              alt="Image"
                            />
                            </LazyLoad>

                            <div className="w100 mt20 card-content">
                              <div className="title-news">
                                <div>
                                  <CustomText>
                                    {currentLanguage.isEN
                                      ? item.titleEn
                                      : item.titleVi}
                                  </CustomText>
                                </div>
                              </div>
                              <div className="txt-new-0">
                                {currentLanguage.isEN
                                  ? item.descriptionsEn
                                  : item.descriptionsVi}
                              </div>
                              <div className="date-new">
                                <CustomText className="txt-date">
                                  {moment(item.approveDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </CustomText>
                                <div className="txt-detail">
                                  <CustomText className="to-detail">
                                    {t("detail")}
                                  </CustomText>
                                  <ArrowForwardIcon className="icon-detail" />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* </Link> */}
                        </div>
                      </Grid>
                    )
                  )}
                </Grid>
              </div>
            ))}
        </div>
    </div>
  );
}

export default NewsCategoryScreen;
