import { Card, CardContent, CardMedia, Container, Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import VNMap from "../../assets/images/Map2.png";
import { currentLanguage } from "../../helpers/utils";
import moment from "moment";
import PinMap from "../../assets/icons/Pin-black.svg";
import { CustomText } from "../../components/styled-custom";
import LazyLoad from "react-lazy-load";

function ProjectListScreen(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dataFilter, setDataFilter] = useState<any>({});
  const [page, setPage] = React.useState(1);
  const [masterData, setMasterData] = useState<any>({});

  useEffect(() => {

    async function getDatas() {
      setMasterData(props.masterData);
    }
    getDatas();
  }, [page, props.changePage, props.masterData]);

  // paginate
  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    props.changePage(value);
    window.scrollTo(0, 900);
  };

  return (
    <div>
      {masterData?.items && (
        <Container className="container">
          <Grid container spacing={8}>
            <Grid item lg={8} xs={12}>
              <LazyLoad offset={1}>

              <div className="grid-project">
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 4, md: 4 }}
                >
                  {masterData?.items.map((item: any, index: number) => (
                    <Grid item xs={12} sm={12} md={6} lg={5} key={index}>
                      <Link to={"/project/" + item.slug} className="borderNone">
                        <Card className="card-project project-item">
                          <div className="content-project">
                            <CardMedia
                              className="img-project"
                              component="img"
                              height="260"
                              image={item.coverImageUrl}
                              alt="Image project"
                            />
                            <CardContent className="info-project">
                              <CustomText className="txt-project">
                                {currentLanguage.isEN
                                  ? item.projectEn
                                  : item.projectVi}
                              </CustomText>
                              <CustomText
                                className={
                                  item?.status === 1
                                    ? "txt-status-1"
                                    : item?.status === 2
                                    ? "txt-status-2"
                                    : "txt-status-3"
                                }
                              >
                                {t(item.statusName)}
                              </CustomText>
                              <div className="txtAddress">
                                <img
                                  className="icon-pin"
                                  src={PinMap}
                                  alt="image Pin"
                                />
                                <CustomText className="txt-province">
                                  {currentLanguage.isEN ? item.districtNameEn : item.districtName}, {currentLanguage.isEN ? item.provinceNameEn : item.provinceName}
                                </CustomText>
                              </div>
                              <div className="content-start">
                                <CustomText className="txt-start">
                                  {t("startDay")}
                                </CustomText>
                                <CustomText className="start-date">
                                  {moment(item.startDate).format("YYYY")}
                                </CustomText>
                              </div>
                              <div className="content-end">
                                <CustomText className="txt-end">
                                  {t("estimatedCompletionDate")}
                                </CustomText>
                                <CustomText className="end-day">
                                  {moment(item.endDate).format("YYYY")}
                                </CustomText>
                              </div>
                              <CustomText className="txt-investor">
                                {item.developer}
                              </CustomText>
                            </CardContent>
                          </div>
                        </Card>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </div>
              </LazyLoad>
              {props.number > 8 ? (
                <div className="btnPagination">
                  <Stack spacing={2}>
                    <Pagination
                      showFirstButton
                      showLastButton
                      count={masterData.totalPages}
                      page={page}
                      onChange={handleChange}
                    />
                  </Stack>
                </div>
              ) : (
                ""
              )}
            </Grid>
            <Grid item lg={4} xs={12}>
            <LazyLoad offset={1}>

              <div
                className={` ${masterData.items.length > 6 ? "outer" : ""} ${
                  masterData.items.length == 6 || masterData.items.length == 5
                    ? "h-125vh"
                    : ""
                } ${
                  masterData.items.length == 3 || masterData.items.length == 4
                    ? "h-65vh"
                    : ""
                }`}
              >
                <div
                  className={`inner ${
                    masterData.items.length < 3 ? "fit-content" : ""
                  }`}
                >
                  <div className="map-project">
                    <img src={VNMap} alt="" />
                  </div>
                </div>
              </div>
            </LazyLoad>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}

export default ProjectListScreen;
