import {
  FilterPropertyByUserModel,
  UpdateProfileModel,
} from "../../../../apis/general-client";
import { generalClient, getToken } from "../../../../helpers/utils";
import { accountModel } from "./my-account-model";

const MyAccountRepositories = {
  updateUser: async () => {
    var post = new UpdateProfileModel();
    const avatarData = accountModel.avatarFile
      ? {
          data: accountModel.avatarFile,
          fileName: accountModel.avatarFile.name,
        }
      : { data: null, fileName: "" };
    if (avatarData.data != null) {
      var avatarFilePathRs = await generalClient.photo_PostPhotoAttachment(
        avatarData,
        "photo",
        undefined,
        "property"
      );
      post.avatarFilePath = avatarFilePathRs.objectReturn.filePath;
    } else {
      post.avatarFilePath = accountModel.avatarFilePath;
    }

    post.firstName = accountModel.firstName;
    post.lastName = accountModel.lastName;
    post.phoneNumber1 = accountModel.phoneNumber1;
    const res = await generalClient.profile_UpdateProfile(post);
    if (res.succeeded) {
      await MyAccountRepositories.setValueForLocalAndSessionStorage(post);
    }
    return res.succeeded;
    // return
  },

  setValueForLocalAndSessionStorage: async (data: any) => {
    //set back local storage and session storage
    const accountInfo = (await generalClient.profile_UserInfo2()) as any;
    var rememberMe = localStorage.getItem("rememberMe");
    if (rememberMe) {
      localStorage.setItem("user", JSON.stringify(accountInfo));
      localStorage.setItem("rememberMe", "true");
    } else {
      sessionStorage.setItem("user", JSON.stringify(accountInfo));
    }
  },

  getFavourite: async (data: any) => {
    const resData = await generalClient.propertyHeart_PagingProperty(data);
    return resData;
  },

  getNoti: async (data: any) => {
    try {
      const resData = await generalClient.notification_GetPagingByUser(
        data.pageNumber,
        data.pageSize
      );
      return resData;
    } catch (error) {
      return 0;
    }
  },
  getCountNoti: async (data: any) => {
    const user = getToken();
    if (!user) {
      return 0;
    } else {
      try {
        const resData =
          await generalClient.notification_GetCountNotificationByUser(data);
        return resData;
      } catch (error) {
        return 0;
      }
    }
  },
  seenNoti: async (data: any) => {
    const res = await generalClient.notification_SeenNotificationByUser(data);
    return res;
  },
  removeNoti: async (data: any) => {
    const res = await generalClient.notification_RemoveNotificationByUser(data);
    return res;
  },
  removeAllNoti: async () => {
    const res = await generalClient.notification_RemoveAllNotificationByUser();
    return res;
  },

  changePass: async (data: any) => {
    return await generalClient.profile_ChangePassword(data);
  },

  getSaveSearch: async (data: any) => {
    try {
      const resData = await generalClient.userSavedSearch_Sorting(data);
      return resData;
    } catch (error) {
      return 0;
    }
  },

  deleteSaveSearch: async (id: any) => {
    const resData = await generalClient.userSavedSearch_Delete(id);
    return resData;
  },

  deleteAllSaveSearch: async () => {
    const resData = await generalClient.userSavedSearch_DeleteAll();
    return resData;
  },

  searchPropertyByUser: async (filterData: any) => {
    const resData = await generalClient.property_FilterPropertyByUser(
      filterData
    );
    return resData;
  },

  deleteTempProperty: async (id: any) => {
    const resData = await generalClient.property_DeleteTempProperty(id);
    return resData;
  },
};
export default MyAccountRepositories;
