import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import ContactForm from "../../components/contact-form";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import ContactUsBG from "../../assets/images/Contactus.jpeg";

function ContactUs() {
  const { t } = useTranslation();

  return (
    <div className="contact-us-container">
      <HeaderBar />
      <Container>
        <CustomText className="contact-title">{t("contactUsTitle")}</CustomText>
        <div className="contact-us-content">
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <div className="contact-form">
                <CustomText className="semiBold mb32">
                  {t("wantToHear")}
                </CustomText>
                <ContactForm type={1} />
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className="contact-image">
                <img src={ContactUsBG} alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <FooterComponent />
    </div>
  );
}

export default ContactUs;
