import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Snackbar,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResetPasswordModel } from "../../apis/general-client";
import CustomTextField from "../../components/custom-text-field";
import DialogComponent from "../../components/dialog-component";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import { getToken } from "../../helpers/utils";
import ForgotPasswordRepositories from "./forgot-password-controller/forgot-password-repositories";

type ResetPasswordScreenType = {
  label: string;
  key: string;
  value: string;
};

function ResetPasswordScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disable, setDisable] = useState<boolean>(true);
  const [openNoti, setOpenNoti] = useState(false);
  const [openErrorNoti, setOpenErrorNoti] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isOpenAuthDialog, setIsOpenAuthDialog] = useState<boolean>(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const token = params.get("token");

  const initialInput: ResetPasswordScreenType[] = [
    {
      label: t("newsPassword") + " *",
      key: "password",
      value: "",
    },
    {
      label: t("reEnterNewPassword") + " *",
      key: "reEnterNewPassword",
      value: "",
    },
  ];

  const [dataInput, setDataInput] = useState<any>({
    password: { value: "", error: "" },
    reEnterNewPassword: { value: "", error: "" },
  });

  const onResetPassword = async () => {
    const accessToken = getToken() != null;
    if (!accessToken) {
      if (email && token) {
        const requestModel = {
          email: email,
          token: token,
          newPassword: dataInput.password.value,
        } as ResetPasswordModel;

        var result = await ForgotPasswordRepositories.resetPassword(
          requestModel
        );
        if (result && result.succeeded) {
          setOpenNoti(true);
          dataInput.password.value = "";
          dataInput.reEnterNewPassword.value = "";
          navigate("/");
        } else {
          let error = result.errors ? result.errors[0] : "";
          setErrorMessage(error);
          setOpenErrorNoti(true);
        }
      } else {
        setErrorMessage("anErrorOccurred");
        setOpenErrorNoti(true);
      }
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenErrorNoti(false);
  };

  const getDataInput = (e: ChangeEvent<HTMLInputElement>) => {
    dataInput[e.target.name].value = e.target.value;
    setDataInput({ ...dataInput });
    if ((dataInput.reEnterNewPassword.value.length < 6 || dataInput.password.value.length < 6) || (dataInput.reEnterNewPassword.value !== dataInput.password.value)) {
      dataInput.reEnterNewPassword.error = "confirmPasswordMismatch";
      setDisable(true); 
    } else {
      setDisable(false);
      dataInput.reEnterNewPassword.error = "";
    
    }
  };

  const onOpenSignInPopup = () => {
    setIsOpenAuthDialog(true);
  };

  const onCloseSignPopup = () => {
    setIsOpenAuthDialog(false);
  };

  useEffect(() => {
    setDisable(disable);
  });
  return (
    <div className="forgot-password-container">
      <HeaderBar
        isOpenAuthDialog={isOpenAuthDialog}
        setIsOpenAuthDialog={() => onCloseSignPopup()}
      />
      <Container>
        <CustomText className="fontXL font2 regular textCenter mt36">
          {t("resetPassword")}
        </CustomText>
        <Card className="card card-reset-password">
          <CardContent>
            <div className="input-group mt20">
              {initialInput.map((item, index) => (
                <div className="mb24" key={index}>
                  <CustomText className="mb8">{t(item.label)}</CustomText>
                  <CustomTextField
                    label=""
                    inputProps={{
                      placeholder: t(item.key) + " *",
                      value: dataInput[item.value],
                      error: dataInput[item.key].error !== "",
                    }}
                    helperText={
                      dataInput[item.key].error && t(dataInput[item.key].error)
                    }
                    onChange={getDataInput}
                    name={item.key}
                    type="password"
                  />
                </div>
              ))}
            </div>

            <div className="dFlex justifyCenter itemsCenter">
              <Button
                className="btn cancel-btn"
                onClick={() => onOpenSignInPopup()}
              >
                {t("cancel")}
              </Button>
              <Button
                disabled={disable}
                className="btn reset-btn"
                onClick={onResetPassword}
              >
                {t("reset")}
              </Button>
            </div>

            <Snackbar
              open={openNoti}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {t("resetPasswordSuccesfully")}
              </Alert>
            </Snackbar>

            <Snackbar
              open={openErrorNoti}
              autoHideDuration={4000}
              onClose={handleCloseError}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseError}
                severity="error"
                sx={{ width: "100%" }}
              >
                {t(errorMessage)}
              </Alert>
            </Snackbar>
          </CardContent>
        </Card>
      </Container>
      <FooterComponent />
    </div>
  );
}

export default ResetPasswordScreen;
