import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "aframe";
import {
  Box,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import "./index.css";
import "./i18n";
import "./main.scss";
import AppLoading from "./components/app-loading";
import App from "./App";
import { AppRepositories } from "./apis/app-repositories";
import AppModal from "./apis/app-modal";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 16,
  },
});

const rootElement = document.getElementById("root");
const loadingMarkup = (
  <Box sx={{ display: "flex" }}>
    <CircularProgress />
  </Box>
);

const checkLicence = async () => {
  await AppRepositories.getMasterData();

  ReactDOM.render(
    <Suspense fallback={loadingMarkup}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <App />
          <AppLoading />
        </ThemeProvider>
      </React.StrictMode>
    </Suspense>,
    rootElement
  );
};

checkLicence();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
