/* eslint-disable react/jsx-no-undef */
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import CustomCard from "../../../components/custom-card";
import { CustomText } from "../../../components/styled-custom";
import CustomSelect, {
  SelectItemType,
} from "../../../components/custom-select";
import FooterComponent from "../../../components/footer-component";
import PhoneMissedIcon from "@mui/icons-material/PhoneMissed";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Box, Button, Popover, Tab, Tabs, Typography } from "@mui/material";
import HeaderBar from "../../../components/header-bar";
import { Menu } from "@mui/icons-material";
import AccountInformation from "./account-information";
import MyFavourite from "./my-favorite";
import MySaveSearch from "./my-save-search";
import Notification from "./notification";
import ChangePass from "./change-pass";
import { useLocation } from "react-router-dom";
import ListingManagement from "./listing-management";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  classes?: string;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function MyAccount() {
  const { t } = useTranslation();
  const location = useLocation() as any;
  const [value, setValue] = useState(0);
  const userInfoJson = sessionStorage.getItem("user");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const data: SelectItemType[] = useMemo(
    () => [
      {
        label: "lastName",
        name: "lastName",
        data: [],
      },
      {
        label: "email",
        name: "email",
        data: [],
      },
      {
        label: "firstName",
        name: "firstName",
        data: [],
      },
      {
        label: "displayName",
        name: "displayName",
        data: [],
      },
      {
        label: "phoneNumber",
        name: "phoneNumber",
        data: [],
      },
    ],
    []
  );

  const [inputValue, setInputValue] = useState<any>({
    firstName: "",
    lastName: "",
    displayName: "",
    phoneNumber: "",
    email: "",
  });

  useEffect(() => {
    if (userInfoJson) {
      var userInfo = JSON.parse(userInfoJson);
      setInputValue({ ...userInfo });
      // inputValue['email'] = "something";
      // inputValue['firstName'] = "something";
      // inputValue['lastName'] = "something";
      // inputValue['phoneNumber'] = "something";
      
    }
    if (location && location?.state) {
      setValue(location?.state?.tabIndex);
    }
  }, [location?.state?.tabIndex]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    inputValue[e.target.name] = e.target.value;
    setInputValue({ ...inputValue });
    // joinData("input", inputValue);
  };

  return (
    <div className="my-account">
      <HeaderBar></HeaderBar>
      <Container>
        <div className="px136 px-account mb32">
          <Grid container spacing={4}>
            <Grid item md={4} sm={5} xs={12}>
              <div className="left">
                <CustomCard title={t("myAccount")}>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    className="dFlex flexCol gapRow24 pTabs p40"
                  >
                    <Tab label={t("accountInfomation")} {...a11yProps(0)} />
                    <Tab label={t("myFavourites")} {...a11yProps(1)} />
                    <Tab label={t("mySavedSearchs")} {...a11yProps(2)} />
                    <Tab label={t("notification")} {...a11yProps(2)} />
                    <Tab label={t("changePassword")} {...a11yProps(3)} />
                    <Tab label={t("listingManagement")} {...a11yProps(4)} />
                  </Tabs>
                </CustomCard>
              </div>
            </Grid>
            <Grid item md={8} sm={7} xs={12}>
              <TabPanel value={value} index={0}>
                <AccountInformation />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <MyFavourite />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MySaveSearch />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Notification />
              </TabPanel>
              <TabPanel value={value} index={4}>
                <ChangePass />
              </TabPanel>
              <TabPanel value={value} index={5}>
                <ListingManagement />
              </TabPanel>
            </Grid>
          </Grid>
        </div>
      </Container>
      <FooterComponent />
    </div>
  );
}

export default MyAccount;
