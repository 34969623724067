class StyledVariables {
  // Colors
  static colors = {
    gray200: "#FAFAFA",
    gray300: "#f7f7f7",
    gray: "#a3a3a3",
    gray400: "#3b3b3b",
    white: "#FFFFFF",
    black: "#000000",
    blue: "#1A67DC",
    blue200: "#c8deff",
    lightGray: "#F3F5F7",
    mediumGray: "#C2CFCA",
    greenDark: "#004029",
    greenLight: "#6aa510",
    red: "#d32f2f",
    yellow400: "#e0cd20",
    yellow700: "#d1842c",
    redDark: "#b91c1c",
    red400: "#ea8181"
  };

  // Font Size
  static fontSize = {
    fontS: 12,
    fontSP: 14,
    fontM: 16,
    fontMP: 20,
    fontL: 24,
    fontXL: 36,
    fontXXL: 55,
  };

  // Font weight
  static fontWeight = {
    regular: 300,
    semiBold: 600,
    bold: 900,
  };

  // Font Family
  static fontFamily = {
    font1: "SVN-Darleston",
    font2: "MongolianBaiti",
    font3: "SVN-Darleston",
  };

  // Spacing
  static spacing = {
    sp4: 4,
    sp8: 8,
    sp12: 12,
    sp16: 16,
    sp20: 20,
    sp24: 24,
    sp28: 28,
    sp30: 30,
    sp32: 32,
    sp36: 36,
    sp40: 40,
    sp44: 44,
    sp48: 48,
    sp52: 52,
    sp56: 56,
    sp60: 60,
    sp80: 80,
    sp88: 88,
    sp96: 96,
    sp112: 112,
    sp280: 280,
    sp400: 400,
    sp440: 440,
    sp600: 600,

    // Grid Spacing
    gap1: 1,
    gap2: 2,
    gap3: 3,
    gap4: 4,
    gap5: 5,
    gap6: 6,
    gap7: 7,
    gap8: 8,
    gap9: 9,
    gap10: 10,
    gap11: 11,
    gap12: 12,
  };
}

export default StyledVariables;
