type AccountModel = {
  avatarFilePath?: string | undefined;
  avatarFile: any | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  phoneNumber1?: string | undefined;
  phoneNumber2?: string | undefined;
  phoneNumber3?: string | undefined;
  address?: string | undefined;
  email?: string | undefined;
};

let defaultAccountModel: AccountModel = {
  avatarFilePath: "",
  avatarFile: undefined,
  address: "",
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber1: "",
  phoneNumber2: "",
  phoneNumber3: "",
};

let accountModel: AccountModel = { ...defaultAccountModel };

export { accountModel };
export type { AccountModel };
