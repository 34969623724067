import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Autocomplete,
  Checkbox,
  createFilterOptions,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ChangeEvent } from "react";
import { cleanAccents, currentLanguage } from "../helpers/utils";
import CustomTextField, { CustomTextFieldType } from "./custom-text-field";
import { CustomText } from "./styled-custom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type SelectItemType = {
  label: string;
  placeholder?: string;
  name: string;
  data: any[];
  type?: "number" | "text" | "password" | undefined;
};

type CustomSelectType = {
  className?: string;
  containerClassName?: string;
  isInput?: boolean;
  isDatePicker?: boolean;
  inputProps?: CustomTextFieldType;
  label?: string;
  name: string;
  value: any;
  multiple?: boolean;
  placeholder?: string;
  data?: any[];
  onChange?: (
    e: SelectChangeEvent | ChangeEvent<HTMLInputElement> | any
  ) => void;
  onBlur?: (e: any) => void;
  error?: boolean;
  autoComplete?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: string;
  type?: "number" | "text" | "password" | undefined;
  min?: number;
};

function CustomSelect(props: CustomSelectType) {
  const filterOptions = createFilterOptions({
    stringify: (option: any) =>
      cleanAccents(
        option[`${props.name}${currentLanguage.isEN ? "En" : "Vi"}`]
      ),
  });

  return (
    <div className={`custom-select ${props.containerClassName}`}>
      <CustomText
        className={`item-label ${props.error ? "red" : ""}`}
        style={{ color: props.disabled ? "rgba(0,0,0,0.4)" : "black" }}
      >
        {props.label}
      </CustomText>
      {props.isInput ? (
        <CustomTextField
          className="input"
          label=""
          disabled={props.disabled}
          min={props.min}
          required={props.required}
          helperText={props.helperText}
          onChange={props.onChange}
          inputProps={{
            placeholder: props.placeholder,
            error: props.error,
            value: props.value,
            onBlur: props.onBlur,
          }}
          name={props.name}
          type={props.type ? props.type : undefined}
          {...props.inputProps}
        />
      ) : props.isDatePicker ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["year"]}
            label=""
            inputFormat="yyyy"
            value={props.value}
            onChange={(e) => props.onChange && props.onChange(e)}
            renderInput={(params) => (
              <TextField
                {...params}
                className="text-field"
                variant="outlined"
                error={props.error}
                inputProps={{
                  ...params.inputProps,
                  placeholder: props.placeholder,
                }}
              />
            )}
          />
        </LocalizationProvider>
      ) : props.autoComplete ? (
        props.data && (
          <Autocomplete
            disablePortal
            className={`auto-complete ${props.className}`}
            options={props.data}
            getOptionLabel={(option) =>
              option[`${props.name}${currentLanguage.isEN ? "En" : "Vi"}`]
            }
            selectOnFocus={false}
            filterSelectedOptions={false}
            onChange={(e, val) => props.onChange && props.onChange(val)}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{ width: 300 }}
            value={props.value}
            renderInput={(params) => (
              <TextField
                {...params}
                className="text-field"
                variant="outlined"
                error={props.error}
                inputProps={{
                  ...params.inputProps,
                  placeholder: props.placeholder,
                }}
              />
            )}
          />
        )
      ) : (
        props.data && (
          <Select
            disabled={props.disabled}
            className={`select ${props.className}`}
            onChange={props.onChange}
            name={props.name}
            value={props.value}
            multiple={props.multiple}
            inputProps={{
              "aria-label": "Without label",
              className: `${
                props.value === props.data[0].id ||
                props.value[0] === props.data[0].id
                  ? "gray"
                  : "gray400"
              }`,
            }}
            error={props.error}
            renderValue={(selected) => {
              if (props.multiple) {
                const dataSelect = selected.map((el: any, index: number) => {
                  const itemSelected = props.data?.find(
                    (val, index) => val.id === el
                  );
                  return (
                    itemSelected &&
                    itemSelected[
                      `${props.name}${currentLanguage.isEN ? "En" : "Vi"}`
                    ]
                  );
                });
                return dataSelect.join(", ");
              } else {
                const itemSelected = props.data?.find(
                  (val, index) => val.id === selected
                );
                return (
                  itemSelected &&
                  itemSelected[
                    `${props.name}${currentLanguage.isEN ? "En" : "Vi"}`
                  ]
                );
              }
            }}
          >
            {props.data.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {props.multiple && item.id !== "default" && (
                  <Checkbox checked={props.value.indexOf(item.id) > -1} />
                )}
                {item[`${props.name}${currentLanguage.isEN ? "En" : "Vi"}`]}
              </MenuItem>
            ))}
          </Select>
        )
      )}
    </div>
  );
}

export default CustomSelect;
export type { SelectItemType };
