import { Card, CardContent, Container, Grid } from "@mui/material";
import moment from "moment";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ProjectModel, ProjectStatus } from "../../apis/general-client";
import AppBreadcrumbs from "../../components/app-breadcrumbs";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import StyledVariables from "../../helpers/materials/_variables";
import ProductDetailRepositories from "./project-detail-controller/project-detail-repositories";
import WatchVideoICon from "../../assets/icons/watch_video.svg";
import VitualTourIcon from "../../assets/icons/Vitual_Tour.svg";
import FloorPlanIcon from "../../assets/icons/floor-plan.svg";
import MapsLineIcon from "../../assets/icons/maps-line.svg";
import ProjectDetailFeatures from "./project-detail-features";
import ProjectDetailSellers from "./project-detail-sellers";
import { currentLanguage } from "../../helpers/utils";
import ViewImageComponent from "../../components/view-image-component";

type ProjectDetailLinkButtonType = {
  label: string;
  value?: string;
  backgroundColor: string;
  textColor: string;
  icon?: string;
};

type ProjectDetailStatusTagType = {
  statusName: string;
  backgroundColor: string;
};

type MenuTabType = {
  key: string;
  ref: string;
  isActive?: boolean;
  onClick: () => void;
};

function ProjectDetailScreen() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [projectDetailData, setProjectDetailData] = useState<ProjectModel>();
  const [googleMapUrl, setGoogleMapUrl] = useState<string>("");

  const projectFeatures = useRef<HTMLInputElement>(null);
  const projectDetailDescriptions = useRef<HTMLInputElement>(null);
  const mapView = useRef<HTMLInputElement>(null);

  const projectDetailLinkButtons: ProjectDetailLinkButtonType[] = [
    {
      label: "video",
      value: projectDetailData?.video,
      backgroundColor: StyledVariables.colors.white,
      textColor: StyledVariables.colors.greenLight,
      icon: WatchVideoICon,
    },
    {
      label: "virtualTour",
      value: projectDetailData?.virtualTour,
      backgroundColor: StyledVariables.colors.greenLight,
      textColor: StyledVariables.colors.white,
      icon: VitualTourIcon,
    },
    {
      label: "floorPlans",
      value: projectDetailData?.floorPlans,
      backgroundColor: StyledVariables.colors.white,
      textColor: StyledVariables.colors.yellow700,
      icon: FloorPlanIcon,
    },
    {
      label: "neighbourhood",
      value: googleMapUrl,
      backgroundColor: StyledVariables.colors.yellow700,
      textColor: StyledVariables.colors.white,
      icon: MapsLineIcon,
    },
  ];

  const tabList: MenuTabType[] = [
    {
      key: "projectFeatures",
      ref: "projectFeatures",
      isActive: false,
      onClick: () => {
        projectFeatures.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
    {
      key: "projectDetailDescriptions",
      ref: "projectDetailDescriptions",
      isActive: false,
      onClick: () => {
        projectDetailDescriptions.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
    {
      key: "neighbourhood",
      ref: "mapView",
      isActive: false,
      onClick: () => {
        mapView.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      },
    },
  ];

  const projectDetailStatusTag: ProjectDetailStatusTagType = {
    statusName: projectDetailData?.statusName
      ? projectDetailData.statusName
      : "",
    backgroundColor: StatusTagColor(),
  };

  function StatusTagColor(): string {
    if (projectDetailData?.status === ProjectStatus.CommingSoon) {
      return StyledVariables.colors.yellow700;
    } else if (projectDetailData?.status === ProjectStatus.AlmostSoldOut) {
      return StyledVariables.colors.redDark;
    } else if (projectDetailData?.status === ProjectStatus.OpenForSale) {
      return StyledVariables.colors.greenLight;
    }
    return "";
  }

  useEffect(() => {
    getProjectDetail();
  }, []);

  async function getProjectDetail() {
    const data = await ProductDetailRepositories.getProductDetailBySlug(slug || "");
    await ProductDetailRepositories.viewCountProject(slug || "");

    if (data.latitude && data.longtitude) {
      let url =
        "https://www.google.com/maps/@" +
        data.latitude +
        "," +
        data.longtitude +
        "z";
      setGoogleMapUrl(url);
    }

    setProjectDetailData(data);
  }

  return projectDetailData ? (
    <div className="project-detail-container">
      <HeaderBar />
      {projectDetailData.coverImageUrl ? (
        <div
          className="project-cover-container"
          style={{
            backgroundImage: `url(` + projectDetailData.coverImageUrl + `)`,
          }}
        >
          <Container className="app-breadcrumbs">
            <AppBreadcrumbs
              transactionTypeId={"003"}
              detailTitle={
                currentLanguage.isEN
                  ? projectDetailData.projectEn
                  : projectDetailData.projectVi
              }
            />
          </Container>
          <div className="project-title">
            <CustomText>
              {currentLanguage.isEN
                ? projectDetailData.projectEn
                : projectDetailData.projectVi}
            </CustomText>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Container>
        {!projectDetailData.coverImageUrl && (
          <AppBreadcrumbs projectDetailData={projectDetailData} />
        )}
        <div className="project-content">
          <div className="section">
            {/* Summary Detail */}
            <Card className="card-item">
              <CardContent>
                <div className="dBlock md:dFlex justifyBetween itemsCenter">
                  <div className="project-summary-left">
                    <div className="project-logo">
                      <img src={projectDetailData.projectLogoUrl} alt="" />
                    </div>
                  </div>
                  <div className="project-summary-right">
                    <ul>
                      <li className="mb12">
                        <div className="dBlock md:dFlex itemsCenter">
                          <CustomText className="semiBold">
                            {t("status") + ": "}
                          </CustomText>
                          {projectDetailStatusTag &&
                            projectDetailStatusTag.statusName && (
                              <CustomText
                                className="status-tag ml12"
                                style={{
                                  backgroundColor:
                                    projectDetailStatusTag.backgroundColor,
                                }}
                              >
                                {t(projectDetailStatusTag.statusName)}
                              </CustomText>
                            )}
                        </div>
                      </li>
                      <li className="mb12">
                        <div className="dFlex itemsCenter">
                          <CustomText className="semiBold">
                            {t("startDate") + ":"}
                          </CustomText>
                          <CustomText className="ml4">
                            {moment(projectDetailData.startDate).format("YYYY")}
                          </CustomText>
                        </div>
                      </li>
                      <li className="mb12">
                        <div className="dFlex itemsCenter">
                          <CustomText className="semiBold">
                            {t("estimateCompletionDate") + ":"}
                          </CustomText>
                          <CustomText className="ml4">
                            {moment(projectDetailData.endDate).format("YYYY")}
                          </CustomText>
                        </div>
                      </li>
                      <li style={{ listStyle: "none" }}>
                        <div className="link-buttons mt20">
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={11} lg={10} xl={7}>
                              <Grid container spacing={2}>
                                {projectDetailLinkButtons &&
                                  projectDetailLinkButtons.map(
                                    (
                                      item: ProjectDetailLinkButtonType,
                                      index: number
                                    ) =>
                                      item.value && (
                                        <Grid key={index} item xs={12} md={6}>
                                          <a
                                            href={item.value}
                                            className="borderNone"
                                            target={"_blank"}
                                            rel="noreferrer"
                                          >
                                            <div
                                              className="button dFlex itemsCenter"
                                              style={{
                                                backgroundColor:
                                                  item.backgroundColor,
                                                borderWidth: "1px",
                                                borderColor: item.textColor,
                                                borderStyle: "solid",
                                              }}
                                            >
                                              <div className="button-image">
                                                <img src={item.icon} alt="" />
                                              </div>
                                              <CustomText
                                                style={{
                                                  color: item.textColor,
                                                }}
                                              >
                                                {t(item.label)}
                                              </CustomText>
                                            </div>
                                          </a>
                                        </Grid>
                                      )
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* project images */}
          <div className="section">
            <ViewImageComponent
              images={projectDetailData.projectImages}
              imageVariable={"imagesPathUrl"}
            />
          </div>

          <div className="section">
            <div className="menu-tab-list">
              {tabList?.map((item: MenuTabType, index: any) => (
                <div key={index} className="tab-item" onClick={item.onClick}>
                  <CustomText className="semiBold">{t(item.key)}</CustomText>
                </div>
              ))}
            </div>
          </div>

          <div className="section">
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={9}>
                {/* features */}
                <Card
                  className="card-item"
                  ref={projectFeatures}
                  id="projectFeatures"
                >
                  <CardContent>
                    <CustomText className="fontMP greenLight mb20 semiBold">
                      {t("projectFeatures")}
                    </CustomText>
                    <ProjectDetailFeatures
                      projectFeatures={projectDetailData.projectFeatures}
                    />
                  </CardContent>
                </Card>

                {/* descriptions */}
                <Card
                  className="card-item"
                  ref={projectDetailDescriptions}
                  id="projectDetailDescriptions"
                >
                  <CardContent>
                    <CustomText className="fontMP greenLight semiBold">
                      {t("projectDetailDescriptions")}
                    </CustomText>
                    <div
                      className="fontM project-descriptions"
                      dangerouslySetInnerHTML={{
                        __html: projectDetailData.descriptions
                          ? projectDetailData.descriptions
                          : "",
                      }}
                    ></div>
                  </CardContent>
                </Card>

                {/* map view */}
                {projectDetailData.mapViewImageUrl && (
                  <Card className="card-item" ref={mapView} id="mapView">
                    <CardContent>
                      <CustomText className="fontMP greenLight mb20 semiBold">
                        {t("neighbourhood")}
                      </CustomText>
                      <div className="project-mapview">
                        <a
                          href={googleMapUrl}
                          className="borderNone"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <img
                            className="w100"
                            src={projectDetailData.mapViewImageUrl}
                            alt=""
                          />
                        </a>
                      </div>
                    </CardContent>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <ProjectDetailSellers
                  projectSellers={projectDetailData.projectSellers}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <FooterComponent />
    </div>
  ) : (
    <></>
  );
}

export default ProjectDetailScreen;
