import { DriveEta } from "@mui/icons-material";
import { Button } from "@mui/material";
import { memo, useLayoutEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ListPropertyModel } from "../../apis/general-client";
import AppModal, { SearchingPropertyType } from "../../apis/app-modal";
import MultiSlides from "../../components/multi-slides";
import { CustomText } from "../../components/styled-custom";
import { currentLanguage, formatLotSize, formatNumber, getPrice } from "../../helpers/utils";

type HomeLatestPropertyType = {
  latestPropertiesData?: ListPropertyModel[];
};

function HomeLatestProperty(props: HomeLatestPropertyType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const slideImageRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const onNavigate = () => {
    AppModal.searchingPropertyModel = {} as SearchingPropertyType;
    navigate("/property", { state: { viewMore: "lates" } });
  };

  function handleSlideImage() {
    if (slideImageRef.current) {
      const slideWidth = slideImageRef.current.offsetWidth;
      let slideItemWidth = slideWidth;

      if (slideWidth >= 1104) {
        slideItemWidth = slideWidth / 5;
      }
      if (slideWidth > 671 && slideWidth < 1104) {
        slideItemWidth = slideWidth / 3;
      }

      const calHeight = slideItemWidth / (5 / 4);
      setImageHeight(calHeight);
    }
  }

  useLayoutEffect(() => {
    handleSlideImage();
    window.addEventListener("resize", handleSlideImage, false);
    return () => window.removeEventListener("resize", handleSlideImage, false);
  }, []);

  const onNavigateDetail = (
    transactionTypeId?: string | undefined,
    slug?: string
  ) => {
    if (transactionTypeId === "004" || transactionTypeId === "005") {
      navigate("/commercial/" + slug, { state: { index: 3 } });
    } else {
      navigate("/property/" + slug, { state: { id: transactionTypeId } });
    }
  };

  return (
    <div ref={slideImageRef} className="luxury-slides-container">
      {props.latestPropertiesData && (
        <MultiSlides
          responsive={{ desktop: 4, tabletL: 3, tabletM: 2, mobile: 1 }}
        >
          {props.latestPropertiesData.map((item, index) => (
            <div key={index} className="handle-hover">
              <div
                className="h100"
                onClick={() =>
                  onNavigateDetail(item.transactionTypeId, item.slug)
                }
                style={{ textDecoration: "none" }}
              >
                <Button className="luxury-slides-item">
                  <div className="image-item" style={{ height: imageHeight }}>
                    <img src={item.coverImageUrl} alt="" draggable={false} />
                  </div>
                  <div className="content-item">
                    <CustomText className="text-title fontMP semiBold mb12">
                      {item.title}
                    </CustomText>
                    <CustomText className="fontMP mb12">
                      {currentLanguage.isEN
                        ? item.propertyTypeEn
                        : item.propertyTypeVi}
                    </CustomText>
                    <CustomText className="fontSP mb12">
                      {/* {item.location} */}
                      {currentLanguage.isEN ? item.districtNameEn : item.districtName}, {currentLanguage.isEN ? item.provinceNameEn : item.provinceName}
                    </CustomText>
                    <CustomText className="fontMP semiBold mb12">
                      {getPrice(
                          item.usdPrice!,
                          item.currencyNotation!,
                          item.price!
                        )}
                      {(item.transactionTypeId === "001" || item.transactionTypeId === "004") && (
                        <span>/ {t("daily")}</span>
                      )}
                    </CustomText>

                    <div className="info-content">
                      <CustomText className="fontSP">
                        {formatLotSize(item.lotSizeFeet || 0)} ft2
                      </CustomText>
                      {(item.transactionTypeId !== "004" && item.transactionTypeId !== "005") && (
                        <CustomText className="fontSP">
                          {item.bedroomEn + " " + t("beds")}
                        </CustomText>
                      )}
                      {(item.transactionTypeId !== "004" && item.transactionTypeId !== "005") && (
                        <CustomText className="fontSP">
                          {item.bathroomEn + " " + t("baths")}
                        </CustomText>
                      )}
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          ))}
        </MultiSlides>
      )}

      {props.latestPropertiesData && props.latestPropertiesData?.length > 4 && (
        <div className="borderNone">
          <Button
            onClick={() => onNavigate()}
            variant="outlined"
            className="view-more-btn"
          >
            {t("viewMore")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default memo(HomeLatestProperty);
