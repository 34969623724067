import { useTranslation } from "react-i18next";
import { CustomText } from "../../components/styled-custom";
import { ReactComponent as Logo } from "../../assets/images/Logo-Footer-green.svg";

type AuthSuccessDialogType = {
  goToSignIn: () => void;
};

function AuthSuccessDialog(props: AuthSuccessDialogType) {
  const { t } = useTranslation();

  return (
    <div className="auth-success-screen">
      <div className="auth-title">
        <CustomText>{t("congratulations")}</CustomText>
      </div>
      <div className="auth-content">
        <Logo />
        <div className="congratulation">
          <CustomText>{t("createAccountSuccessfullyNoti")}</CustomText>
          {/* <CustomText className="login-btn" onClick={props.goToSignIn}>
            {t("loginNow")}
          </CustomText> */}
        </div>
        <div className="message">
          <CustomText>{t("checkEmailForVerify")}</CustomText>
        </div>
      </div>
    </div>
  );
}

export default AuthSuccessDialog;
