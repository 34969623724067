import { VerifyAccountModel } from "../../../apis/general-client";
import { generalClient } from "../../../helpers/utils";

const VerifyEmailRepositories = {
    verifyAccount: async (request: VerifyAccountModel) => {
        const res = await generalClient.user_VerifyAccount(request);
        return res;
    }
}

export default VerifyEmailRepositories