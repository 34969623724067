import { Check } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/Logo-Footer.svg";
import { ReactComponent as Facebook } from "../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../assets/icons/twitter.svg";
import { ReactComponent as Youtube } from "../assets/icons/youtube.svg";
import { CustomText } from "./styled-custom";
import { useEffect, useState } from "react";
import { AppRepositories } from "../apis/app-repositories";
import LazyLoad from "react-lazy-load";
import { SocialNetworkModel } from "../apis/general-client";

function FooterComponent() {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isOpenService, setOpenService] = useState<boolean>(false);
  const [dataSocials, setMasterDatas] = useState<any>({});

  const toggle = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const toggleService = (isOpenService: boolean) => {
    setOpenService(isOpenService);
  };

  const getSocial = async () => {
    let val = {
      page: 1,
      pageSize: 12,
    };
    const result = await AppRepositories.getSocial(val);
    setMasterDatas(result);
  };

  useEffect(() => {
    getSocial();
  }, []);

  return (
    <LazyLoad offset={10}>
      <>
        <div className="footer-component">
          <Container>
            <Logo className="logo-footer" />
            <div className="content">
              <Grid container spacing={8}>
                <Grid item lg={2} xs={12} md={3}>
                  <CustomText className="white fontL">
                    {t("ourServices")}
                  </CustomText>
                </Grid>
                <Grid item lg={5} xs={12} md={4}>
                  <CustomText className="semiBold">
                    {t("servicesForForeigners")}
                  </CustomText>
                  <CustomText>
                    <Check />
                    {t("vietnamIntroduction")}
                  </CustomText>
                  <CustomText>
                    <Check />
                    {t("realEstateManagement")}
                  </CustomText>

                  <div onClick={() => toggleService(!isOpenService)}>
                    {!isOpenService ? (
                      <CustomText>
                        <Link to={""}>
                          {t("moreServices")}
                        </Link>
                      </CustomText>
                    ) : (
                      <div>
                        <CustomText>
                          <Check />
                          {t("salesOrLease")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t(
                            "representingInternationalInvestorsSeekingToFundRealEstateDevelopmentsInVietnam"
                          )}
                        </CustomText>
                        <CustomText>
                          <Link to={""}>
                            {t("less")}
                          </Link>
                        </CustomText>
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item lg={5} xs={12} md={5}>
                  <CustomText className="semiBold">
                    {t("legalForForeigners")}
                  </CustomText>
                  <CustomText>
                    <Check />
                    {t("vietnamVisa")}
                  </CustomText>
                  <CustomText>
                    <Check />
                    {t("obtainLicense")}
                  </CustomText>
                  <div onClick={() => toggle(!isOpen)}>
                    {!isOpen ? (
                      <CustomText>
                        <Link to={""}>
                          {t("moreServices")}
                        </Link>
                      </CustomText>
                    ) : (
                      <div>
                        <CustomText>
                          <Check />
                          {t("temporaryResidentCard")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("visaExtension")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("visaExemption")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("setupCompany")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("workPermit/GP")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("criminalRecordCertificate")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("approvalLetterOnArrival")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("consularLegalization")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("personalLawyerServices")}
                        </CustomText>
                        <CustomText>
                          <Check />
                          {t("interpretationNotarization")}
                        </CustomText>
                        <CustomText>
                          <Link to={""}>
                            {t("less")}
                          </Link>
                        </CustomText>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <div className="bottom-line">
          <Container>
            <CustomText className="white">{t("vnHomeLuxury")}</CustomText>
            <div className="right-side">
              <div>
                {dataSocials?.items && (
                  <div>
                    {dataSocials?.items.map(
                      (item: SocialNetworkModel, index: number) =>
                        item.isShowFooter && (
                          <a
                            key={index}
                            href={item.descriptions}
                            target="_blank"
                          >
                            <img src={item.iConUrl} alt="Image Socials" />
                          </a>
                        )
                    )}
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </>
    </LazyLoad>
  );
}

export default FooterComponent;
