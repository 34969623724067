import { Alert, Button, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { ChangeEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomCard from "../../../components/custom-card";
import CustomTextField from "../../../components/custom-text-field";
import { CustomText } from "../../../components/styled-custom";
import { appLoading, getUser } from "../../../helpers/utils";
import MyAccountRepositories from "./controllers/my-account-repositories";

function ChangePass() {
  const { t } = useTranslation();
  const user = getUser();
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpenNoti] = useState(false);

  const textFieldData: any[] = useMemo(
    () => [
      {
        label: "currentPass",
        name: "currentPass",
        helperText: "Field is",
      },
      {
        label: "newPassword",
        name: "newPassword",
        helperText: "Field is",
      },
      {
        label: "rePassword",
        name: "rePassword",
        helperText: "Field is",
      },
    ],
    []
  );
  const [inputValue, setInputValue] = useState<any>({
    currentPass: { value: "", error: "" },
    newPassword: { value: "", error: "" },
    rePassword: { value: "", error: "" },
  });
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    inputValue[targetName].value = targetValue;
    inputValue[targetName].error = "";
    setInputValue({ ...inputValue });
    if (targetName === "newPassword" && inputValue["rePassword"].value != "") {
      if (inputValue["newPassword"].value != inputValue["rePassword"].value) {
        inputValue["rePassword"].error = "passwordNotMatch";
        setInputValue({ ...inputValue });
      } else {
        inputValue["rePassword"].error = "";
        setInputValue({ ...inputValue });
      }
    } else if (
      targetName === "rePassword" &&
      inputValue["newPassword"].value != ""
    ) {
      if (inputValue["newPassword"].value != inputValue["rePassword"].value) {
        inputValue["rePassword"].error = "passwordNotMatch";
        setInputValue({ ...inputValue });
      } else {
        inputValue["rePassword"].error = "";
        setInputValue({ ...inputValue });
      }
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    onValidate();
    if (
      !inputValue["currentPass"].error &&
      !inputValue["newPassword"].error &&
      !inputValue["rePassword"].error
    ) {
      appLoading.show();
      let post = {
        userName: user.userName,
        oldPassword: inputValue["currentPass"].value,
        newPassword: inputValue["newPassword"].value,
        confirmPassword: inputValue["rePassword"].value,
      };
      await MyAccountRepositories.changePass(post)
        .then((res) => {
          if (res.succeeded) {
           
            setOpenNoti(true);
            setIsShowError(false);
            setInputValue({
              currentPass: { value: "", error: "" },
              newPassword: { value: "", error: "" },
              rePassword: { value: "", error: "" },
            });
          } else {
            let error = res.errors ? res.errors[0] : "";
            setOpenNoti(true);

            setIsShowError(true);
            if (res.errors) {
              setErrorMessage(t(res.errors[0]));
            }
          }
        })
        .catch((err) => {
          let error = err.errors ? err.errors[0] : "";
          setIsShowError(true);

          setErrorMessage(t("errorSystem"));
          setOpenNoti(true);
        });

      appLoading.dismiss();
    }
  };
  const onValidate = () => {
    Object.keys(inputValue).forEach((key: any, index: number) => {
      const _inputValue = inputValue[key];
      if (
        key === "rePassword" &&
        _inputValue.value !== inputValue["newPassword"].value
      ) {
        _inputValue.error = "passwordNotMatch";
      }
      if (key === "newPassword") {
        if (_inputValue.value.length < 6) {
          _inputValue.error = "passMoreThanSixCharacter";
        }
      }
      if (_inputValue.value === "") {
        _inputValue.error = "requiredField";
      }
    });
    setInputValue({ ...inputValue });
  };
  useEffect(() => {
    setInputValue(inputValue);
  });
  return (
    <div className="right change-pass w100">
      <CustomCard title={t("changePassword")}>
        <div className="change-pass pt40">
          {textFieldData.map((item, index) => (
            <div>
              <div className="form-label pb16 pt40">
                <CustomText>{t(item.label)}</CustomText>
              </div>
              <CustomTextField
                key={index}
                className="auth-text-field"
                label=""
                type="password"
                name={item.name}
                onChange={onInputChange}
                inputProps={{
                  placeholder: t('minimun6Characters'),
                  value: inputValue[item.name].value,
                  error: inputValue[item.name].error?.length > 0,
                }}
                helperText={
                  inputValue[item.name].error && t(inputValue[item.name].error)
                }
              ></CustomTextField>
            </div>
          ))}
        </div>
        <div className="button-group py40">
          <Button className="save-submit " onClick={onSubmit}>
            {t("changePassword")}
          </Button>
        </div>
      </CustomCard>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={!isShowError ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {!isShowError ? (
            <CustomText className="bold">{t("chanegPassSuccess")}</CustomText>
          ) : (
            <CustomText className="bold">{errorMessage}</CustomText>
          )}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ChangePass;
