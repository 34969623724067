import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Snackbar,
} from "@mui/material";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import FooterComponent from "../../components/footer-component";
import CustomTextField from "../../components/custom-text-field";
import { useNavigate } from "react-router-dom";
import ForgotPasswordRepositories from "./forgot-password-controller/forgot-password-repositories";
import { ForgetPasswordModel } from "../../apis/general-client";
import DialogComponent from "../../components/dialog-component";
import VerifySuccessfulIcon from "../../assets/icons/verify-successful.svg";
import { getToken } from "../../helpers/utils";

function ForgotPasswordScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openNoti, setOpenNoti] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isOpenAuthDialog, setIsOpenAuthDialog] = useState<boolean>(false);

  const [dataInput, setDataInput] = useState<any>({
    emailAddress: "",
  });

  const [disable, setDisable] = useState<boolean>(true);

  const onSendEmail = async () => {
    const accessToken = getToken() != null;
    if (!accessToken) {
      const requestModel = {
        email: dataInput.emailAddress,
      } as ForgetPasswordModel;

      var result = await ForgotPasswordRepositories.forgetPassword(
        requestModel
      );
      if (result && result.succeeded) {
        setOpenDialog(true);
        dataInput.emailAddress = "";
      } else {
        let error = result.errors ? result.errors[0] : "";
        setErrorMessage(error);
        setOpenNoti(true);
      }
    }
  };

  const getDataInput = (e: ChangeEvent<HTMLInputElement>) => {
    dataInput.emailAddress = e.target.value;
    setDataInput({ ...dataInput });

    const objContact = Object.entries(dataInput).filter(
      ([object, key]) => key === ""
    );

    setDisable(objContact.length !== 0);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };

  const onOpenSignInPopup = () => {
    setIsOpenAuthDialog(true);
  };

  const onCloseSignPopup = () => {
    setIsOpenAuthDialog(false);
  };

  return (
    <div className="forgot-password-container">
      <HeaderBar
        isOpenAuthDialog={isOpenAuthDialog}
        setIsOpenAuthDialog={() => onCloseSignPopup()}
      />
      <Container>
        <Card className="card card-forgot-password">
          <CardContent>
            <CustomText className="fontL greenLight semiBold uppercase">
              {t("forgotYourPassword")}
            </CustomText>
            <CustomText className="mt20">
              {t("enterEmailToResetPassword")}
            </CustomText>

            <div className="input-group mt20 mb20">
              <CustomTextField
                label=""
                inputProps={{
                  placeholder: t("emailAddress"),
                  value: dataInput.emailAddress,
                }}
                row={7}
                onChange={getDataInput}
                name={"emailAddress"}
              />
            </div>

            <div className="dFlex justifyCenter itemsCenter">
              <Button
                className="btn cancel-btn"
                onClick={() => onOpenSignInPopup()}
              >
                {t("cancel")}
              </Button>
              <Button
                disabled={disable}
                className="btn reset-btn"
                onClick={onSendEmail}
              >
                {t("reset")}
              </Button>
            </div>

            <Snackbar
              open={openNoti}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {t(errorMessage)}
              </Alert>
            </Snackbar>
          </CardContent>
        </Card>

        <DialogComponent
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          className="forgot-password-success-dialog"
        >
          <div className="textCenter">
            <CustomText className="fontL greenLight semiBold uppercase">
              {t("sendForgotPasswordEmailSuccessfully")}
            </CustomText>
            <div className="mt20 success-icon">
              <img src={VerifySuccessfulIcon} alt="" />
            </div>
            <CustomText className="mt20">
              {t("checkEmailToGetResetPasswordLink")}
            </CustomText>
          </div>
        </DialogComponent>
      </Container>
      <FooterComponent />
    </div>
  );
}

export default ForgotPasswordScreen;
