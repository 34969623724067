import {
  Alert,
  Button,
  Container,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import * as React from "react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AppModal, { SearchingPropertyType } from "../../apis/app-modal";
import { AppRepositories } from "../../apis/app-repositories";
import { PagingNearestPropertyModel } from "../../apis/general-client";
import { ReactComponent as Bookmark } from "../../assets/icons/Bookmark.svg";
import CustomTextField from "../../components/custom-text-field";
import DialogComponent from "../../components/dialog-component";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import SearchBar from "../../components/search-bar";
import { CustomText } from "../../components/styled-custom";
import {
  appLoading,
  basicHTML,
  getToken,
  recentlyProperty,
} from "../../helpers/utils";
import PropertyListRepositories from "./property-list-controllers/property-list-repositories";
import PropertyListScreen from "./property-list/property-list-component";
import PropertyMapScreen from "./property-map/property-map-component";

function PropertyMapListScreen(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogSaveSearch, setOpenSignIn] = useState<boolean>(false);
  const [keywordValue, setKeywordValue] = useState<string>("");
  // const [recentlyProperties, setRecentlyProperties] =
  //   useState<ListPropertyModel[]>();
  const [isload, setLoad] = React.useState<boolean>(false);
  const [index, setIndex] = React.useState<number>(0);
  const local = location?.state as any;
  const [page, setPage] = React.useState(1);
  const [masterData, setMasterData] = useState<any>({});
  const [expand, setExpand] = React.useState(true);

  const [fullItemData, setFullItemData] = useState<any>({});
  const [sortModelState, setSortModelState] = useState<{
    newest: Boolean;
    oldest: Boolean;
    lowestPrice: Boolean;
    highestPrice: Boolean;
  }>({
    newest: true,
    oldest: false,
    lowestPrice: false,
    highestPrice: false,
  });

  const [switchScreen, setSwitchScreen] = useState<boolean>(false);
  const [sortModel, changeSortModelEvent] = useState<boolean>(false);

  const [currentProvince, setCurrentProvince] = useState<any>({});

  async function getDatas() {
    appLoading.show();

    if (local?.viewMore === "recently") {
      const recentlyPropertyIds = recentlyProperty.get();
      const model = {
        pageNumber: 0,
        pageSize: 0,
        ids: recentlyPropertyIds,
      } as PagingNearestPropertyModel;
      const resDataRecently = await AppRepositories.getPropertyRecently(model);

      let hardCodeMasterData = {
        items: resDataRecently.items,
        // pageIndex: 0,
        // totalPages: 0,
        totalCount: resDataRecently.items?.length,
        hasPreviousPage: true,
        hasNextPage: true,
      };
      setLoad(true);
      setMasterData(hardCodeMasterData);
      setSwitchScreen(true);
    } else {
      let overrideSearchingPropertyModel = AppModal.searchingPropertyModel;
      overrideSearchingPropertyModel.transactionTypeId = AppModal.searchingPropertyModel.transactionTypeId ? 
        AppModal.searchingPropertyModel.transactionTypeId : local?.id;
      overrideSearchingPropertyModel.isManyCommercialProperty = overrideSearchingPropertyModel.isManyCommercialProperty === false ? false : local?.isManyCommercialProperty;
      
      if (local?.keyword) {
        overrideSearchingPropertyModel.propertyKeyWord = local?.keyword;
        local.keyword = "";
      }
      
      // paging
      let val = {
        pageNumber: page,
        pageSize: 12,
        sortingModel: sortModelState,
        ...overrideSearchingPropertyModel,
      };
      setCurrentProvince(val);
      const resData = await PropertyListRepositories.getPropertyList(val);
      setLoad(true);
      setMasterData(resData);

      if (local?.viewMore === "lates") {
        setSwitchScreen(true);
      }
    }
    appLoading.dismiss();
  }

  const [openNoti, setOpenNoti] = useState(false);
  const [openNotifalse, setOpenNotifalse] = useState(false);
  const initialInput: string[] = useMemo(() => ["name"], []);
  const [dataSaveSearch, setDataSaveSearch] = useState<any>({
    name: "",
    keyword: "",
  });

  const [disable, setDisable] = useState<boolean>(true);
  const getDataSaveSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dataSaveSearch[e.target.name] = e.target.value;
    setDataSaveSearch({ ...dataSaveSearch });
    const objContact = Object.entries(dataSaveSearch).filter(
      ([object, key]) => key === "" && object !== "name"
    );
    setDisable(objContact.length !== 0);
  };

  async function saveSearch() {
    let overrideSearchingPropertyModel = AppModal.searchingPropertyModel;

    const model = {
      name: dataSaveSearch.name,
      keyword: AppModal.searchingPropertyModel.propertyKeyWord
        ? AppModal.searchingPropertyModel.propertyKeyWord
        : "",
      type: overrideSearchingPropertyModel?.transactionTypeId,
    };
    const resData = await PropertyListRepositories.createSaveSearch(model);

    if (resData.succeeded) {
      setOpenNoti(true);
      setOpenDialog(false);
      // setOpenNotifalse(false);
    } else {
      setOpenNotifalse(true);
    }
  }
  const checkExpand = (event: boolean) => {
    setExpand(event);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
    setOpenNotifalse(false);
  };

  const clearData = () => {
    // if (location.pathname !== "/property") {
    //   AppModal.searchingPropertyModel = {} as SearchingPropertyType;
    // }
    window.removeEventListener("popstate", () => {
      if (location.pathname !== "/property") {
        AppModal.searchingPropertyModel = {} as SearchingPropertyType;
      }
    });
  };

  useEffect(() => {
    getDatas();
    window.addEventListener("popstate", () => {
      AppModal.searchingPropertyModel = {} as SearchingPropertyType;
    });
    return clearData();
  }, [page, local?.id, local?.sort, sortModelState,openDialogSaveSearch]);

  // paginate
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeScreen = (value: boolean) => {
    setSwitchScreen(value);
  };

  const onFilter = async (sort: number) => {
    const sortModelState = {
      newest: sort === 1,
      oldest: sort === 2,
      lowestPrice: sort === 3,
      highestPrice: sort === 4,
    };
    setSortModelState(sortModelState);
  };

  const onSearch = () => {
    getDatas();
  };

  const onSaveSearch = () => {
    const token = getToken() != null;
    if (token) {
      var searchModel = AppModal.searchingPropertyModel;
      // setOpenNoti(false);
      setOpenDialog(true);
      dataSaveSearch["name"] = searchModel?.propertyKeyWord
        ? searchModel?.propertyKeyWord
        : "";
      dataSaveSearch["keyword"] = searchModel?.propertyKeyWord
        ? searchModel?.propertyKeyWord
        : "";
      window.scrollTo(0, 0);
      setDataSaveSearch({ ...dataSaveSearch });
    } else {
      setOpenSignIn(true);
    }
  };

  const onSave = () => {
    saveSearch();
  };

  const setSignInModel = (val: boolean) => {
    setOpenSignIn(val);
  };

  return (
    <div className="map-list-container w100">
      <HeaderBar
        setIndex={setIndex}
        isopen={openDialogSaveSearch}
        setSignInModel={setSignInModel}
      />
      {!switchScreen && (
        <div className="pt12">
          <SearchBar
            index={index}
            isMappage
            onSearch={onSearch}
            transactionId={local?.id}
          />
        </div>
      )}
      {isload && (
        <Container
          className={`ptMoblie container ${!switchScreen ? "container-map-option" : ""
            }`}
        >
          <div className={!switchScreen ? "btn-list-map" : "btn-list-list"}>
            <span className="borderNone">
              <Button
                onClick={() => handleChangeScreen(false)}
                className={switchScreen ? "btn-map" : "btn-list"}
              >
                {t("map")}
              </Button>
            </span>
            <span className="borderNone">
              <Button
                onClick={() => handleChangeScreen(true)}
                className={!switchScreen ? "btn-map" : "btn-list"}
              >
                {t("list")}
              </Button>
            </span>
          </div>
          <div
            className={
              !switchScreen ? "header-property-map" : "header-property-list"
            }
          >
            <div
              className={`contentCount header-property-item ${expand ? "" : "bg-white"
                }`}
            >
              <CustomText>
                {basicHTML(
                  t("resultTotalListings", { number: masterData.totalCount })
                )}
              </CustomText>
            </div>
            <div
              className={`contentSave header-property-item ${expand ? "" : "bg-white"
                }`}
            >
              <CustomText className="txtSave">{t("saveSearch")}</CustomText>
              <div className="btnImgSave" onClick={() => onSaveSearch()}>
                <Bookmark className="img-save" />
              </div>
            </div>
            {expand ? (
              <div className="content-sort header-property-item">
                <CustomText>{t("sortBy")} </CustomText>
                <Select
                  className="formSelect"
                  id="demo-simple-select-standard"
                  onChange={(e: any) => onFilter(e.target.value)}
                  defaultValue={1}
                >
                  <MenuItem value={1}>
                    <CustomText>{t("newest")}</CustomText>
                  </MenuItem>
                  <MenuItem value={2}>
                    <CustomText>{t("oldest")}</CustomText>
                  </MenuItem>
                  <MenuItem value={3}>
                    <CustomText>{t("lowestPrice")}</CustomText>
                  </MenuItem>
                  <MenuItem value={4}>
                    <CustomText>{t("highestPrice")}</CustomText>
                  </MenuItem>
                </Select>
              </div>
            ) : (
              ""
            )}
          </div>
          <DialogComponent
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            className="save-search-bar-dialog"
            containerClassName="dialog-save-search"
          >
            <CustomText className="title-save">
              {t("saveYourSearch")}
            </CustomText>
            <CustomText className="title-name">{t("citiSearch")}</CustomText>

            <div className="pRelative">
              {initialInput.map((item, index) => (
                <CustomTextField key={index}
                  className="save-search-input"
                  label=""
                  inputProps={{
                    placeholder: t("citiSave"),
                    value: dataSaveSearch[item],
                  }}
                  onChange={getDataSaveSearch}
                  name={item}
                />
              ))}
            </div>

            <div className="dFlex justifyCenter dialog-save-search-button mt20">
              <Button
                className="cancel"
                variant="outlined"
                onClick={() => {
                  setOpenDialog(false);
                  // onClick={cancel}
                }}
              >
                {t("cancel")}
              </Button>
              <Button
                className="save"
                variant="outlined"
                onClick={() => onSave()}
              >
                {t("save")}
              </Button>
            </div>
          </DialogComponent>

          {openNoti === true && (
            <Snackbar
              open={openNoti}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {t("nameYourSearchSent")}
              </Alert>
            </Snackbar>
          )}
          {openNotifalse === true && (
            <Snackbar
              open={openNotifalse}
              autoHideDuration={2000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {t("nameYourSearchExist")}
              </Alert>
            </Snackbar>
          )}
        </Container>
      )}

      {switchScreen ? (
        <PropertyListScreen
          index={index}
          // changeSortModelEvent={setSortModelState}
          masterData={masterData}
          changePage={setPage}
          currentLocalId={local?.id}
          setSignInModel={setSignInModel}
        />
      ) : (
        <PropertyMapScreen
          index={index}
          setSignInModel={setSignInModel}
          // changeSortModelEvent={setSortModelState}
          masterData={masterData}
          fullData={fullItemData}
          checkExpand={(e: boolean) => checkExpand(e)}
          changePage={setPage}
          currentLocalId={local?.id}
          currentProvince={currentProvince}
        />
      )}
      <FooterComponent />
    </div>
  );
}

export default PropertyMapListScreen;
