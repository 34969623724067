import { Grid } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import NewsRepositories from "./news-controllers/news-list-repositories";
import { appLoading, currentLanguage } from "../../helpers/utils";
import { CustomText } from "../../components/styled-custom";
import CurrencyFormat from "react-currency-format";

function LatestScreen(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const local = location?.state as any;
  const navigate = useNavigate();
  const [isload, setLoad] = React.useState<boolean>(false);
  const [resData, setMasterData] = useState<any>({});
  const [dataRelateds, setMasterDataRelateds] = useState<any>({});
  const [resDataRelated, setMasterDataRelated] = useState<any>({});

  async function getDatasRelated() {
    let pagingModel = {
      pageNumber: 1,
      pageSize: 6,
    };
    const dataRelateds = await NewsRepositories.getLatestNews(pagingModel);
    setLoad(true);
    setMasterDataRelateds(dataRelateds);
  }

  useEffect(() => {
    getDatasRelated();
    setMasterDataRelated(props.resDataRelated);
  }, [local?.id, props.resDataRelated]);

  const onNavigate = (slug: string) => {
    navigate("/news/" + slug);
    window.scrollTo(0, 0);
  };

  return dataRelateds ? (
    <div className="news-detail-container w100 pointer">
      {dataRelateds?.items && (
        <div className="container-new">
          <div className="mapContent">
            <Grid
              container
              rowSpacing={4}
              columnSpacing={{ xs: 1, sm: 4, md: 4 }}
            >
              {dataRelateds?.items.map((item: any, index: number) => (
                <div className="border-new" onClick={() => onNavigate(item.slug)}>
                  <div className="card-wellread">
                    <img
                      className="img-lastest"
                      // component="img"
                      // height="100"
                      src={item.imagePathUrl}
                      alt="Image Well Read"
                    />
                    <div className="w100">
                      <div className="title-news-wellread">
                        <div className="txt-news-wellread">
                          <CustomText>
                            {currentLanguage.isEN ? item.titleEn : item.titleVi}
                          </CustomText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

export default LatestScreen;
