import Cookies from "js-cookie";
import { selectListType } from "../components/search-bar";
import { generalClient } from "../helpers/utils";
import {
  BannerTypes,
  CreatePropertyHeartModel,
  PagingNearestPropertyModel,
  SuggestSearchPropertyRequestModel,
} from "./general-client";
import MasterData from "./master-data";

const AppRepositories = {
  getPropertyList: async (data: any) => {
    const resData = await generalClient.property_SearchAndSortProperty(data);
    console.log("resData", resData);
    
    return resData;
  },

  getMasterData: async () => {
    const sessionCurrency = sessionStorage.getItem("currency");
    const sessionRate = sessionStorage.getItem("currencyRate");
    const masterData = await generalClient.masterData_GetMasterData();
    if (masterData) {
      MasterData.amenitiesNearby = masterData.amenitiesNearby;
      MasterData.bathroom = masterData.bathroomTypes;
      MasterData.bedroom = masterData.bedroomTypes;
      MasterData.currency = masterData.currency;
      MasterData.projectFeature = masterData.projectFeature;
      MasterData.properties = masterData.propertyType;
      MasterData.transaction = masterData.transactionType;
      MasterData.view = masterData.view;
      MasterData.currencyRate = sessionRate ? parseFloat(sessionRate) : 1;
      MasterData.currencyValue = sessionCurrency ? sessionCurrency : "USD";
    }
  },

  getPropertyById: async (propertyId: string) => {
    const res = await generalClient.property_GetById(propertyId);
    return res;
  },
  
  getPropertyBySlug: async (slug: string) => {
    const res = await generalClient.property_GetBySlug(slug);
    return res;
  },

  getAllBanners: async (type: BannerTypes) => {
    const resData = await generalClient.banner_GetByType(type);
    return resData;
  },

  getPropertyRecently: async (model: PagingNearestPropertyModel) => {
    const res = await generalClient.property_GetNearest(model);
    return res;
  },

  getSuggestProperty: async (model: SuggestSearchPropertyRequestModel) => {
    const res = await generalClient.property_SuggestProperty(model);
    return res;
  },

  propertyTypeByTransaction: async (request: string) => {
    const res = await generalClient.masterData_GetPropertyTypeByTransaction(
      request
    );
    return res;
  },

  sendPropertyHeart: async (model: CreatePropertyHeartModel) => {
    const res = await generalClient.propertyHeart_SendHeart(model);
    return res;
  },

  isPropertyHeart: async (model: CreatePropertyHeartModel) => {
    const res = await generalClient.propertyHeart_IsPropertyHeart(model);
    return res;
  },

  isManyPropertyHeart: async (userId: string, propertyIds: string[]) => {
    const res = await generalClient.propertyHeart_IsManyPropertyHeart(
      userId,
      propertyIds
    );
    return res;
  },

  getSocial: async (pagingModel: any) => {
    const res = await generalClient.socialNetwork_PagingSocialNetwork(pagingModel);
    return res;
  },

};

export { AppRepositories };
