type AmenitiesNearbyType = {
  id: string;
  amenitiesNearbyEn: string;
  amenitiesNearbyVi: string;
  descriptions: string;
};

type BathroomType = {
  id: string;
  bedroomTypesEn: string;
  bedroomTypesVi: string;
  descriptions: string;
};

type BedroomType = {
  id: string;
  bedroomTypesEn: string;
  bedroomTypesVi: string;
  descriptions: string;
};

type CurrencyType = {
  id: string;
  countryEn: string;
  countryVi: string;
  currencyName: string;
  notation: string;
  descriptions: string;
  symbol: string;
};

type ProjectFeatureType = {
  id: string;
  projectFeatureEn: string;
  projectFeatureVi: string;
  descriptions: string;
};

type PropertiesType = {
  id: string;
  propertyTypeEn: string;
  propertyTypeVi: string;
  descriptions: string;
};

type TransactionType = {
  id: string;
  transactionTypeEn: string;
  transactionTypeVi: string;
  notation: string;
  descriptions: string;
};

type ViewType = {
  id: string;
  viewEn: string;
  viewVi: string;
  descriptions: string;
};

interface MasterDataType
  extends AmenitiesNearbyType,
    BathroomType,
    BedroomType,
    CurrencyType,
    ProjectFeatureType,
    PropertiesType,
    TransactionType,
    ViewType {}

class MasterData {
  static amenitiesNearby: AmenitiesNearbyType[];
  static bathroom: BathroomType[];
  static bedroom: BedroomType[];
  static currency: CurrencyType[];
  static projectFeature: ProjectFeatureType[];
  static properties: PropertiesType[];
  static transaction: TransactionType[];
  static view: ViewType[];
  static currencyRate: number;
  static currencyValue: string;
}

export default MasterData;
export type {
  AmenitiesNearbyType,
  BathroomType,
  BedroomType,
  CurrencyType,
  ProjectFeatureType,
  PropertiesType,
  TransactionType,
  ViewType,
  MasterDataType,
};
