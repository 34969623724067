import Cookies from "js-cookie";
import { RegisterModel, RoleTypes } from "../../../apis/general-client";
import { generalClient } from "../../../helpers/utils";
import { CookieName } from "../../../types/cookie.types";
import userInfo from "./auth-model";

const AuthRepositories = {
  register: async (data: any) => {
    const req: any = {
      email: data["email"].value,
      userName: data["email"].value,
      password: data["password"].value,
      confirmPassword: data["rePassword"].value,
      avatar: "",
      firstName: data["firstName"].value,
      lastName: data["lastName"].value,
      birthDay: undefined,
      license: "",
      phoneNumber1: data["phoneNumber"].value,
      phoneNumber2: "",
      descriptions: "",
      role: RoleTypes.Guest,
      // returnUrl: "",
      // socialNetworks: [],
    };

    const res = await generalClient.user_Register(req);
    return res;
  },

  login: async (email: string, password: string, rememberMe: boolean) => {
    const req: any = {
      email,
      password,
      rememberMe,
    };

    const res = await generalClient.user_Login(req);
    if (res.succeeded) {
      localStorage.setItem("rememberMe", rememberMe.toString());
      sessionStorage.setItem("accessToken", res.accessToken!); //set token at sessionStorage when you turn off browser => token in session storage will be clear
      let accountInfo: any;
      if (rememberMe) {
        localStorage.setItem("accessToken", res.accessToken!);
        accountInfo = (await generalClient.profile_UserInfo2()) as any;
        localStorage.setItem("user", JSON.stringify(accountInfo));

        Cookies.set(CookieName.email, btoa(email));
        Cookies.set(CookieName.password, btoa(password));
      } else {
        accountInfo = (await generalClient.profile_UserInfo2()) as any;
        sessionStorage.setItem("user", JSON.stringify(accountInfo));
      }
    }

    return res.succeeded;
  },
};

export { AuthRepositories };
