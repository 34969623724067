import { Button } from "@mui/material";
import { memo, useLayoutEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AppModal, { SearchingPropertyType } from "../../apis/app-modal";
import { ListPropertyModel } from "../../apis/general-client";
import MultiSlides from "../../components/multi-slides";
import { CustomText } from "../../components/styled-custom";
import { currentLanguage, formatLotSize, formatNumber, getPrice } from "../../helpers/utils";

type HomeLuxurySlidesType = {
  recentlyPropertiesData?: ListPropertyModel[];
};

function HomeLuxurySlides(props: HomeLuxurySlidesType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const slideImageRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState<number>(0);

  function handleSlideImage() {
    if (slideImageRef.current) {
      const slideWidth = slideImageRef.current.offsetWidth;
      let slideItemWidth = slideWidth;

      if (slideWidth >= 1104) {
        slideItemWidth = slideWidth / 5;
      }
      if (slideWidth > 671 && slideWidth < 1104) {
        slideItemWidth = slideWidth / 3;
      }

      const calHeight = slideItemWidth / (5 / 4);
      setImageHeight(calHeight);
    }
  }
  const onNavigate = () => {
    AppModal.searchingPropertyModel = {} as SearchingPropertyType;
    navigate("/property", { state: { viewMore: "recently" } });
  };

  useLayoutEffect(() => {
    handleSlideImage();
    window.addEventListener("resize", handleSlideImage, false);
    return () => window.removeEventListener("resize", handleSlideImage, false);
  }, []);

  const onNavigateDetail = (
    transactionTypeId?: string | undefined,
    slug?: string
  ) => {
    if (transactionTypeId === "004" || transactionTypeId === "005") {
      navigate("/commercial/" + slug, { state: { index: 3 } });
    } else {
      navigate("/property/" + slug, { state: { id: transactionTypeId } });
    }
  };

  return (
    <div ref={slideImageRef} className="luxury-slides-container">
      {props.recentlyPropertiesData && (
        <MultiSlides
          responsive={{ desktop: 4, tabletL: 3, tabletM: 2, mobile: 1 }}
        >
          {props.recentlyPropertiesData &&
            props.recentlyPropertiesData.map((item, index) => (
              <div key={index} className="handle-hover">
                <div
                  className="h100"
                  onClick={() =>
                    onNavigateDetail(item.transactionTypeId, item.slug)
                  }
                  style={{ textDecoration: "none" }}
                  draggable={false}
                >
                  {/* <Link
                  to={"/property/" + item.id}
                  style={{ textDecoration: "none" }}
                  draggable={false}
                > */}
                  <Button className="luxury-slides-item">
                    <div className="image-item" style={{ height: imageHeight }}>
                      <img src={item.coverImageUrl} alt="" />
                    </div>
                    <div className="content-item">
                      <CustomText className="text-title fontMP semiBold mb12">
                        {item.title}
                      </CustomText>
                      <CustomText className="text-content fontMP mb12">
                        {currentLanguage.isEN
                          ? item.propertyTypeEn
                          : item.propertyTypeVi}
                      </CustomText>
                      <CustomText className="text-location fontSP mb12">
                        {/* {item.location} */}
                        {currentLanguage.isEN ? item.districtNameEn : item.districtName}, {currentLanguage.isEN ? item.provinceNameEn : item.provinceName}
                      </CustomText>
                      <CustomText className="fontMP semiBold mb12">
                        {getPrice(
                          item.usdPrice!,
                          item.currencyNotation!,
                          item.price!
                        )}
                        {(item.transactionTypeId === "001" || item.transactionTypeId === "004") && (
                          <span>/ {t("daily")}</span>
                        )}
                      </CustomText>
                      <div className="info-content">
                        <CustomText className="fontSP">
                          {formatLotSize(item.lotSizeFeet || 0)} ft2
                        </CustomText>
                        {(item.transactionTypeId !== "004" && item.transactionTypeId !== "005") && (
                          <CustomText className="fontSP">
                            {item.bedroomEn + " " + t("beds")}
                          </CustomText>
                        )}
                        {(item.transactionTypeId !== "004" && item.transactionTypeId !== "005") && (
                          <CustomText className="fontSP">
                            {item.bathroomEn + " " + t("baths")}
                          </CustomText>
                        )}
                      </div>
                    </div>
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            ))}
        </MultiSlides>
      )}
      {props.recentlyPropertiesData &&
        props.recentlyPropertiesData?.length > 4 && (
          <div className="borderNone">
            <Button
              onClick={() => onNavigate()}
              variant="outlined"
              className="view-more-btn"
            >
              {t("viewMore")}
            </Button>
          </div>
        )}
      {!props.recentlyPropertiesData && <CustomText>{t("noData")}</CustomText>}
    </div>
  );
}

export default memo(HomeLuxurySlides);
