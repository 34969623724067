import { useTranslation } from "react-i18next";
import CustomCard from "../../../components/custom-card";
import { CustomText } from "../../../components/styled-custom";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as Noti } from "../../../assets/icons/Notification.svg";
import StyledVariables from "../../../helpers/materials/_variables";
import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MyAccountRepositories from "./controllers/my-account-repositories";
import { basicHTML, currentLanguage } from "../../../helpers/utils";
import moment from "moment";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { AppContext } from "../../../context/app-context";
import { AppActionStates, appSetState } from "../../../context/states";
import DialogComponent from "../../../components/dialog-component";
import NotificationsIcon from "@mui/icons-material/Notifications";
function Notification(props: any) {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(AppContext);

  const [page, setPage] = React.useState(1);
  const [noti, setNoti] = useState<any>({});
  const [countNoti, setCountNoti] = useState<any>();
  const [seen, setSeenNoti] = useState<any>();
  const [invisible, setInvisible] = React.useState(false);
  const [openDialogDelete, setOpenDelete] = useState<boolean>(false);
  const [openDialogDeleteAll, setOpenDeleteAll] = useState<boolean>(false);
  const [selectedDelete, setSelectedDelete] = useState<any>();
  async function getNoti() {
    let val = {
      pageNumber: 1,
      pageSize: 100,
    };
    const res = await MyAccountRepositories.getNoti(val);
    setNoti(res);
  }
  async function getCountNoti() {
    const res = await MyAccountRepositories.getCountNoti(false);
    setCountNoti(res);
    state.count = res;
    dispatch(appSetState(AppActionStates.NOTI, state.count));
  }
  const unSeen = async (id: any) => {
    var ids = [];
    ids.push(id);
    const res = await MyAccountRepositories.seenNoti(ids);
    if (res.succeeded) {
      setSeenNoti(res);
    }
  };
  const deleteNoti = async (id: any) => {
    await MyAccountRepositories.removeNoti(id);
    unSeen(id);
    setOpenDelete(false);
  };

  const deleteAllNoti = async () => {
    const res = await MyAccountRepositories.removeAllNoti();
    if (res.succeeded) {
      getNoti();
      getCountNoti();
    }
  };

  useEffect(() => {
    getCountNoti();
    getNoti();
  }, [seen]);

  return (
    <div
      className={`${noti?.totaCount === 0 ? "height0" : ""} right noti w100`}
    >
      <CustomCard title={t("notification")}>
        <div className="dFlex justifyBetween pt40 pb28">
          <CustomText className="fontSemibold">
            {basicHTML(t("countNoti", { number: countNoti }))}
          </CustomText>

          <CustomText
            onClick={() => setOpenDeleteAll(true)}
            className="clear pointer"
          >
            {t("clearAll")}
          </CustomText>
        </div>
        <div
          className={`${
            noti?.totalCount !== 0 ? "h-noti-total pointer" : "pointer"
          }`}
        >
          {noti?.items?.map((item: any, index: number) => (
            <div
              className={
                "dFlex justifyBetween h-noti py12 border-t-noti py8 px8 " +
                (item.isSeen ? "bg-noti-unseen" : "bg-noti-seen")
              }
            >
              <a
                onClick={() => unSeen(item.id)}
                href={item.link}
                className="dFlex w100"
              >
                <div className="dFlex noti-bell flexCol justifyCenter dot-noti">
                  <Badge color="success" variant="dot" invisible={item.isSeen}>
                    <Button>
                      {/* <Noti className="" fill={StyledVariables.colors.red400} /> */}
                      <NotificationsIcon />
                    </Button>
                  </Badge>
                </div>

                <div className="pl24 w-200-mobile dFlex flexCol justifyCenter">
                  <CustomText className="semiBold">
                    {currentLanguage.isEN ? item.title : item.titleVi}
                  </CustomText>
                  <CustomText className="fontSP py8">
                    {currentLanguage.isEN ? item.content : item.contentVi}
                  </CustomText>
                  <CustomText className="gray fontSP">
                    {" "}
                    {moment(item.postedTime).format("DD/MM/YYYY")}
                  </CustomText>
                </div>
              </a>
              <div
                onClick={() => {
                  setSelectedDelete(item.id);
                  setOpenDelete(true);
                }}
                className="red flexCol justifyCenter dFlex "
              >
                <DeleteIcon />
              </div>
            </div>
          ))}
        </div>

        {noti.totalCount === 0 ? (
          <CustomText className="pb-212 bold">{t("noNoti")}</CustomText>
        ) : (
          ""
        )}
      </CustomCard>
      <DialogComponent
        open={openDialogDelete}
        onClose={() => setOpenDelete(false)}
        className="delete-dialog"
      >
        <div className="delete-content">
          <CustomText className="bold">{t("confirmDeleteNoti")}</CustomText>
          <div className="action-btn">
            <Button className="cancel" onClick={() => setOpenDelete(false)}>
              {t("cancel")}
            </Button>
            <Button
              className="confirm"
              onClick={() => deleteNoti(selectedDelete)}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </DialogComponent>
      <DialogComponent
        open={openDialogDeleteAll}
        onClose={() => setOpenDeleteAll(false)}
        className="delete-dialog"
      >
        <div className="delete-content">
          <CustomText className="bold">{t("confirmDeleteAllNoti")}</CustomText>
          <div className="action-btn">
            <Button className="cancel" onClick={() => setOpenDeleteAll(false)}>
              {t("cancel")}
            </Button>
            <Button
              className="confirm"
              onClick={() => {
                deleteAllNoti();
                setOpenDeleteAll(false);
              }}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </DialogComponent>
    </div>
  );
}

export default Notification;
