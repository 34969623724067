import { CreateUserSavedSearchModel } from "../../../apis/general-client";
import { generalClient } from "../../../helpers/utils";

const PropertyListRepositories = {
  getPropertyList: async (data: any) => {
    const resData = await generalClient.property_SearchAndSortProperty(data);
    return resData;
  },

  createSaveSearch: async (model: any) => {
    const res = await generalClient.userSavedSearch_CreateSavedSearch(model);
    return res;
  },

  viewCountProperty: async (propertyId: string) => {
    try {
      const res = await generalClient.property_ViewProperty(propertyId);
      return res;
    } catch (error) {
      return false;
    }
  },

  getCoordinateProvinceByCode: async (provinceCode: string)=>{
    const res = await generalClient.map_GetCoordinateProvinceByCode(provinceCode);
    return res;
  },

  getCoordinateDistrictByCode: async (districtCode: string)=>{
    const res = await generalClient.map_GetCoordinateDistrictByCode(districtCode);
    return res;
  },

  getCoordinateWardByCode: async (wardCode: string)=>{
    const res = await generalClient.map_GetCoordinateWardByCode(wardCode);
    return res;
  },
};

export default PropertyListRepositories;
