import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Container, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppRepositories } from "../../apis/app-repositories";
import {
  ListPropertyModel,
  PagingNearestPropertyModel,
} from "../../apis/general-client";
import { CustomText } from "../../components/styled-custom";
import StyledVariables from "../../helpers/materials/_variables";
import { recentlyProperty } from "../../helpers/utils";
import HomeLatestProperty from "./home-latest-property";
import HomeLuxurySlides from "./home-luxury-slides";
import HomeTrusted from "./home-trusted";
import LazyLoad from 'react-lazy-load';

function HomeSearchLuxury() {
  const { t } = useTranslation();
  const [defaultData, setDefaultData] = useState<string[]>([
    "popularCities",
    "latestProperties",
    "recentlyViewed",
  ]);
  const [tabValue, setTabValue] = useState<string>("1");
  const [searchTitle, setSearchTitle] = useState<string>(
    window.innerWidth <= 450 ? "homeSearchLuxury_Mobile" : "homeSearchLuxury"
  );
  const [expandTitle, setExpandTitle] = useState<string>(
    window.innerWidth <= 450 ? "expandLuxury_Mobile" : "expandLuxury"
  );

  const [latestPropertiesData, setLatestPropertiesData] =
    useState<ListPropertyModel[]>();
  const [recentlyProperties, setRecentlyProperties] =
    useState<ListPropertyModel[]>();
  const [readyToRender, setReadyToRender] = useState<boolean>(false);

  useEffect(() => {
    async function getLatestProperties() {
      let val = {
        pageNumber: 1,
        pageSize: 16,
      };
      const resData = await AppRepositories.getPropertyList(val);
      return resData.items;
    }

    async function getRecentlyProperties() {
      const recentlyPropertyIds = recentlyProperty.get();
      if (recentlyPropertyIds && recentlyPropertyIds.length > 0) {
        const model = {
          pageNumber: 1,
          pageSize: 16,
          ids: recentlyPropertyIds,
        } as PagingNearestPropertyModel;
        const resData = await AppRepositories.getPropertyRecently(model);
        setRecentlyProperties(resData.items);
        setDefaultData(["popularCities", "latestProperties", "recentlyViewed"]);
        return resData.items;
      }
      return;
    }

    Promise.all([getLatestProperties(), getRecentlyProperties()]).then((el) => {
      el[0] && setLatestPropertiesData(el[0]);
      if (el[1]) {
        setRecentlyProperties(el[1]);
        setDefaultData(["popularCities", "latestProperties", "recentlyViewed"]);
      }
      setReadyToRender(true);
    });
  }, []);

  window.addEventListener("resize", function (e) {
    var screenWidth = window.innerWidth;
    if (defaultData.length === 2) {
      const _searchTitle =
        tabValue === "1"
          ? screenWidth <= 450
            ? "homeSearchTrusted_Mobile"
            : "homeSearchTrusted"
          : screenWidth <= 450
          ? "homeSearchLuxury_Mobile"
          : "homeSearchLuxury";
      const _expandTitle =
        tabValue === "1"
          ? ""
          : screenWidth <= 450
          ? "expandLuxury_Mobile"
          : "expandLuxury";
      setSearchTitle(_searchTitle);
      setExpandTitle(_expandTitle);
    }
    if (defaultData.length === 3) {
      const _searchTitle =
        tabValue === "2"
          ? screenWidth <= 450
            ? "homeSearchTrusted_Mobile"
            : "homeSearchTrusted"
          : screenWidth <= 450
          ? "homeSearchLuxury_Mobile"
          : "homeSearchLuxury";
      const _expandTitle =
        tabValue === "2"
          ? ""
          : screenWidth <= 450
          ? "expandLuxury_Mobile"
          : "expandLuxury";
      setSearchTitle(_searchTitle);
      setExpandTitle(_expandTitle);
    }
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    var screenWidth = window.innerWidth;
    if (defaultData.length === 2) {
      const _searchTitle =
        newValue === "1"
          ? screenWidth <= 450
            ? "homeSearchTrusted_Mobile"
            : "homeSearchTrusted"
          : screenWidth <= 450
          ? "homeSearchLuxury_Mobile"
          : "homeSearchLuxury";
      const _expandTitle =
        newValue === "1"
          ? ""
          : screenWidth <= 450
          ? "expandLuxury_Mobile"
          : "expandLuxury";
      setTabValue(newValue);
      setSearchTitle(_searchTitle);
      setExpandTitle(_expandTitle);
    }
    if (defaultData.length === 3) {
      const _searchTitle =
        newValue === "2"
          ? screenWidth <= 450
            ? "homeSearchTrusted_Mobile"
            : "homeSearchTrusted"
          : screenWidth <= 450
          ? "homeSearchLuxury_Mobile"
          : "homeSearchLuxury";
      const _expandTitle =
        newValue === "2"
          ? ""
          : screenWidth <= 450
          ? "expandLuxury_Mobile"
          : "expandLuxury";
      setTabValue(newValue);
      setSearchTitle(_searchTitle);
      setExpandTitle(_expandTitle);
    }
  };

  return (
    <div className="home-search-luxury">
      <Container>
        <div
          className="label-top"
          dangerouslySetInnerHTML={{ __html: t(searchTitle) }}
        ></div>
        <div
          className="label-expand"
          dangerouslySetInnerHTML={{ __html: t(expandTitle) }}
        ></div>
        <div className="tab-group">
          {readyToRender && (
            <TabContext value={tabValue}>
              <div className="tab-label">
                <TabList
                  onChange={handleTabChange}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: StyledVariables.colors.greenLight,
                    },
                  }}
                >
                  {defaultData.map((item, index) => (
                    <Tab
                      wrapped
                      key={index}
                      label={t(item)}
                      value={index.toString()}
                    />
                  ))}
                </TabList>
              </div>
              {defaultData.map((item, index) => {
                return (
                  <TabPanel key={index} value={index.toString()}>
                    {index === 0 && <HomeTrusted />}
                    {index === 1 && (
                      <HomeLatestProperty
                        latestPropertiesData={latestPropertiesData}
                      />
                    )}
                    {index === 2 && (
                      <HomeLuxurySlides
                        recentlyPropertiesData={recentlyProperties}
                      />
                    )}
                  </TabPanel>
                );
              })}
            </TabContext>
          )}
        </div>
      </Container>
    </div>
  );
}

export default HomeSearchLuxury;
