import { Close } from "@mui/icons-material";
import { Backdrop, Fade, Modal } from "@mui/material";

type DialogComponentType = {
  open: boolean;
  onClose: () => void;
  children: any;
  className?: string;
  containerClassName?: string;
  backgroundImage?: string;
  closeButtonColor?: string;
};

function DialogComponent(props: DialogComponentType) {
  return (
    <Modal
      open={props.open}
      onClose={(_, reason) => reason !== "backdropClick" && props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div
          className={`dialog-component ${props.containerClassName}`}
          style={
            props.backgroundImage
              ? {
                  backgroundImage: `url(${props.backgroundImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }
              : {}
          }
        >
          <div
            className="close-icon"
            style={{ borderColor: props.closeButtonColor }}
            onClick={props.onClose}
          >
            <Close style={{ color: props.closeButtonColor }} />
          </div>
          <div className={props.className}>{props.children}</div>
        </div>
      </Fade>
    </Modal>
  );
}

export default DialogComponent;
