import { ProjectStatus } from "../../../apis/general-client";

type SearchProjectModel = {
  projectName: string;
  location: string;
  propertyTypeId: string;
  status?: ProjectStatus;
};

class ProjectModal {
  static searchingProjectModel: SearchProjectModel =
    {} as SearchProjectModel;
}

export default ProjectModal;
export type { SearchProjectModel };
