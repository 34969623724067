import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCard from "../../components/custom-card";
import CustomSelect, { SelectItemType } from "../../components/custom-select";
import { CustomText } from "../../components/styled-custom";
import ImageUpload from "../../assets/icons/img-upload.png";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Close } from "@mui/icons-material";
import MasterData from "../../apis/master-data";
import { notRequiredField, sellHomeModal } from "./controllers/sell-home-modal";
import { useLocation } from "react-router-dom";

function SellPropertySummary(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const local = location?.state as any;

  const item = local?.item;
  // initExistedSellHomeModal(item);
  const data: SelectItemType[] = useMemo(
    () => [
      {
        label: t("propertyType") + " *",
        placeholder: "choosePropertyType",
        name: "propertyType",
        data: [
          {
            id: "default",
            propertyTypeVi: t("choosePropertyType"),
            propertyTypeEn: t("choosePropertyType"),
          },
          ...MasterData.properties,
        ],
      },
      {
        label: t("transactionType") + " *",
        name: "transactionType",
        placeholder: "chooseTransactionTypee",
        data: [
          {
            id: "default",
            transactionTypeVi: t("chooseTransactionType"),
            transactionTypeEn: t("chooseTransactionType"),
          },
          ...MasterData.transaction,
        ],
      },
      {
        label: t("photos"),
        name: "photos",
        placeholder: "",
        data: [],
      },
      {
        label: t("addVideoLink"),
        name: "addVideoLink",
        placeholder: "addVideoLinkEx",
        data: [{}],
      },
      {
        label: t("addVirtualVideoLink"),
        name: "addVirtualVideoLink",
        placeholder: "addVirtualVideoLinkEx",
        data: [],
      },
    ],
    [t]
  );

  const [photoList, setPhotoList] = useState<string[]>(() => {
    if (sellHomeModal?.propertyImages) {
      const _photoList = [];
      let photos = sellHomeModal?.propertyImages;
      for (let i = 0; i < photos.length; i++) {
        const fileUrl = photos[i].imagesUrl;
        _photoList.push(fileUrl);
      }
      return _photoList;
    } else {
      return [];
    }
  });

  const [avatar, setAvatar] = useState<string>(() => {
    props.updateHasAvtar(sellHomeModal?.coverImageUrl ? true : false);
    return sellHomeModal?.coverImageUrl ? sellHomeModal?.coverImageUrl : "";
  });

  const [value, setValue] = useState<any>({
    // propertyType: "default",
    // transactionType: "default",
    // addVideoLink: "",
    // addVirtualVideoLink: "",

    propertyType:
      sellHomeModal?.propertyTypeId != null
        ? sellHomeModal?.propertyTypeId
        : "default",
    transactionType:
      sellHomeModal?.transactionTypeId != null
        ? sellHomeModal?.transactionTypeId
        : "default",
    addVideoLink:
      sellHomeModal?.videoLink != null ? sellHomeModal?.videoLink : "",
    addVirtualVideoLink:
      sellHomeModal?.virtualVideoLink != null
        ? sellHomeModal?.virtualVideoLink
        : "",
  });

  const getUploadAvatar = (file: ChangeEvent<HTMLInputElement>) => {
    const fileInput = file.target.files;
    if (fileInput && fileInput[0]) {
      const fileUrl = URL.createObjectURL(fileInput[0]);
      setAvatar(fileUrl);
      // if (sellHomeModal?.propertyImages) {
      //   sellHomeModal.coverImageUrl = fileInput[0];
      // }
      sellHomeModal.avatarFile = fileInput[0];
      props.updateHasAvtar(true);
    }

    file.target.value = "";
    props.required["avatarFile"] && props.validator("avatarFile");
  };

  async function getAvatar() {
    if (sellHomeModal?.coverImageUrl) {
      const avrtar = sellHomeModal?.coverImageUrl;
      setAvatar(avrtar);
    } else {
      const avrtar = sellHomeModal?.avatarFile;
      setAvatar(avrtar);
    }
  }

  const getUploadPhoto = (file: ChangeEvent<HTMLInputElement>) => {
    const _photoList = JSON.parse(JSON.stringify(photoList));
    const fileInput = file.target.files;
    if (fileInput && fileInput[0]) {
      for (let i = 0; i < fileInput.length; i++) {
        const fileUrl = URL.createObjectURL(fileInput[i]);
        _photoList.push(fileUrl);
        sellHomeModal?.photoFileList.push(fileInput[i]);
      }
      setPhotoList(_photoList);
    }
    file.target.value = "";
  };

  async function getPhotos() {
    const _photoList = JSON.parse(JSON.stringify(photoList));
    if (sellHomeModal?.propertyImages) {
      let photos = sellHomeModal?.propertyImages;
      for (let i = 0; i < photos.length; i++) {
        const fileUrl = photos[i].imagesUrl;
        _photoList.push(fileUrl);
      }
      setPhotoList(_photoList);
    }
  }

  const onRemoveAvatar = () => {
    avatar && URL.revokeObjectURL(avatar);
    setAvatar("");
    sellHomeModal.avatar = "";
    sellHomeModal.avatarFile = null;
    props.updateHasAvtar(false);
  };

  const onRemovePhoto = (index: number) => {
    let sellHomeModal2 = sellHomeModal;
    const _photoList = JSON.parse(JSON.stringify(photoList));
    URL.revokeObjectURL(_photoList[index]);
    _photoList.splice(index, 1);

    sellHomeModal?.photoFileList.splice(index, 1);
    setPhotoList(_photoList);
    sellHomeModal.propertyImages.splice(index, 1);
    sellHomeModal.photoList = _photoList;
  };

  const getValue = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    value[targetName] = targetValue;

    if (targetName === "transactionType") {
      props.onTransactionChange(targetValue);
    }

    sellHomeModal[targetName] = targetValue === "default" ? "" : targetValue;
    setValue({ ...value });
    props.required[targetName] && props.validator(targetName);
  };

  useEffect(() => {
    setValue(value);
    if (value.transactionType === "004" || value.transactionType === "004") {
      props.onTransactionChange(value.transactionType);
    }
  }, [local?.item]);

  return (
    <CustomCard className="sell-property-summary" title={t("propertySummary")}>
      <Grid container spacing={2}>
        {data &&
          data.map(
            (item, index) =>
              index < 2 && (
                <Grid key={index} item md={6} xs={12}>
                  <CustomSelect
                    error={props.required[item.name]}
                    name={item.name}
                    value={value[item.name]}
                    label={item.label}
                    data={item.data}
                    onChange={getValue}
                  />
                </Grid>
              )
          )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <div className="photo-upload-container">
            <CustomText className="fontMP">{t(data[2].label)}</CustomText>
            <div
              className={`text-avatar ${
                props.required.avatarFile ? "error" : ""
              }`}
            >
              <div className="avatar-upload">
                <CustomText className="fontSP mr24 des-title">
                  {t("uploadAvatarProperty") + " *"}
                </CustomText>
                <div
                  className="add-image"
                  style={avatar ? { border: "none" } : {}}
                >
                  <img
                    className={avatar ? "" : "upload-icon"}
                    src={avatar || ImageUpload}
                    alt=""
                  />
                  <input
                    onChange={getUploadAvatar}
                    type="file"
                    accept="image/*"
                  />
                  {avatar && (
                    <div className="clear-icon" onClick={onRemoveAvatar}>
                      <Close />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`photo-upload ${
                props.required.photoFileList ? "error" : ""
              }`}>
              <CustomText className="fontSP mt12 des-title">
                {t("uploadPhotoProperty")}
              </CustomText>
              <div className="photo-upload-list">
                {photoList.length > 0 &&
                  photoList.map((item, index) => (
                    <div key={index} className="photo-list-item">
                      <img src={item} alt="" />
                      <div
                        className="clear-icon"
                        onClick={() => onRemovePhoto(index)}
                      >
                        <Close />
                      </div>
                    </div>
                  ))}
                <div className="add-image">
                  <img className="upload-icon" src={ImageUpload} alt="" />
                  <input
                    onChange={getUploadPhoto}
                    type="file"
                    accept="image/*"
                    multiple
                  />
                </div>
              </div>
              <CustomText className="fontSP mt12">
                ({t("minium4Images")})
              </CustomText>
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          {data.map(
            (item, index) =>
              index > 2 && (
                <CustomSelect
                  error={
                    !notRequiredField.some((e) => e === item.name) &&
                    props.required[item.name]
                  }
                  name={item.name}
                  key={index}
                  isInput
                  value={value[item.name]}
                  label={t(item.label)}
                  placeholder={item.placeholder && t(item.placeholder)}
                  onChange={getValue}
                />
              )
          )}
        </Grid>
      </Grid>
    </CustomCard>
  );
}

export default SellPropertySummary;
