import { Avatar, Button, Container, Rating } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import MultiSlides from "../../components/multi-slides";
import { CustomText } from "../../components/styled-custom";
import LazyLoad from 'react-lazy-load';

type ItemType = {
  imageUrl: string;
  name: string;
  username: string;
  star: number;
  details: string;
};

function HomeClients() {
  const { t } = useTranslation();
  const dataList: ItemType[] = [
    {
      imageUrl:
        "https://media-cdn-v2.laodong.vn/Storage/NewsPortal/2021/8/4/938115/Justin-Bieber-Dung-D.jpg",
      name: "JESSICA – Canada",
      username: "",
      star: 5,
      details: "VietnamHomes Luxury Brokerage gave me all the information I needed to invest in this beautiful country.  They negotiated an excellent price that I bought 2 condos.",
    },
    {
      imageUrl:
        "https://media-cdn-v2.laodong.vn/Storage/NewsPortal/2021/8/4/938115/Justin-Bieber-Dung-D.jpg",
      name: "KEVIN PHAN – USA",
      username: "",
      star: 5,
      details: "Vietnam Homes Luxury Brokerage provided the best representation I have ever had even when I purchased a home in my state of New York City.",
    },
    {
      imageUrl:
        "https://media-cdn-v2.laodong.vn/Storage/NewsPortal/2021/8/4/938115/Justin-Bieber-Dung-D.jpg",
      name: "MATTHEW SMITH – UK",
      username: "",
      star: 5,
      details: "Vietnam Homes Luxury Brokerage showed me how simple it was to buy Ocean view condos for under $125K and showed me the potential I would make in renting it out.  How could I not buy multiple properties.  Thanks Vietnam Homes Team.",
    },
    {
      imageUrl:
        "https://media-cdn-v2.laodong.vn/Storage/NewsPortal/2021/8/4/938115/Justin-Bieber-Dung-D.jpg",
      name: "CHOON HEE – Korea",
      username: "",
      star: 5,
      details: "We had Vietnam Homes Luxury Brokerage find us a 5-star condo to rent and after a few months, we asked them to help us buy it because we loved it so much.  Thank you everyone for making us feel like family.",
    },
    {
      imageUrl:
        "https://media-cdn-v2.laodong.vn/Storage/NewsPortal/2021/8/4/938115/Justin-Bieber-Dung-D.jpg",
      name: "JONES - Australia",
      username: "",
      star: 5,
      details: "We picked Vietnam to work remotely and with the exceptional help from Vietnam Homes Luxury Brokerage, we found a brand-new ocean condo with 5 star amenities at a ridiculously low price.",
    },
    {
      imageUrl:
        "https://media-cdn-v2.laodong.vn/Storage/NewsPortal/2021/8/4/938115/Justin-Bieber-Dung-D.jpg",
      name: "WILLIAMS - USA",
      username: "",
      star: 5,
      details: "Vietnam Homes is a legitimate 5-star brokerage.  They have a team of knowledgeable agents that can speak over 20 languages.  Thank you Vietnam Homes for taking care of me through the entire buying process.  Love my view of the OCEAN!!!!",
    }
  ];

  return (
    <div className="home-clients">
      <Container>
        <CustomText className="title">{t("clientsTestimonials")}</CustomText>
      <LazyLoad offset={1}>
        <MultiSlides showNavigation={true}
          responsive={{ desktop: 3, tabletL: 3, tabletM: 1, mobile: 1 }}
          showDot
        >
          {dataList.map((item, index) => (
            <Button disabled key={index} className="card-item">
              {/* <Avatar src={item.imageUrl} /> */}
              <CustomText className="fontMP mb12 mt12 bold">
                {item.name}
              </CustomText>
              <CustomText className="mb12">{item.username}</CustomText>
              <Rating
                size="small"
                defaultValue={item.star}
                precision={0.5}
                readOnly
              />
              <CustomText className="mt12">{item.details}</CustomText>
            </Button>
          ))}
        </MultiSlides>
      </LazyLoad>
      </Container>
    </div>
  );
}

export default memo(HomeClients);
