import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRepositories } from "../../../apis/app-repositories";
import { CreatePropertyHeartModel } from "../../../apis/general-client";
import CustomCard from "../../../components/custom-card";
import { CustomText } from "../../../components/styled-custom";
import {
  basicHTML,
  currentLanguage,
  formatLotSize,
  formatNumber,
  getPrice,
  getToken,
  getUser,
} from "../../../helpers/utils";
import MyAccountRepositories from "./controllers/my-account-repositories";
import { ReactComponent as HeartActive } from "../../../assets/icons/Heart.svg";
import StyledVariables from "../../../helpers/materials/_variables";
import { ReactComponent as Heart } from "../../../assets/icons/heart-2.svg";
import CurrencyFormat from "react-currency-format";
import Bedroom from "../../../assets/icons/bedroom.svg";
import Bathroom from "../../../assets/icons/bathroom.svg";
import { AppContext } from "../../../context/app-context";
import { AppActionStates, appSetState } from "../../../context/states";
function MyFavourite(props: any) {
  let location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = getUser();
  const [data, setData] = useState<any>({});
  const [countNoti, setCountNoti] = useState<any>();
  const { state, dispatch } = useContext(AppContext);

  const [sortModelState, setSortModelState] = useState<{
    newest: Boolean;
    oldest: Boolean;
    lowestPrice: Boolean;
    highestPrice: Boolean;
    oldFavourite: Boolean;
    newFavourite: Boolean;
  }>({
    newest: true,
    oldest: false,
    lowestPrice: false,
    highestPrice: false,
    oldFavourite: false,
    newFavourite: false,
  });
  const [page, setPage] = React.useState(1);
  async function getData() {
    let val = {
      userId: user.id,
      pageNumber: page,
      pageSize: 4,
      sortingModel: sortModelState,
    };

    var resData = await MyAccountRepositories.getFavourite(val);

    if (resData.items) {
      resData["items"].forEach((item: any) => {
        item.isHeart = true;
      });
    }
    setData(resData);
  }
  const onNavigate = (item: any) => {
    if (item.transactionTypeId === "004" || item.transactionTypeId === "005") {
      navigate("/commercial/" + item.slug, { state: { index: 3 } });
    } else {
      navigate("/property/" + item.slug);
    }
  };
  const sendPropertyHeart = async (item: any, index: number) => {
    const token = getToken() != null;
    if (token) {
      const user = getUser();
      const model = {
        propertyId: item.propertyId,
        userId: user.id,
      } as CreatePropertyHeartModel;
      await AppRepositories.sendPropertyHeart(model);
      getData();
    }
  };
  const onFilter = async (sort: number) => {
    const sortModelState = {
      newest: sort === 1,
      oldest: sort === 2,
      lowestPrice: sort === 3,
      highestPrice: sort === 4,
      oldFavourite: sort === 5,
      newFavourite: sort === 6,
    };
    setSortModelState(sortModelState);
  };

  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    window.scrollTo(0, 0);
  };
  async function getCountNoti() {
    const res = await MyAccountRepositories.getCountNoti(false);
    setCountNoti(res);
    state.count = res;
    dispatch(appSetState(AppActionStates.NOTI, state.count));
  }

  useEffect(() => {
    getCountNoti();

    getData();
  }, [sortModelState, page]);
  async function getIsPropertyHeart() {
    const token = getToken() != null;
    if (token) {
      const user = getUser();
      if (data.items && data.items.length > 0) {
        const propertyIds = data.items.map((x: any) => x.id);
        const res = await AppRepositories.isManyPropertyHeart(
          user.id,
          propertyIds
        );
        res.forEach((x, index) => {
          data.items[index]["isHeart"] = x;
        });
      }
    }
  }

  return (
    <div className="right my-favourite w100">
      <CustomCard title={t("myFavourites")}>
        <div className="contentCount header-property-item pt20">
          <CustomText>
            {basicHTML(t("resultTotalListings", { number: data.totalCount }))}
          </CustomText>
        </div>
        <div className="content-sort header-property-item pt8">
          <CustomText>{t("sortBy")} </CustomText>
          <Select
            className="formSelect "
            id="demo-simple-select-standard"
            onChange={(e: any) => onFilter(e.target.value)}
            defaultValue={1}
          >
            <MenuItem value={1}>
              <CustomText>{t("newest")}</CustomText>
            </MenuItem>
            <MenuItem value={2}>
              <CustomText>{t("oldest")}</CustomText>
            </MenuItem>
            <MenuItem value={3}>
              <CustomText>{t("lowestPrice")}</CustomText>
            </MenuItem>
            <MenuItem value={4}>
              <CustomText>{t("highestPrice")}</CustomText>
            </MenuItem>
            <MenuItem value={5}>
              <CustomText>{t("oldFavourite")}</CustomText>
            </MenuItem>
            <MenuItem value={6}>
              <CustomText>{t("newFavourite")}</CustomText>
            </MenuItem>
          </Select>
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 4, md: 4 }}>
          {data.items?.map((item: any, index: number) => (
            <Grid item xs={12} lg={6} key={index}>
              <div className="borderNone">
                <Card className="cardProperty property-item">
                  <CardMedia
                    component="img"
                    height="237"
                    image={item.coverImageUrl}
                    alt="Image"
                    onClick={() => onNavigate(item)}
                  />
                  <CardContent>
                    <div className="titleProperty">
                      <span
                        onClick={() => onNavigate(item)}
                        className="txtProperty"
                      >
                        {item.title}
                      </span>
                      <div>
                        <Button
                          onClick={() => {
                            sendPropertyHeart(item, index);
                          }}
                        >
                          {item["isHeart"] ? (
                            <HeartActive
                              className="iconHear-1"
                              fill={StyledVariables.colors.red400}
                            />
                          ) : (
                            <Heart
                              className="iconHear-1"
                              fill={StyledVariables.colors.red400}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                    <div onClick={() => onNavigate(item)} className="txtType">
                      {currentLanguage.isEN
                        ? item.propertyTypeEn
                        : item.propertyTypeVi}
                    </div>
                    <div
                      onClick={() => onNavigate(item)}
                      className="txtAddress"
                    >
                      {/* {currentLanguage.isEN
                        ? item.propertyAddressEn
                        : item.propertyAddressVi} */}
                      {item.propertyAddressVi}
                    </div>
                    <div onClick={() => onNavigate(item)} className="txtPrice">
                      <span>
                        {getPrice(
                          item.usdPrice!,
                          item.currencyNotation!,
                          item.price!
                        )}
                      </span>
                    </div>
                    <div
                      onClick={() => onNavigate(item)}
                      className="txtQuantity"
                    >
                      <span className="txtLot">{formatLotSize(item.lotSizeFeet || 0)} ft2 </span>
                      {props.index !== 3 && item.bedroomEn != null && (
                        <span className="txt-bed">
                          {item.bedroomEn}
                          <span>
                            <img
                              className="iconBed ml8"
                              src={Bedroom}
                              alt="bedroom"
                            />
                          </span>
                        </span>
                      )}
                      {props.index !== 3 && item.bathroomEn != null && (
                        <span className="txtBath">
                          {item.bathroomEn}
                          <span>
                            <img
                              className="iconBath"
                              src={Bathroom}
                              alt="bathroom"
                            />
                          </span>
                        </span>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          ))}
        </Grid>
        <div className="btnPagination">
          <Stack spacing={2}>
            <Pagination
              showFirstButton
              showLastButton
              count={data.totalPages}
              page={page}
              onChange={handleChange}
            />
          </Stack>
        </div>
      </CustomCard>
    </div>
  );
}

export default MyFavourite;
