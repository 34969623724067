import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomCard from "../../../components/custom-card";
import ImageUpload from "../../../assets/icons/img-upload.png";
import validator from "validator";
import { CustomText } from "../../../components/styled-custom";
import CustomSelect, {
  SelectItemType,
} from "../../../components/custom-select";
import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { appLoading, getUser } from "../../../helpers/utils";
import { sellHomeModal } from "../../sell-home/controllers/sell-home-modal";
import { Close } from "@mui/icons-material";
import MyAccountRepositories from "./controllers/my-account-repositories";
import { accountModel } from "./controllers/my-account-model";
import { AppContext } from "../../../context/app-context";
import { AppActionStates, appSetState } from "../../../context/states";

function AccountInformation(props: any) {
  const { state, dispatch } = useContext(AppContext);
  const { t } = useTranslation();
  const user = getUser();
  const [avatar, setAvatar] = useState<string>(
    user?.personalInformation?.avatarUrl ?? ""
  );
  const [open, setOpenNoti] = useState(false);
  const [disable, setDisable] = useState<boolean>(false);

  const [required, setRequired] = useState<any>({
    firstName: false,
    lastName: false,
  });
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };
  const getUploadAvatar = (file: ChangeEvent<HTMLInputElement>) => {
    const fileInput = file.target.files;
    if (fileInput && fileInput[0]) {
      const fileUrl = URL.createObjectURL(fileInput[0]);
      setAvatar(fileUrl);
      accountModel.avatarFile = fileInput[0];
    }
    file.target.value = "";
    // props.required["avatarFile"] && props.validator("avatarFile");
  };

  const data: any[] = useMemo(
    () => [
      {
        label: t("firstName") + " *",
        name: "firstName",
        data: "",
        required: true,
        helperText: "requiredField",
      },
      {
        label: t("lastName") + " *",
        name: "lastName",
        data: "",
        required: true,
        helperText: "requiredField",
      },
      {
        label: t("displayName"),
        name: "displayName",
        data: "",
        disabled: true,
      },
      {
        label: t("email"),
        name: "email",
        data: "",
        disabled: true,
      },

      {
        label: t("phoneNumber"),
        name: "phoneNumber",
        data: "",
        helperText: "phoneNumber",
      },
    ],
    []
  );

  const onRemoveAvatar = () => {
    avatar && URL.revokeObjectURL(avatar);
    setAvatar("");
    accountModel.avatarFilePath = "";
    accountModel.avatarFile = null;
  };
  const [inputValue, setInputValue] = useState<any>({
    firstName: user.personalInformation.firstName,
    lastName: user.personalInformation.lastName,
    displayName:
      user.personalInformation.firstName +
      " " +
      user.personalInformation.lastName,
    phoneNumber: user.personalInformation.phoneNumber1,
    email: user.email,
  });
  useEffect(() => {}, []);
  const onSubmit = async () => {
    appLoading.show();

    accountModel.lastName = inputValue["lastName"];
    accountModel.firstName = inputValue["firstName"];
    accountModel.phoneNumber1 = inputValue["phoneNumber"];
    accountModel.email = inputValue["email"];
    accountModel.avatarFilePath =
      avatar != null && avatar != "" ? user?.personalInformation.avatar : "";
    const res = await MyAccountRepositories.updateUser();
    if (res) {
      setOpenNoti(true);
      var userModify = getUser();
      userModify.personalInformation.displayName = `${userModify?.personalInformation?.firstName} ${userModify?.personalInformation?.lastName}`;
      setInputValue(userModify.personalInformation);
      state.userInfo = userModify.personalInformation;
      dispatch(appSetState(AppActionStates.EDIT_USER, state.userInfo));
      // const isRemember = localStorage.getItem("rememberMe") === "true";
      // if (isRemember) {
      //   localStorage.setItem("user", JSON.stringify(userModify));
      // } else {
      //   sessionStorage.setItem("user", JSON.stringify(userModify));
      // }
      // window.location.reload();
      appLoading.dismiss();

      window.scrollTo(0, 0);
    }
    window.scrollTo(0, 0);
  };

  const validateField = (fieldName: string, value: string) => {
    if (fieldName === "phoneNumber" && value !== "") {
      const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
      if (regex.test(value)) {
        required["phoneNumber"] = false;
        setDisable(false)
      } else {
        required["phoneNumber"] = true;
        setDisable(true)
      }
      setRequired({ ...required });
    } else if (fieldName === "phoneNumber" && value === "") {
      required["phoneNumber"] = false;
      setRequired({ ...required });
      setDisable(false);
    }
    else {
      required[fieldName] = value === "" ? true : false;
      setRequired({ ...required });
      if (required[fieldName] == true) {
        setDisable(true);
      } else setDisable(false);
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    inputValue[e.target.name] = e.target.value;
    setInputValue({ ...inputValue });
    validateField(e.target.name, e.target.value);
  };
  return (
    <form className="right w100">
      <CustomCard title={t("accountInfomation")}>
        <div className="pb68 mobile">
          <Grid container spacing={4}>
            <Grid item md={6} xs={12}>
              <div className="dFlex image pt48 pb40">
                <div>
                  <div className="avatar-upload">
                    <div
                      className="add-image"
                      style={avatar ? { border: "none" } : {}}
                    >
                      <img
                        className={`w100 h100 objectCover ${
                          avatar ? "" : "upload-icon"
                        }`}
                        src={avatar || ImageUpload}
                        alt=""
                      />
                      <input
                        onChange={getUploadAvatar}
                        type="file"
                        accept="image/*"
                      />
                      {avatar && (
                        <div className="clear-icon" onClick={onRemoveAvatar}>
                          <Close />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="italic pt128 text-limit">
                  <CustomText>{t("maximumFileSize1MB")}</CustomText>
                  <CustomText>{t("formatJPEG,PNG")}</CustomText>
                </div>
              </div>
            </Grid>
            <Grid item md={6} xs={12}>
              <div className="dFlex flexCol justifyEnd h100 h-mobile">
                {data.map(
                  (item, index) =>
                    index == 0 && (
                      <CustomSelect
                        isInput
                        disabled={item.disabled}
                        key={index}
                        error={required[item.name]}
                        required={item?.required}
                        label={item.label}
                        name={item.name}
                        value={inputValue[item.name]}
                        onChange={handleInput}
                        helperText={t(item.helperText)}
                        type={item.type}
                      ></CustomSelect>
                    )
                )}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            {data.map(
              (item, index) =>
                0 < index &&
                index < 3 && (
                  <Grid item md={6} xs={12}>
                    <div className="dFlex flexCol justifyStart h100 h-mobile">
                      <CustomSelect
                        isInput
                        disabled={item.disabled}
                        key={index}
                        error={required[item.name]}
                        required={item?.required}
                        label={item.label}
                        name={item.name}
                        value={inputValue[item.name]}
                        onChange={handleInput}
                        helperText={t(item.helperText)}
                        type={item.type}
                      ></CustomSelect>
                    </div>
                  </Grid>
                )
            )}
          </Grid>
          <Grid container spacing={4}>
            {data.map(
              (item, index) =>
                2 < index && (
                  <Grid item md={6} xs={12}>
                    <div className="dFlex flexCol justifyStart h100 h-mobile">
                      <CustomSelect
                        isInput
                        disabled={item.disabled}
                        key={index}
                        error={required[item.name]}
                        required={item?.required}
                        label={item.label}
                        name={item.name}
                        value={inputValue[item.name]}
                        onChange={handleInput}
                        helperText={t(item.helperText)}
                        type={item.type}
                      ></CustomSelect>
                    </div>
                  </Grid>
                )
            )}
          </Grid>
        </div>
        <div className="button-group pb48">
          <Button className="save-submit" disabled={disable} onClick={onSubmit}>
            {t("save")}
          </Button>
        </div>
      </CustomCard>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {t("updateAccountInfoSuccess")}
        </Alert>
      </Snackbar>
    </form>
  );
}
export default AccountInformation;
function Reducer(Reducer: any, initialState: any): [any, any] {
  throw new Error("Function not implemented.");
}
