import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import { useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import { appLoading, currentLanguage, getSiteDomain } from "../../helpers/utils";
import NewsCategoryScreen from "./news-category";
import NewsRepositories from "./news-controllers/news-list-repositories";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTranslation } from "react-i18next";
import NewsSearch from "./news-search";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { NewsModel } from "../../apis/general-client";
import LazyLoad from "react-lazy-load";

function NewsScreen(props: any) {
  const { t } = useTranslation();
  const [masterData, setMasterData] = useState<any>({});
  const [resDataNewsCaterory, setNewsCategory] = useState<any[]>();
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  const [isload, setLoad] = React.useState<boolean>(false);
  const domain = getSiteDomain();

  async function getDatas() {
    // appLoading.show();
    let val = {
      pageNumber: page,
      pageSize: 12,
    };
    const resData = await NewsRepositories.getNewsList(val);
    setLoad(true);
    setMasterData(resData);
    appLoading.dismiss();
  }

  async function getDataNewsCategory() {
    const resDataNewsCaterory = await NewsRepositories.getNewsCategory();
    setLoad(true);
    setNewsCategory(resDataNewsCaterory);
  }

  useEffect(() => {
    getDatas();
    getDataNewsCategory();
  }, []);

  const onSearch = () => {
    getDatas();
  };

  const onNavigate = (item: any) => {
    navigate("/news/" + item.slug);
    window.scrollTo(0, 0);
  };

  return (
    <div className="w100">
      <HeaderBar></HeaderBar>
      <Container>
        <div className="header-project-list">
          <CustomText className="txt-hotnews">{t("hotNews")}</CustomText>
          <div style={{ width: 666 }}>
            <NewsSearch isMappage onSearch={onSearch} />
          </div>
        </div>
        <hr className="mb20 mt28 line-news" />
        <div>
          {masterData?.items && (
            <div className="container-new">
              <div className="estateContent">
                <div className="gridProperty">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 4, md: 4 }}
                  >
                    {masterData?.items.map(
                      (item: any, index: number) =>
                        index === 0 && (
                          <Grid item xs={12} sm={12} lg={12} key={index}>
                            <div className="border-new w100">
                              <div className="card-new-0">
                                <CardMedia
                                  className="img-new-0"
                                  component="img"
                                  height="600"
                                  image={item.imagePathUrl}
                                  alt="Image"
                                  onClick={() => onNavigate(item)}
                                />
                                <div className="w100 card-content mt20">
                                  <div
                                    className="date-listnew-index0 mr40 mb12"
                                    onClick={() => onNavigate(item)}
                                  >
                                    <div className="title-news">
                                      <CustomText>
                                        {currentLanguage.isEN
                                          ? item.titleEn
                                          : item.titleVi}
                                      </CustomText>
                                    </div>
                                    <div className="date-views">
                                      <CustomText className="mr12">
                                        {moment(item.approveDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </CustomText>
                                      <CustomText >
                                        {item.viewCount} {t("views")}
                                      </CustomText>
                                    </div>
                                  </div>
                                  <div
                                    className="txt-new-0"
                                    onClick={() => onNavigate(item)}
                                  >
                                    {currentLanguage.isEN
                                      ? item.descriptionsEn
                                      : item.descriptionsVi}
                                  </div>
                                  {/* <div>{item.id}</div> */}
                                  <div className="date-new">
                                    <div className="share-new">
                                      <CustomText className="txt-share">
                                      {t("share")}
                                      </CustomText>
                                      <div className="share">
                                        <FacebookShareButton
                                          url={
                                            domain + "/news/" +
                                            item.slug
                                          }
                                          className="Demo__some-network__share-button"
                                        >
                                          <Facebook />
                                        </FacebookShareButton>

                                        <TwitterShareButton
                                          // title={"test"}
                                          url={
                                            domain + "/news/" +
                                            item.slug
                                          }
                                          hashtags={["vietnamhome"]}
                                        >
                                          <Twitter />
                                        </TwitterShareButton>
                                      </div>
                                    </div>

                                    <div
                                      className="txt-detail"
                                      onClick={() => onNavigate(item)}
                                    >
                                      <CustomText className="to-detail">
                                        {t("detail")}
                                      </CustomText>
                                      <ArrowForwardIcon className="icon-detail" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        )
                    )}
                  </Grid>
                </div>

                <div className="mapContent">
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 4, md: 4 }}
                  >
                    {masterData?.items.map(
                      (item: any, index: number) =>
                        index !== 0 &&
                        index < 5 && (
                          <Grid item xs={12} sm={12} lg={12} key={index}>
                            <div
                              className="border-new pointer w100"
                              onClick={() => onNavigate(item)}
                            >
                              <div className="cardProperty">
                                <CardMedia
                                  className="imgMap"
                                  component="img"
                                  height="160"
                                  image={item.imagePathUrl}
                                  alt="Image"
                                />
                                <CardContent className="w100">
                                  <div className="title-news">
                                    <div className="txtPriceMap">
                                      <CustomText>
                                        {currentLanguage.isEN
                                          ? item.titleEn
                                          : item.titleVi}
                                      </CustomText>
                                    </div>
                                  </div>
                                  <div className="txt-new">
                                    {currentLanguage.isEN
                                      ? item.descriptionsEn
                                      : item.descriptionsVi}
                                  </div>
                                  <div className="date-new">
                                    <div className="txt-date">
                                      {moment(item.approveDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </div>
                                    <div className="txt-detail">
                                      <CustomText className="to-detail">
                                        {t("detail")}
                                      </CustomText>
                                      <ArrowForwardIcon className="icon-detail" />
                                    </div>
                                  </div>
                                </CardContent>
                              </div>
                            </div>
                          </Grid>
                        )
                    )}
                  </Grid>
                </div>
              </div>
            </div>
          )}
        </div>
        <LazyLoad offset={0}>
        <NewsCategoryScreen
          resDataNewsCaterory={resDataNewsCaterory}
          style={{ padding: 230 }}
        />
        </LazyLoad>

      </Container>
      <FooterComponent />
    </div>
  );
}

export default NewsScreen;
