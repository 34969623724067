import { Loader } from "@googlemaps/js-api-loader";
import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import moment from "moment";
import { env } from "process";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { AppRepositories } from "../../apis/app-repositories";
import {
  CreatePropertyHeartModel,
  ListPropertyModel,
  PaginatedListOfListPropertyModel,
  PropertyModel,
} from "../../apis/general-client";
import BathroomIcon from "../../assets/icons/bathroom.svg";
import BedroomIcon from "../../assets/icons/bedroom.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Heart } from "../../assets/icons/heart-2.svg";
import { ReactComponent as HeartActive } from "../../assets/icons/Heart.svg";
import LotsizeIcon from "../../assets/icons/Lotsize.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import VitualTourIcon from "../../assets/icons/Vitual_Tour.svg";
import WatchVideoICon from "../../assets/icons/watch_video.svg";
import AppBreadcrumbs from "../../components/app-breadcrumbs";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import MultiSlides from "../../components/multi-slides";
import { CustomText } from "../../components/styled-custom";
import ViewImageComponent from "../../components/view-image-component";
import StyledVariables from "../../helpers/materials/_variables";
import {
  currentLanguage,
  formatLotSize,
  formatNumber,
  getPrice,
  getSiteDomain,
  getToken,
  getUser,
  recentlyProperty,
} from "../../helpers/utils";
import ProductDetailRepositories from "../project-detail/project-detail-controller/project-detail-repositories";
import PropertyListRepositories from "../property-map-list/property-list-controllers/property-list-repositories";
import PropertyDetailSeller from "./property-detail-seller";

type PropertyDetailType = {
  label: string;
  value?: string;
  url?: string;
};

function PropertyDetaiScreen() {
  const { t } = useTranslation();
  let location = useLocation();
  const [openDialogSaveSearch, setOpenSignIn] = useState<boolean>(false);
  const domain = getSiteDomain();

  const local = location?.state as any;

  const navigate = useNavigate();

  const [propertyDetailData, setPropertyDetailData] =
    React.useState<PropertyModel>();
  const [propertyId, setPropertyId] = React.useState<string>();
  const [propertySlug, setPropertySlug] = React.useState<string>();
  const [isHeart, setIsHeart] = React.useState<boolean>(false);
  const [isCommerical, setIsCommerical] = React.useState<boolean>(false);
  const [dataCountPropertys, setDataCountPropertys] = useState<any>({});
  const propertyDetails: PropertyDetailType[] = [
    {
      label: "propertyID",
      value: propertyDetailData?.propertyNumber,
    },
    {
      label: "propertyType",
      value: currentLanguage.isEN
        ? propertyDetailData?.propertyTypeEn
        : propertyDetailData?.propertyTypeVi,
    },
    {
      label: "propertyStatus",
      value: currentLanguage.isEN
        ? propertyDetailData?.transactionTypeEn
        : propertyDetailData?.transactionTypeVi,
    },
    {
      label: "amentitisNearby",
      value: propertyDetailData?.propertyAmenitiesNearbys
        ?.map((x) =>
          currentLanguage.isEN ? x.amenitiesNearbyEn : x.amenitiesNearbyVi
        )
        .join(", "),
    },
    {
      label: "Project",
      value: currentLanguage.isEN
        ? propertyDetailData?.projectEn
        : propertyDetailData?.projectVi,
      url: "/project/" + propertyDetailData?.projectId,
    },
    {
      label: "bedrooms",
      value: propertyDetailData?.bedroomEn,
    },
    {
      label: "bathrooms",
      value: propertyDetailData?.bathroomEn,
    },

    {
      label: "lotSize",
      value:
        formatLotSize(propertyDetailData?.lotSizeFeet || 0).toString() + " ft2",
    },

    {
      label: "storeyFloorNumber",
      value: propertyDetailData?.floorsNumber?.toString(),
    },
    {
      label: "completionDate",
      value: moment(propertyDetailData?.yearCompleted).format("YYYY"),
    },
    {
      label: "totalNumberOfFloorInBuilding",
      value: propertyDetailData?.totalBuildingFloors?.toString(),
    },

    {
      label: "timeOnVietnameHome",
      value: moment(propertyDetailData?.approveDate).format("MM/DD/YYYY"),
    },
  ];

  const { slug } = useParams();
  const [similarProperties, setSimilarProperties] =
    useState<PaginatedListOfListPropertyModel>();

  useEffect(() => {
    getPropertyDetail();
    setDataCountPropertys(dataCountPropertys);
  }, [propertyId, local?.index]);

  const mapConfig = {
    apiKey: process.env.REACT_APP_apiKey ? process.env.REACT_APP_apiKey : "",
    version: process.env.REACT_APP_version,
    mapId: process.env.REACT_APP_mapId,
  };

  const mapFunctions = {
    mapInstance: {
      map: null as any,
    },
    initMap(data: any) {
      let self = this;
      //load map
      const loader = new Loader({
        apiKey: mapConfig.apiKey,
        version: mapConfig.version,
        libraries: ["places"],
      });
      var lat = parseFloat(data.latitude);
      var lng = parseFloat(data.longitude);

      loader.load().then(() => {
        self.mapInstance.map = new google.maps.Map(
          document.getElementById("mapRender") as HTMLElement,
          {
            center: { lat: lat, lng: lng },
            zoom: 16,
            minZoom: 16,
            //mapId: mapConfig.mapId,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            gestureHandling: "greedy",
          }
        );
        this.addMarker({ lat: lat, lng: lng });
      });
    },
    addMarker(position: google.maps.LatLng | google.maps.LatLngLiteral) {
      var mapData = this.mapInstance.map;
      const marker = new google.maps.Marker({
        position,
        map: mapData,
      });
    },
  };

  async function getPropertyDetail() {
    const data = await AppRepositories.getPropertyBySlug(slug || "");
    const id = data.id;
    setPropertyId(data.id);

    const dataCountView = await PropertyListRepositories.viewCountProperty(
      id || ""
    );
    if (dataCountView) {
      setDataCountPropertys(dataCountView);
    }

    setIsCommerical(
      data.transactionTypeId !== "004" && data.transactionTypeId !== "005"
        ? false
        : true
    );
    setPropertyDetailData(data);
    getSimilarProperties(data);
    id && recentlyProperty.set(id);
    mapFunctions.initMap(data);
    getIsPropertyHeart();
  }

  function chooseProperty(item: any) {
    if (item.transactionTypeId === "004" || item.transactionTypeId === "005") {
      navigate("/commercial/" + item.slug, { state: { index: 3 } });
    } else {
      navigate("/property/" + item.slug);
    }
    setPropertyId(item.id);
    setPropertySlug(item.slug);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  async function getIsPropertyHeart() {
    const token = getToken() != null;
    if (token) {
      const user = getUser();
      var model = {
        propertyId: propertyId,
        userId: user.id,
      } as CreatePropertyHeartModel;

      const isHeart = await AppRepositories.isPropertyHeart(model);
      setIsHeart(isHeart);
    }
  }

  async function getSimilarProperties(similarProperty: ListPropertyModel) {
    let val = {
      pageNumber: 1,
      pageSize: 12,
      propertyTypeId: similarProperty?.propertyTypeId,
      transactionTypeId: similarProperty?.transactionTypeId,
    };
    const resData = await AppRepositories.getPropertyList(val);
    setSimilarProperties(resData);
  }

  const setSignInModel = (val: boolean) => {
    setOpenSignIn(val);
  };

  const sendPropertyHeart = async () => {
    const token = getToken() != null;
    if (token) {
      const user = getUser();
      const model = {
        propertyId: propertyId,
        userId: user.id,
      } as CreatePropertyHeartModel;

      const res = await AppRepositories.sendPropertyHeart(model);
      if (res.succeeded) {
        const isHeart = await AppRepositories.isPropertyHeart(model);
        setIsHeart(isHeart);
      }
    } else {
      setOpenSignIn(true);
    }
  };

  const redirectToProject = async () => {
    if (propertyDetailData?.projectId) {
      const project = await ProductDetailRepositories.getProductDetail(propertyDetailData?.projectId);
      navigate("/project/" + project.slug);
    }
  }

  return propertyDetailData ? (
    <div className="property-detail-container">
      <HeaderBar
        isopen={openDialogSaveSearch}
        setSignInModel={setSignInModel}
        transactionId={propertyDetailData.transactionTypeId}
      />
      <Container>
        <AppBreadcrumbs
          transactionTypeId={propertyDetailData.transactionTypeId}
          detailTitle={propertyDetailData.title}
        />
        <div className="property-content">
          <div className="section">
            <CustomText className="font2 fontXL">
              {propertyDetailData.title}
            </CustomText>
          </div>
          <div className="section mt12">
            <div className="dBlock md:dFlex justifyBetween">
              <CustomText>
                {currentLanguage.isEN
                  ? propertyDetailData.districtNameEn
                  : propertyDetailData.districtName}
                ,{" "}
                {currentLanguage.isEN
                  ? propertyDetailData.provinceNameEn
                  : propertyDetailData.provinceName}
              </CustomText>
              <div className="dFlex itemsCenter share-icon">
                {/* <div>
                  <CustomText>{t("share")}: </CustomText>
                </div>
                <div>
                  <img src={FacebookIcon} alt="facebook" />
                </div>
                <div>
                  <img src={TwitterIcon} alt="twitter" />
                </div> */}
                <div className="share-new">
                  <CustomText className="txt-share">{t("share")} </CustomText>
                  <div className="share">
                    <FacebookShareButton
                      url={domain + "/property/" + propertyDetailData.slug}
                      className="Demo__some-network__share-button"
                    >
                      <Facebook />
                    </FacebookShareButton>

                    <TwitterShareButton
                      // title={"test"}
                      url={domain + "/property/" + propertyDetailData.slug}
                      hashtags={["vietnamhome"]}
                    >
                      <Twitter />
                    </TwitterShareButton>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="mb20" />

          <div className="section">
            <ViewImageComponent
              images={propertyDetailData.propertyImages}
              imageVariable={"imagesUrl"}
            />
          </div>

          <div className="section">
            <div className="image-3d"></div>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={9}>
                {/* Price */}
                <Card className="card-item">
                  <CardContent>
                    <div className="dFlex justifyBetween">
                      <div className="card-item-wrapper">
                        <div className="price">
                          <CustomText>
                            {getPrice(
                              propertyDetailData.usdPrice!,
                              propertyDetailData.currencyNotation!,
                              propertyDetailData.price!
                            )}
                            {propertyDetailData.transactionTypeId === "001" ||
                              propertyDetailData.transactionTypeId === "004" ? (
                              <span>/{t("daily")}</span>
                            ) : (
                              ""
                            )}
                          </CustomText>
                        </div>
                        {
                          propertyDetailData.priceOnMonth != 0 &&
                          <div className="price-monthly">
                            <CustomText style={{ fontSize: '20px' }}>
                              {getPrice(
                                propertyDetailData.usdPrice!,
                                propertyDetailData.currencyNotation!,
                                propertyDetailData.priceOnMonth!
                              )}
                              {propertyDetailData.transactionTypeId === "001" ||
                                propertyDetailData.transactionTypeId === "004" ? (
                                <span>/{t("monthly")}</span>
                              ) : (
                                ""
                              )}
                            </CustomText>
                          </div>
                        }
                      </div>
                      <div className="favourite textCenter">
                        <Button
                          onClick={() => {
                            sendPropertyHeart();
                          }}
                        >
                          {isHeart ? (
                            <HeartActive
                              className="heart"
                              fill={StyledVariables.colors.red400}
                            />
                          ) : (
                            <Heart
                              className="heart"
                              fill={StyledVariables.colors.red400}
                            />
                          )}
                        </Button>
                        <CustomText>{t("favourite")}</CustomText>
                      </div>
                    </div>
                    <CustomText>
                      {currentLanguage.isEN
                        ? propertyDetailData.districtNameEn
                        : propertyDetailData.districtName}
                      ,{" "}
                      {currentLanguage.isEN
                        ? propertyDetailData.provinceNameEn
                        : propertyDetailData.provinceName}
                    </CustomText>
                    <CustomText>
                      {t("propertyNumber")}: {propertyDetailData.propertyNumber}
                    </CustomText>
                    <div className="dBlock md:dFlex justifyBetween mt28">
                      <div className="buttons dBlock sm:dFlex">
                        {propertyDetailData.videoLink && (
                          <a
                            href={propertyDetailData.videoLink}
                            className="borderNone"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <div className="button button-white dFlex itemsCenter mb16">
                              <div className="button-image">
                                <img src={WatchVideoICon} alt="Video" />
                              </div>
                              <CustomText>{t("video")}</CustomText>
                            </div>
                          </a>
                        )}
                        {propertyDetailData.virtualVideoLink && (
                          <a
                            href={propertyDetailData.virtualVideoLink}
                            className="borderNone"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <div className="button button-green dFlex itemsCenter mb16">
                              <div className="button-image">
                                <img src={VitualTourIcon} alt="Virtual Tour" />
                              </div>
                              <CustomText>{t("virtualTour")}</CustomText>
                            </div>
                          </a>
                        )}
                      </div>
                      <div className="utilities-icons dFlex textCenter sm:itemsEnd justifyCenter">
                        <div className="utilities-icon">
                          <div className="icon">
                            <img src={LotsizeIcon} alt="lot size" />
                          </div>
                          <CustomText>
                            {formatLotSize(propertyDetailData?.lotSizeFeet || 0)}{" "}
                            ft<sup>2</sup>
                          </CustomText>
                        </div>
                        {!isCommerical && (
                          <div className="utilities-icon">
                            <div className="icon">
                              <img src={BedroomIcon} alt="bedrooms" />
                            </div>
                            <CustomText>
                              {propertyDetailData.bedroomEn} {t("bedrooms")}
                            </CustomText>
                          </div>
                        )}
                        {!isCommerical && (
                          <div className="utilities-icon">
                            <div className="icon">
                              <img src={BathroomIcon} alt="bathrooms" />
                            </div>
                            <CustomText>
                              {propertyDetailData.bathroomEn} {t("bathrooms")}
                            </CustomText>
                          </div>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>

                {/* Description */}
                <Card className="card-item" id="property-description">
                  <CardContent>
                    <CustomText className="fontMP greenLight">
                      {t("descriptions")}
                    </CustomText>
                    <div
                      className="fontM property-descriptions"
                      dangerouslySetInnerHTML={{
                        __html: propertyDetailData.descriptions
                          ? propertyDetailData.descriptions
                          : "",
                      }}
                    ></div>
                  </CardContent>
                </Card>

                {/* Detail */}
                <Card className="card-item">
                  <CardContent>
                    <CustomText className="fontMP greenLight">
                      {t("details")}
                    </CustomText>
                    <div className="mt20 property-detail">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          {propertyDetails &&
                            propertyDetails.map(
                              (item, index) =>
                                index > -1 &&
                                index < 5 && (
                                  <>
                                    {((item.label === "Project" &&
                                      item.value) ||
                                      item.label !== "Project") && (
                                        <Grid
                                          item
                                          container
                                          spacing={2}
                                          className="property-detail-item"
                                        >
                                          <Grid item xs={12} sm={6}>
                                            <CustomText className="semiBold">
                                              {t(item.label)}
                                            </CustomText>
                                          </Grid>
                                          <Grid
                                            className="property-detail-item-content"
                                            item
                                            xs={12}
                                            sm={6}
                                          >
                                            <CustomText className="dFlex">
                                              <span className="colon">
                                                :&nbsp;
                                              </span>
                                              {item.label === "Project" ? (
                                                <span className="underline pointer" onClick={() => redirectToProject()}>
                                                  {item.value}
                                                </span>
                                              ) : (
                                                <span>{item.value}</span>
                                              )}
                                            </CustomText>
                                          </Grid>
                                        </Grid>
                                      )}
                                  </>
                                )
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {propertyDetails &&
                            propertyDetails.map(
                              (item, index) =>
                                index > 4 &&
                                (isCommerical
                                  ? index !== 5 && index !== 6
                                  : item) && (
                                  <Grid
                                    container
                                    spacing={2}
                                    className="property-detail-item"
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <CustomText className="semiBold">
                                        {t(item.label)}
                                      </CustomText>
                                    </Grid>
                                    <Grid
                                      className="property-detail-item-content"
                                      item
                                      xs={12}
                                      sm={6}
                                    >
                                      <CustomText className="dFlex">
                                        <span className="colon">:&nbsp;</span>
                                        <span>{item.value}</span>
                                      </CustomText>
                                    </Grid>
                                  </Grid>
                                )
                            )}
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>

                {/* Map */}
                <Card className="card-item">
                  <CardContent>
                    <CustomText className="fontMP greenLight">
                      {t("map")}
                    </CustomText>
                  </CardContent>
                  <div id="mapRender" className="mapContent"></div>
                </Card>

                {/* Similar Home */}
                <Card className="card-item">
                  <CardContent>
                    <CustomText className="fontMP greenLight">
                      {t("similarHomesYouMayLike")}
                    </CustomText>
                    <div className="luxury-slides-container">
                      {similarProperties?.items && (
                        <MultiSlides
                          showDot
                          responsive={{
                            desktop: 3,
                            tabletL: 3,
                            tabletM: 2,
                            mobile: 1,
                          }}
                        >
                          {similarProperties?.items.map((item, index) => (
                            <div key={index} className="handle-hover">
                              <div
                                onClick={() => chooseProperty(item || "")}
                                style={{ textDecoration: "none" }}
                              >
                                <Button className="luxury-slides-item">
                                  <div
                                    className="image-item"
                                    style={{ height: 300 }}
                                  >
                                    <img
                                      src={item.coverImageUrl}
                                      alt=""
                                      draggable={false}
                                    />
                                  </div>
                                  <div className="content-item">
                                    <CustomText className="fontMP semiBold mb12">
                                      {item.title}
                                    </CustomText>
                                    <CustomText className="fontMP mb12">
                                      {currentLanguage.isEN
                                        ? item.propertyTypeEn
                                        : item.propertyTypeVi}
                                    </CustomText>
                                    <CustomText className="fontSP mb12">
                                      {currentLanguage.isEN
                                        ? item.locationEn
                                        : item.location}
                                    </CustomText>
                                    <CustomText className="fontMP semiBold mb12">
                                      {getPrice(
                                        item.usdPrice!,
                                        item.currencyNotation!,
                                        item.price!
                                      )}
                                    </CustomText>
                                    <div className="info-content">
                                      <CustomText className="fontSP">
                                        {formatLotSize(item.lotSizeFeet || 0)}{" "}
                                        ft2
                                      </CustomText>
                                      {local?.index !== 3 &&
                                        item.bedroomEn != null && (
                                          <CustomText className="fontSP">
                                            {item.bedroomEn + " " + t("beds")}
                                          </CustomText>
                                        )}
                                      {local?.index !== 3 &&
                                        item.bathroomEn != null && (
                                          <CustomText className="fontSP">
                                            {item.bathroomEn + " " + t("baths")}
                                          </CustomText>
                                        )}
                                    </div>
                                  </div>
                                </Button>
                              </div>
                            </div>
                          ))}
                        </MultiSlides>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={3}>
                <PropertyDetailSeller
                  propertySellers={propertyDetailData.propertySellers}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <FooterComponent />
    </div>
  ) : (
    <></>
  );
}

export default PropertyDetaiScreen;
