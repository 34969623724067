import { generalClient } from "../../../helpers/utils";

const HomeRepositories = {
    
  createContact: async (data: any) => {
    try {
      const resData = await generalClient.contact_Create(data)
      return resData.succeeded
    } catch (error) {
     return false
    }
   
    // return resData;
  }
}

export default HomeRepositories