import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderBar from "../../components/header-bar";
import FooterComponent from "../../components/footer-component";
import AppBreadcrumbs from "../../components/app-breadcrumbs";
import { useEffect, useState } from "react";
import NewsRepositories from "./news-controllers/news-list-repositories";
import { appLoading, currentLanguage } from "../../helpers/utils";
import { CustomText } from "../../components/styled-custom";
import CurrencyFormat from "react-currency-format";
import PropertyDetailSeller from "../property-detail/property-detail-seller";
import MultiSlides from "../../components/multi-slides";
import { TabContext, TabList, TabPanel } from "@mui/lab";

function WellReadScreen(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const local = location?.state as any;
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  const [isload, setLoad] = React.useState<boolean>(false);
  const [dataWellReads, setMasterDataRelateds] = useState<any>({});

  const [resDataRelated, setMasterDataRelated] = useState<any>({});

  async function getDatasRelated() {
    let pagingModel = {
      pageNumber: 1,
      pageSize: 6,
    };
    const dataWellReads = await NewsRepositories.getWellReadNews(pagingModel);
    setLoad(true);
    setMasterDataRelateds(dataWellReads);
  }

  useEffect(() => {
    getDatasRelated();
    setMasterDataRelated(props.resDataRelated);
  }, [local?.id, props.resDataRelated]);

  const onNavigate = (slug: string) => {
    navigate("/news/" + slug);
    window.scrollTo(0, 0);
  };

  return dataWellReads ? (
    <div className="news-detail-container w100 pointer">
      {dataWellReads?.items && (
        <div className="container-new">
          <div className="mapContent">
            <Grid
              container
              rowSpacing={4}
              columnSpacing={{ xs: 1, sm: 4, md: 4 }}
            >
              {dataWellReads?.items.map((item: any, index: number) => (
                // <Grid item xs={12} sm={12} lg={12} key={index}>
                <div className="border-new" onClick={() => onNavigate(item.slug)} key={index}>
                  <div className="card-wellread">
                    {/* <CardMedia
                      className="img-new "
                      component="img"
                      height="100"
                      image={item.imagePathUrl}
                      alt="Image"
                    /> */}
                    <img
                      className="img-lastest"
                      // component="img"
                      // height="100"
                      src={item.imagePathUrl}
                      alt="Image Well Read"
                    />
                    <div className="w100">
                      <div className="title-news-wellread">
                        <div className="txt-news-wellread">
                          <CustomText>
                            {currentLanguage.isEN ? item.titleEn : item.titleVi}
                          </CustomText>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                // </Grid>
              ))}
            </Grid>
          </div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

export default WellReadScreen;
