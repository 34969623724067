import {
  Alert,
  Button,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState, useMemo } from "react";
import CachedIcon from "@mui/icons-material/Cached";
import * as React from "react";
import { useTranslation } from "react-i18next";
import CustomCard from "../../../components/custom-card";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { CustomText } from "../../../components/styled-custom";
import MyAccountRepositories from "./controllers/my-account-repositories";
import moment from "moment";
import { appLoading, basicHTML } from "../../../helpers/utils";
import { useNavigate } from "react-router-dom";
import MasterData from "../../../apis/master-data";
import CustomSelect, {
  SelectItemType,
} from "../../../components/custom-select";
import {
  initExistedSellHomeModal,
  sellHomeModal,
} from "../../sell-home/controllers/sell-home-modal";
import { Console } from "console";
import { AppRepositories } from "../../../apis/app-repositories";
import CustomTextField from "../../../components/custom-text-field";
// import DialogComponent from "../../../components/dialog-component";
import DialogComponent from "../../../components/dialog-component";
type ListingType = {
  isCommercial?: boolean;
};
function ListingManagement(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data: SelectItemType[] = useMemo(
    () => [
      {
        label: t("transactionType"),
        name: "transactionType",
        placeholder: "listingType",
        data: [
          {
            id: "default",
            transactionTypeVi: t("listingType"),
            transactionTypeEn: t("listingType"),
          },
          ...MasterData.transaction,
        ],
      },
    ],
    [t]
  );

  const dataStatus: SelectItemType[] = useMemo(
    () => [
      {
        label: t("status"),
        name: "status",
        // placeholder: "Listing Type",

        data: [
          {
            id: "default",
            statusVi: t("status"),
            statusEn: t("status"),
          },
          {
            id: "unposted",
            statusVi: t("unposted"),
            statusEn: t("unposted"),
          },
          {
            id: "available",
            statusVi: t("available"),
            statusEn: t("available"),
          },
          {
            id: "lock",
            statusVi: t("lock"),
            statusEn: t("lock"),
          },
          {
            id: "draft",
            statusVi: t("draft"),
            statusEn: t("draft"),
          },
          {
            id: "expired",
            statusVi: t("expired"),
            statusEn: t("expired"),
          },

          // ...MasterData.transaction,
        ],
      },
    ],
    [t]
  );

  const [value, setValue] = useState<any>({ transactionType: "default" });

  const [valueStatus, setValueStatus] = useState<any>({
    status: "default",
  });
  const [masterData, setMasterData] = useState<any>({});
  const [dateValueFrom, setDateValueFrom] = useState<any>("");
  const [dateValueTo, setDateValueTo] = useState<any>("");
  const [idValue, setIdValue] = useState<any>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogNoDraft, setOpenDialogNoDraft] = useState<boolean>(false);

  const [openNoti, setOpenNoti] = useState(false);
  const [openNotifalse, setOpenNotifalse] = useState(false);
  const [disable, setDisable] = useState<boolean>(true);

  const getListStatus = (value: any) => {
    var listEnumStatus = [];
    switch (value) {
      case "unposted":
        listEnumStatus.push(1);
        listEnumStatus.push(3);
        break;
      case "available":
        listEnumStatus.push(2);
        break;
      case "lock":
        listEnumStatus.push(-1);
        break;
      case "draft":
        listEnumStatus.push(5);
        break;
      case "expired":
        listEnumStatus.push(4);
        break;
      default:
        listEnumStatus = [];
        break;
    }
    return listEnumStatus;
  };

  async function getDatas() {
    appLoading.show();
    try {
      let val = {
        transactionTypeId:
          value.transactionType === "default" ? "" : value.transactionType,
        fromDate: dateValueFrom,
        toDate: dateValueTo,
        listStatus: getListStatus(valueStatus.status),
      };
      const resData = await MyAccountRepositories.searchPropertyByUser(val);
      setMasterData(resData);
      appLoading.dismiss();
    } catch (err) {
      appLoading.dismiss();
    }
  }

  async function deleteProperty(id: string) {
    const resData = await MyAccountRepositories.deleteTempProperty(id);
    getDatas();
    if (resData.succeeded) {
      setOpenNoti(true);
      setOpenDialog(false);
    } else {
      setOpenNotifalse(true);
    }
  }

  const onDeleteproperty = (id: string, approveStatusName: string) => {
    if (approveStatusName === "Draft") {
      setDisable(false);
      setOpenDialog(true);
      setIdValue(id);
    } else {
    }
  };

  const onNavigateEdit = (item: any, approveStatusName: string) => {
    if (approveStatusName === "Draft") {
      getPropertyDetail(item.id);
    }

    if (approveStatusName !== "Draft") {
      setOpenDialogNoDraft(true);
    }
  };

  const onDelete = () => {
    deleteProperty(idValue);
  };

  const handleDateChangeFrom = (e: any) => {
    let newDate = moment(e).format("MM/DD/YY");
    if (newDate === "Invalid date") {
      newDate = "";
      setDateValueFrom(newDate);
    } else {
      setDateValueFrom(newDate);
    }
  };
  const handleDateChangeTo = (e: any) => {
    let newDate = moment(e).format("MM/DD/YY");
    if (newDate === "Invalid date") {
      newDate = "";
      setDateValueTo(newDate);
    } else {
      setDateValueTo(newDate);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };

  const onNavigate = (item: any) => {
    if (item.transactionTypeId === "004") {
      navigate("/commercial/" + item.slug, { state: { index: 3 } });
    } else {
      navigate("/property/" + item.slug);
    }
  };

  async function getPropertyDetail(id: string) {
    const dataItem = await AppRepositories.getPropertyById(id || "");
    if (dataItem.transactionTypeId === "004") {
      // initExistedSellHomeModal(dataItem);
      navigate("/sell-commercial", { state: { item: dataItem } });
      window.scrollTo(0, 0);
    } else {
      // initExistedSellHomeModal(dataItem);
      navigate("/sell-home", { state: { item: dataItem } });
      window.scrollTo(0, 0);
    }
  }

  const onSearch = () => {
    getDatas();
  };

  const getValue = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    value[targetName] = targetValue;
    sellHomeModal[targetName] = targetValue === "default" ? "" : targetValue;
    setValue({ ...value });
    // props.required[targetName] && props.validator(targetName);
  };

  const getValueStatus = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    valueStatus[targetName] = targetValue;
    setValueStatus({ ...valueStatus });
  };

  useEffect(() => {
    getDatas();
  }, []);

  return (
    <div className="right change-pass w100 list-management">
      <CustomCard title={t("listingManagement")}>
        <div className="button-group py40">
          <Grid item md={12} xs={12} lg={2.5} className="grid-select">
            {data.map((item, index) => (
              <CustomSelect
                className="btn-select"
                placeholder={item.name && t(item.name)}
                name={item.name}
                value={value[item.name]}
                data={item.data}
                onChange={getValue}
              />
            ))}
          </Grid>
          <Grid item md={12} xs={12} lg={2.5} className="date-picker">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              className="choose-date"
            >
              <DatePicker
                label={t("from")}
                inputFormat="dd/MM/yyyy"
                value={dateValueFrom}
                onChange={(newValue) => {
                  handleDateChangeFrom(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="text-field txt-date"
                    variant="outlined"
                    error={props.error}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: props.placeholder,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={12} xs={12} lg={2.5} className="date-picker">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                className="choose-date"
                label={t("to")}
                inputFormat="dd/MM/yyyy"
                value={dateValueTo}
                onChange={(newValue) => {
                  handleDateChangeTo(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="text-field txt-date"
                    variant="outlined"
                    error={props.error}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: props.placeholder,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={12} xs={12} lg={2.5}>
            <Grid container spacing={0}>
              {dataStatus.map((item, index) => (
                <Grid key={index} item md={12} xs={12} lg={12}>
                  <CustomSelect
                    className="btn-select"
                    name={item.name}
                    value={valueStatus[item.name]}
                    data={item.data}
                    onChange={getValueStatus}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} lg={2}>
            <Button className="save-submit" onClick={() => onSearch()}>
              {t("search")}
            </Button>
          </Grid>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table mb12">
            <TableHead>
              <TableRow>
                <TableCell className="semiBold" align="right">
                  {t("no.")}
                </TableCell>
                <TableCell className="semiBold"> {t("title")}</TableCell>
                <TableCell className="semiBold nowrap" align="right">
                  {t("status")}
                </TableCell>
                <TableCell className="semiBold nowrap" align="right">
                  {t("listingDate")}
                </TableCell>
                <TableCell className="semiBold nowrap" align="right">
                  {t("endDay")}
                </TableCell>
                <TableCell className="semiBold" align="center">
                  {t("action")}
                </TableCell>
              </TableRow>
            </TableHead>
            {masterData?.length ? (
              <TableBody>
                {masterData?.map((item: any, index: number) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="px4" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell className="px4" component="th" scope="row">
                      {item.title}
                      <div className="view-edit pt20 ">
                        <div className="count-view">
                          <RemoveRedEyeOutlinedIcon className="icon-viewedit" />
                          <CustomText className="txt-countview">
                            {item.viewCount} {t("view")}
                          </CustomText>
                        </div>
                        <div
                          className="view pointer"
                          onClick={() => onNavigate(item)}
                        >
                          <InsertDriveFileIcon className="icon-viewedit" />
                          <CustomText className="txt-view">
                            {t("view")}
                          </CustomText>
                        </div>
                        <div
                          onClick={() =>
                            onNavigateEdit(item, item.approveStatusName)
                          }
                          className="edit-list"
                        >
                          <EditIcon className="icon-edit" />
                          <CustomText className="txt-edit nowrap">
                            {t("edit")}
                          </CustomText>
                        </div>
                      </div>
                    </TableCell>
                    {item.approveStatusName === "Active" && (
                      <TableCell className="px4" align="center">
                        {t("available")}
                      </TableCell>
                    )}
                    {(item.approveStatusName === "New" ||
                      item.approveStatusName === "InActive") && (
                      <TableCell className="px4" align="center">
                        {t("unposted")}
                      </TableCell>
                    )}
                    {item.approveStatusName === "Lock" && (
                      <TableCell className="px4" align="center">
                        {t("lock")}
                      </TableCell>
                    )}
                    {item.approveStatusName === "Expired" && (
                      <TableCell className="px4" align="center">
                        {t("expired")}
                      </TableCell>
                    )}
                    {item.approveStatusName === "Draft" && (
                      <TableCell className="px4" align="center">
                        <CustomText className="nowrap">{t("draft")}</CustomText>
                      </TableCell>
                    )}

                    {/* <TableCell className="px4" align="center">
                      {moment(item.createTime).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className="px4" align="right">
                      {moment(item.expiredDate).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className="button-group-delete py40 px4"> */}
                    {item.approveStatusName === "New" ||
                    item.approveStatusName === "InActive" ||
                    item.approveStatusName === "Draft" ||
                    item.approveStatusName === "Lock" ? (
                      <>
                        <TableCell align="center">----</TableCell>
                        <TableCell align="center">----</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="right">
                          {moment(item.approveDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell align="right">
                          {moment(item.expiredDate).format("DD/MM/YYYY")}
                        </TableCell>
                      </>
                    )}
                    <TableCell className="button-group-delete py40">
                      <div className="relist-delete">
                        <CachedIcon className="icon-relist" />
                        <CustomText className="txt-reLis">
                          {t("reListing")}
                        </CustomText>
                      </div>
                      <div
                        onClick={() =>
                          onDeleteproperty(item.id, item.approveStatusName)
                        }
                        className={
                          item.approveStatusName === "Draft"
                            ? "btn-enabled"
                            : "btn-disable"
                        }
                      >
                        <DeleteIcon className="icon-del"></DeleteIcon>
                        <CustomText className="txt-del">
                          {t("delete")}
                        </CustomText>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <div className="txt-item">{t("noItem")}</div>
            )}
          </Table>
        </TableContainer>
      </CustomCard>
      <DialogComponent
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        className="save-search-bar-dialog"
        containerClassName="dialog-save-search"
      >
        <CustomText className="title-save ">{t("deleteConfirm")}</CustomText>
        <CustomText className="title-name-delete bold">
          {t("doYouWantToDeleteThisData")}
        </CustomText>

        <div className="dFlex justifyCenter dialog-save-search-button mt20">
          <Button
            className="cancel"
            variant="outlined"
            onClick={() => onDelete()}
          >
            {t("Yes")}
          </Button>
          <Button
            className="save"
            variant="outlined"
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            {t("no")}
          </Button>
        </div>
      </DialogComponent>
      <DialogComponent
        open={openDialogNoDraft}
        onClose={() => setOpenDialogNoDraft(false)}
        className="save-search-bar-dialog"
        containerClassName="dialog-save-search"
      >
        <CustomText className="bold"
          dangerouslySetInnerHTML={{ __html: t("draftStatusOnly") }}
        ></CustomText>
        <div className="dFlex justifyCenter ">
          <Button
            className="save-list"
            variant="outlined"
            onClick={() => {
              setOpenDialogNoDraft(false);
            }}
          >
            {t("ok")}
          </Button>
        </div>
      </DialogComponent>
      {openNoti === true && (
        <Snackbar
          open={openNoti}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            <div className="bold">{t("deleteSuccessfully")}</div>
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default ListingManagement;
