import { Search, SearchOutlined } from "@mui/icons-material";
import {
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTextField from "./custom-text-field";
import { CustomText } from "./styled-custom";
import { ReactComponent as Filters } from "../assets/icons/Filters.svg";
import DialogComponent from "./dialog-component";
import CustomSelect, { SelectItemType } from "./custom-select";
import { AppRepositories } from "../apis/app-repositories";
import {
  SuggestPropertyAdministrativeModel,
  SuggestPropertyModel,
  SuggestPropertyTypes,
  SuggestSearchPropertyModel,
  SuggestSearchPropertyRequestModel,
} from "../apis/general-client";
import { useLocation, useNavigate } from "react-router-dom";
import MasterData from "../apis/master-data";
import { currentLanguage } from "../helpers/utils";
import AppModal, { SearchingPropertyType } from "../apis/app-modal";
import MapFlagIcon from "../assets/icons/map-flag.svg";
import BuidlingIcon from "../assets/icons/building.svg";

export type selectListType = {
  label: string;
  key: string;
  valueItems: [
    {
      value: string;
      displayName: string;
    }
  ];
};

type SearchBarType = {
  isMappage?: boolean;
  onSearch?: () => void;
  index?: number;
  transactionId?: string;
};

function SearchBar(props: SearchBarType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [propertyType, setPropertyType] = useState<any>([]);
  const [keywordValue, setKeywordValue] = useState<string>(
    AppModal.searchingPropertyModel.propertyKeyWord
  );

  const priceValue: number[] = [
    0, 25000, 50000, 75000, 100000, 125000, 150000, 175000, 200000, 225000,
    250000, 275000, 300000, 325000, 350000, 375000, 400000, 425000, 450000,
    475000, 500000, 550000, 600000, 650000, 700000, 750000, 800000, 850000,
    900000, 950000, 1000000, 1100000, 1200000, 1300000, 1400000, 1500000,
    1600000, 1700000, 1800000, 1900000, 2000000, 2500000, 3000000, 3500000,
    4000000, 4500000, 5000000, 5500000, 6000000, 6500000, 7000000, 7500000,
    8000000, 8500000, 9500000, 10000000, 15000000, 20000000,
  ];

  const landSizeValue: number[] = [
    0, 1, 2, 5, 10, 50, 100, 200, 300, 400, 500, 1000,
  ];

  const dialogData: SelectItemType[] = useMemo(
    () => [
      {
        label: "transactionType",
        name: "transactionType",
        placeholder: "forSale",
        data: MasterData.transaction ? [
          {
            id: "default",
            transactionTypeVi: t("all"),
            transactionTypeEn: t("all"),
          },
          ...MasterData.transaction,
        ] : [
          {
            id: "default",
            transactionTypeVi: t("all"),
            transactionTypeEn: t("all"),
          },
        ],
      },
      {
        label: "listedSince",
        name: "listedSince",
        placeholder: "mdy",
        data: [{ text: t("listedSince"), value: "listedSince" }],
      },
    ],
    []
  );

  const numberFormat = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const numberData: SelectItemType[] = useMemo(
    () => [
      {
        label: "minPrice",
        name: "minPrice",
        placeholder: "0",
        data: priceValue.map((x) => ({
          id: x.toString(),
          minPriceEn: numberFormat(x),
          minPriceVi: numberFormat(x),
        })),
      },
      {
        label: "maxPrice",
        name: "maxPrice",
        placeholder: "unlimited",
        data: priceValue.map((x) => ({
          id: x.toString(),
          maxPriceEn: numberFormat(x),
          maxPriceVi: numberFormat(x),
        })),
      },
      {
        label: "landSize",
        name: "landSize",
        placeholder: "any",
        data: landSizeValue.map((x) => ({
          id: x.toString(),
          landSizeEn: numberFormat(x),
          landSizeVi: numberFormat(x),
        })),
      },
    ],
    []
  );

  const getPropertyType = async () => {
    const result = await AppRepositories.propertyTypeByTransaction(
      props.transactionId || ""
    );

    setPropertyType(result);
  };

  useEffect(() => {
    getPropertyType();
  }, [props.transactionId]);

  const data: SelectItemType[] = [
    {
      label: "propertyType",
      name: "propertyType",
      data: [
        {
          id: "default",
          propertyTypeVi: t("all"),
          propertyTypeEn: t("all"),
        },
        // ...MasterData.properties,
        ...propertyType,
      ],
    },
    {
      label: "bedrooms",
      name: "bedroomTypes",
      data: MasterData.bedroom ? [
        {
          id: "default",
          bedroomTypesVi: t("all"),
          bedroomTypesEn: t("all"),
        },
        ...MasterData.bedroom,
      ] : [
        {
          id: "default",
          bedroomTypesVi: t("all"),
          bedroomTypesEn: t("all"),
        }
      ] ,
    },
    {
      label: "bathrooms",
      name: "bathroomTypes",
      data: MasterData.bathroom ? [
        {
          id: "default",
          bathroomTypesVi: t("all"),
          bathroomTypesEn: t("all"),
        },
        ...MasterData.bathroom,
      ] : [
        {
          id: "default",
          bathroomTypesVi: t("all"),
          bathroomTypesEn: t("all"),
        },
      ],
    },
    {
      label: "commercialRealEstateType",
      name: "propertyType",
      data: [
        {
          id: "default",
          propertyTypeVi: t("all"),
          propertyTypeEn: t("all"),
        },
        // ...MasterData.properties,
        ...propertyType,
      ],
    },
  ];

  const [suggestProperty, setSuggestProperty] =
    useState<SuggestSearchPropertyModel>();
  const [selectedValues, setSelectedValues] = useState<any>({
    keyword: AppModal.searchingPropertyModel.propertyKeyWord
      ? AppModal.searchingPropertyModel.propertyKeyWord
      : "",
    propertyType: AppModal.searchingPropertyModel.propertyTypeId
      ? AppModal.searchingPropertyModel.propertyTypeId
      : data[0].data[0].id,
    bedroomTypes: AppModal.searchingPropertyModel.bedroomId
      ? AppModal.searchingPropertyModel.bedroomId
      : data[1].data[0].id,
    bathroomTypes: AppModal.searchingPropertyModel.bathroomId
      ? AppModal.searchingPropertyModel.bathroomId
      : data[2].data[0].id,
    transactionType: AppModal.searchingPropertyModel.transactionTypeId
      ? AppModal.searchingPropertyModel.transactionTypeId
      : dialogData[0].data[0].id,
    minPrice: AppModal.searchingPropertyModel.minPrice
      ? AppModal.searchingPropertyModel.minPrice.toString()
      : numberData[0].data[0].id,
    maxPrice: AppModal.searchingPropertyModel.maxPrice
      ? AppModal.searchingPropertyModel.maxPrice.toString()
      : numberData[1].data[0].id,
    landSize: AppModal.searchingPropertyModel.landSize
      ? AppModal.searchingPropertyModel.landSize.toString()
      : numberData[2].data[0].id,
    listedSince: AppModal.searchingPropertyModel.listedSince
      ? AppModal.searchingPropertyModel.listedSince
      : "",
  });

  const handleChange = (event: SelectChangeEvent) => {
    selectedValues[event.target.name] = event.target.value;

    setSelectedValues({ ...selectedValues });
  };

  const handleDialogInput = (e: any) => {
    selectedValues.listedSince = e;

    setSelectedValues({ ...selectedValues });
  };

  const onSearchInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    selectedValues.keyword = value;
    setKeywordValue(value);
    AppModal.searchingPropertyModel.propertyKeyWord = value;

    const state = location.state as any;
    let suggestType = undefined;
    if (location.pathname.includes("/commercial")) {
      suggestType = SuggestPropertyTypes.Commercial;
    } else if (
      location.pathname.includes("/property") &&
      state &&
      (state.id === "001" || state.id === "002")
    ) {
      suggestType = SuggestPropertyTypes.NotCommercial;
    }

    const resData = await AppRepositories.getSuggestProperty({
      keyword: selectedValues.keyword,
      suggestPropertyType: suggestType,
    } as SuggestSearchPropertyRequestModel);

    setSuggestProperty(resData);
    setSelectedValues({ ...selectedValues });
  };

  const closeSuggestDropdown = async () => {
    const resData = await AppRepositories.getSuggestProperty({
      keyword: "",
    } as SuggestSearchPropertyRequestModel);
    setSuggestProperty(resData);
  };

  const onSearchProperty = () => {
    AppModal.searchingPropertyModel = {} as SearchingPropertyType;
    AppModal.searchingPropertyModel.propertyKeyWord = selectedValues.keyword;
    AppModal.searchingPropertyModel.listedSince = selectedValues.listedSince;

    setAppModalSearchValue();
    closeSuggestDropdown();
  };

  const chooseAdministrative = (item: SuggestPropertyAdministrativeModel) => {
    AppModal.searchingPropertyModel.administrativeCode =
      item.administrativeCode!;
    AppModal.searchingPropertyModel.propertyKeyWord = item.administrativeName!;
    setKeywordValue(item.administrativeName!);

    setAppModalSearchValue();
    closeSuggestDropdown();
    setOpenDialog(false);
  };

  const setAppModalSearchValue = () => {
    AppModal.searchingPropertyModel.propertyTypeId =
      selectedValues.propertyType !== "default"
        ? selectedValues.propertyType
        : "";
    AppModal.searchingPropertyModel.bedroomId =
      selectedValues.bedroomTypes !== "default"
        ? selectedValues.bedroomTypes
        : "";
    AppModal.searchingPropertyModel.bathroomId =
      selectedValues.bathroomTypes !== "default"
        ? selectedValues.bathroomTypes
        : "";
    AppModal.searchingPropertyModel.transactionTypeId =
      selectedValues.transactionType !== "default"
        ? selectedValues.transactionType
        : "";
    AppModal.searchingPropertyModel.minPrice =
      selectedValues.minPrice !== "0" ? parseInt(selectedValues.minPrice) : 0;
    AppModal.searchingPropertyModel.maxPrice =
      selectedValues.maxPrice !== "0" ? parseInt(selectedValues.maxPrice) : 0;
    AppModal.searchingPropertyModel.landSize =
      selectedValues.landSize !== "0" ? parseInt(selectedValues.landSize) : 0;

    AppModal.searchingPropertyModel.isManyCommercialProperty = false;

    !props.isMappage
      ? navigate("/property")
      : props.onSearch && props.onSearch();
  };

  const resetFilter = () => {
    const defaultSelectedValues = {
      keyword: "",
      propertyType: data[0].data[0].id,
      bedroomTypes: data[1].data[0].id,
      bathroomTypes: data[2].data[0].id,
      transactionType: dialogData[0].data[0].id,
      minPrice: numberData[0].data[0].id,
      maxPrice: numberData[1].data[0].id,
      landSize: numberData[2].data[0].id,
      listedSince: null,
    };
    setKeywordValue("");
    setSelectedValues(defaultSelectedValues);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      onSearchProperty();
    }
  };

  const onNavigate = async (propertyId: string) => {
    const data = await AppRepositories.getPropertyById(propertyId || "");
    navigate("/property/" + data.slug);
  };

  const SuggestPropertyDropdown = () => {
    return suggestProperty?.suggestAdministrative != null &&
      suggestProperty?.suggestProperty != null ? (
      <div className="suggest-dropdown" id="suggest-dropdown">
        <div className="suggest-dropdown-section">
          <div className="dFlex itemsCenter">
            <div className="suggest-dropdown-section-icon mr12">
              <img src={MapFlagIcon} alt="" />
            </div>
            <CustomText className="semiBold">{t("location")}</CustomText>
          </div>
          <div className="suggest-dropdown-items">
            {suggestProperty?.suggestAdministrative &&
              suggestProperty?.suggestAdministrative.map(
                (item: SuggestPropertyAdministrativeModel, index: number) => (
                  <div
                    key={index}
                    className="suggest-dropdown-item"
                    onClick={() => chooseAdministrative(item)}
                  >
                    <CustomText>
                      {currentLanguage.isEN
                        ? item.administrativeNameEn
                        : item.administrativeName}
                    </CustomText>
                  </div>
                )
              )}
          </div>
        </div>
        <div className="suggest-dropdown-section">
          <div className="dFlex itemsCenter">
            <div className="suggest-dropdown-section-icon mr12">
              <img src={BuidlingIcon} alt="" />
            </div>
            <CustomText className="semiBold">{t("listings")}</CustomText>
          </div>
          <div className="suggest-dropdown-items">
            {suggestProperty?.suggestProperty &&
              suggestProperty?.suggestProperty.map(
                (item: SuggestPropertyModel, index: number) => (
                  <div
                    key={index}
                    className="suggest-dropdown-item"
                    onClick={() => onNavigate(item.propertyId || "")}
                  >
                    <CustomText>
                      {item.propertyNumber} - {item.address}
                    </CustomText>
                  </div>
                )
              )}
          </div>
        </div>
        <div
          className="suggest-dropdow-overplay"
          onClick={closeSuggestDropdown}
        ></div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <Container className="search-bar-container">
      <div className="search-bar">
        <div className="search-box">
          <CustomTextField
            iconStart={<SearchOutlined />}
            label=""
            inputProps={{
              onChange: onSearchInput,
              placeholder: t("searchCityEx"),
              value: keywordValue,
              onKeyDown: handleKeyDown,
            }}
          />
          {!openDialog && <SuggestPropertyDropdown />}
        </div>
        <div className="dDesktop">
          {data.map(
            (item, index) =>
              (props.index !== 3 ? index < 3 : index === 3) && (
                <div key={index} className="select-box-group search-bar-item">
                  <div className="select-box">
                    <CustomText className="select-label">
                      {t(item.label)}
                    </CustomText>
                    <Select
                      className="select-box-item"
                      value={selectedValues[item.name]}
                      name={item.name}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {/* <div className="list-item"> */}
                      {item.data.map((value, valueIndex) => (
                        <MenuItem  key={valueIndex} value={value.id}>
                          {
                            value[
                              `${item.name}${
                                currentLanguage.isEN ? "En" : "Vi"
                              }`
                            ]
                          }
                        </MenuItem>
                      ))}
                      {/* </div> */}
                    
                    </Select>
                  </div>
                </div>
              )
          )}
        </div>
        <div className="select-button-group search-bar-item">
          <Button variant="outlined" onClick={onSearchProperty}>
            {t("search")}
          </Button>
          <Filters
            className="filter-icon"
            onClick={() => setOpenDialog(true)}
          />
          <Button
            className="filter-btn"
            variant="outlined"
            onClick={() => setOpenDialog(true)}
          >
            {t("filter")}
          </Button>
        </div>
      </div>
      <div className="search-bar-mobile" onClick={() => setOpenDialog(true)}>
        <Search />
      </div>
      <DialogComponent
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        className="search-bar-dialog"
        containerClassName={
          props.index !== 3 ? "search-bar-mobile-dialog" : "commercial-dialog"
        }
      >
        <div className="pRelative">
          <CustomTextField
            className="search-input"
            label=""
            iconStart={<Search />}
            inputProps={{
              onChange: onSearchInput,
              placeholder: t("citiProperty"),
              value: keywordValue,
              onKeyDown: handleKeyDown,
            }}
          />
          {openDialog && <SuggestPropertyDropdown />}
        </div>
        <CustomText className="title">{t("searchFilter")}</CustomText>
        <Grid container spacing={2}>
          {data.map(
            (item, index) =>
              (props.index !== 3 ? index < 3 : index === 3) && (
                <Grid
                  item
                  key={index}
                  className="filter-item"
                  xs={12}
                  md={6}
                  lg={props.index === 3 ? 4 : 3}
                >
                  <CustomSelect
                    label={t(item.label)}
                    onChange={handleChange}
                    name={item.name}
                    value={selectedValues[item.name]}
                    data={item.data}
                  />
                </Grid>
              )
          )}
          {dialogData.map((item, index) => {
            const isDatePicker = index === dialogData.length - 1;

            return (
              <Grid
                item
                key={index}
                className="filter-item"
                xs={12}
                md={6}
                lg={props.index === 3 ? 4 : 3}
              >
                <CustomSelect
                  label={t(item.label)}
                  isDatePicker={isDatePicker}
                  onChange={isDatePicker ? handleDialogInput : handleChange}
                  name={item.name}
                  value={selectedValues[item.name]}
                  data={item.data}
                />
              </Grid>
            );
          })}
          {numberData.map((item, index) => {
            return (
              <Grid
                item
                key={index}
                className="filter-item"
                xs={12}
                md={6}
                lg={props.index === 3 ? 4 : 3}
              >
                <CustomSelect
                  key={index}
                  label={t(item.label)}
                  onChange={handleChange}
                  name={item.name}
                  value={selectedValues[item.name]}
                  data={item.data}
                />
              </Grid>
            );
          })}
        </Grid>
        <div className="dFlex justifyCenter dialog-search-button mt20">
          <Button
            className="resetFilter"
            variant="outlined"
            onClick={resetFilter}
          >
            {t("resetFilter")}
          </Button>
          <Button
            className="search"
            variant="outlined"
            onClick={() => {
              setOpenDialog(false);
              onSearchProperty();
            }}
          >
            {t("search")}
          </Button>
        </div>
      </DialogComponent>
    </Container>
  );
}

export default SearchBar;
