import StyledVariables from "../helpers/materials/_variables";
import { CustomText } from "./styled-custom";

type CustomCardType = {
  title?: string;
  backgroundColor?: string;
  className?: string;
  containerClassName?: string;
  children: any;
};

function CustomCard(props: CustomCardType) {
  return (
    <div
      className={`custom-card ${props.containerClassName}`}
      style={{
        backgroundColor: props.backgroundColor || StyledVariables.colors.white,
      }}
    >
      {props.title && (
        <CustomText className="custom-card-title">{props.title}</CustomText>
      )}
      <div className={`custom-card-content ${props.className}`}>
        {props.children}
      </div>
    </div>
  );
}

export default CustomCard;
