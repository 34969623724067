import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DevelopScreen from "../components/develop-screen";
import { getToken, getUser } from "../helpers/utils";
import ChangePass from "../views/auth/my-account/change-pass";
import MyAccount from "../views/auth/my-account/my-account";
import ContactUs from "../views/contact-us/contact-us";
import ForgotPasswordScreen from "../views/forgot-password/forgot-password";
import ResetPasswordScreen from "../views/forgot-password/reset-password";
import Home from "../views/home/home";
import NewsScreen from "../views/news/news";
import NewsDetailScreen from "../views/news/news-detail";
import NewsListScreen from "../views/news/news-list";
import ProjectDetailScreen from "../views/project-detail/project-detail";
import ProjectScreen from "../views/project/project";
import PropertyDetaiScreen from "../views/property-detail/property-detail";
import PropertyMapListScreen from "../views/property-map-list/property-map-list";
import SellHome from "../views/sell-home/sell-home";
import VerifyAccountScreen from "../views/verify-account/verify-account";

function AppRoutes() {
  const accessToken = getToken();
  useEffect(() => {
    const user = getUser();
    return user;
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="property" element={<PropertyMapListScreen />} />
        <Route path="property/:slug" element={<PropertyDetaiScreen />} />
        <Route path="commercial" element={<PropertyMapListScreen />} />
        <Route path="commercial/:slug" element={<PropertyDetaiScreen />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="develop" element={<DevelopScreen />} />
        <Route path="news" element={<NewsScreen />} />
        <Route path="news/news-list" element={<NewsListScreen />} />
        <Route path="news/:slug" element={<NewsDetailScreen />} />
        <Route path="project/:slug" element={<ProjectDetailScreen />} />
        <Route path="project" element={<ProjectScreen />} />

        <Route path="" element={<ChangePass />} />

        {!accessToken ? (
          <>
            <Route path="verify-account" element={<VerifyAccountScreen />} />
            <Route path="forgot-password" element={<ForgotPasswordScreen />} />
            <Route path="reset-password" element={<ResetPasswordScreen />} />
          </>
        ) : (
          <>
            <Route path="my-account" element={<MyAccount />} />
            <Route path="sell-home" element={<SellHome />} />
            <Route path="sell-commercial" element={<SellHome />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
