import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NewsRepositories from "./news-controllers/news-list-repositories";
import { appLoading, currentLanguage } from "../../helpers/utils";
import { CustomText } from "../../components/styled-custom";
import MultiSlides from "../../components/multi-slides";
import { ArrowForwardIos } from "@mui/icons-material";

function NextNewsScreen(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const { slug } = useParams();
  const local = location?.state as any;
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [isload, setLoad] = React.useState<boolean>(false);
  const [resData, setMasterData] = useState<any>({});
  const [dataNextNews, setDataNextnews] = useState<any>({});

  const [resDataRelated, setMasterDataRelated] = useState<any>({});

  async function getDatasNextNews() {
    appLoading.show();
    const resData = await NewsRepositories.getNewsDetailBySlug(slug);
    const dataNextNews = await NewsRepositories.getNextNews(resData.id || "");
    setLoad(true);
    setDataNextnews(dataNextNews);
    appLoading.dismiss();
  }

  useEffect(() => {
    getDatasNextNews();
    setMasterDataRelated(props.resDataRelated);
  }, [local?.id, props.resDataRelated, slug]);

  const onNavigate = (slug: string) => {
    navigate("/news/" + slug);
    window.scrollTo(0, 0);
  };
  return dataNextNews && dataNextNews.id  !=="00000000-0000-0000-0000-000000000000" ? (
    <div className="news-detail-container w100">
      <div className="next-news" onClick={() => onNavigate(dataNextNews.slug)}>
        <div className="txt-nextnews">
          {currentLanguage.isEN ? dataNextNews.titleEn : dataNextNews.titleVi}
        </div>
        <div className="mb16">
          <CustomText>
            {currentLanguage.isEN
              ? dataNextNews.descriptionsEn
              : dataNextNews.descriptionsVi}
          </CustomText>
        </div>
        <CustomText className="txt-readmore">
          {t("readmore")} <span className="ml12">{">>"} </span>
        </CustomText>
        <img
          className="img-nextnews"
          src={dataNextNews.imagePathUrl}
          alt="image next news"
        />
      </div>
    </div>
  ) : (
    <></>
  );
}

export default NextNewsScreen;
