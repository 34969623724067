type SellHomeModal = {
  propertyType: string;
  transactionType: string;
  photoFileList: File[];
  avatarFile: File | null;
  avatarUrl: string;
  photoUrlList: any[];
  addVideoLink: string;
  addVirtualVideoLink: string;
  proviceCity: string;
  district: string;
  ward: string;
  project: string;
  street: string;
  addressShowVI: string;
  addressShowEN: string;
  title: string;
  descriptions: string;
  yearCompleted: Date;
  view: string[];
  currency: string;
  bedroomTypes: string;
  bathroomTypes: string;
  amenitiesNearby: string[];
  acreageLotSize: string;
  price: string;
  floorNumber: string;
  totalFloorInBuilding: string;
  longitude: string;
  latitude: string;
  isSubmit: boolean;
  isCommercial: boolean;
};

const defaultSellHomeModal: any = {
  propertyType: "",
  transactionType: "",
  photoFileList: [],
  avatarFile: null,
  avatarUrl: "",
  photoUrlList: [],
  addVideoLink: "",
  addVirtualVideoLink: "",
  proviceCity: "",
  district: "",
  ward: "",
  project: "",
  street: "",
  addressShowVI: "",
  addressShowEN: "",
  title: "",
  descriptions: "",
  yearCompleted: null,
  view: [],
  currency: "002",
  bedroomTypes: "",
  bathroomTypes: "",
  amenitiesNearby: [],
  acreageLotSize: "",
  price: "",
  floorNumber: "",
  totalFloorInBuilding: "",
  longitude: "",
  latitude: "",
  isSubmit: false,
  isCommercial: false
};

let sellHomeModal: any = { ...defaultSellHomeModal };

function initExistedSellHomeModal(initialData: any) {
  if (initialData) {
    sellHomeModal = { ...sellHomeModal, ...initialData };

      sellHomeModal.propertyType = initialData.propertyTypeId;
      sellHomeModal.transactionType = initialData.transactionTypeId;
      sellHomeModal.photoFileList = [];
      sellHomeModal.avatarFile = null
      // sellHomeModal.photoUrlList = initialData.photoUrlList;
      sellHomeModal.addVideoLink = initialData.videoLink;
      sellHomeModal.addVirtualVideoLink = initialData.virtualVideoLink;
      sellHomeModal.proviceCity = initialData.provinceCode;
      sellHomeModal.district = initialData.districtCode;
      sellHomeModal.ward = initialData.wardCode;
      sellHomeModal.project = initialData.projectId;
      sellHomeModal.street = initialData.street;
      sellHomeModal.addressShowVI = initialData.propertyAddressVi;
      sellHomeModal.addressShowEN = initialData.propertyAddressEn;
      sellHomeModal.title = initialData.title;
      sellHomeModal.descriptions = initialData.descriptions;
      sellHomeModal.yearCompleted = initialData.yearCompleted;
      sellHomeModal.view = initialData.propertyViews;
      sellHomeModal.currency = initialData.currencyId;
      sellHomeModal.bedroomTypes = initialData.bedroomId;
      sellHomeModal.bathroomTypes = initialData.bathroomId;
      sellHomeModal.amenitiesNearby = initialData.propertyAmenitiesNearbys;
      sellHomeModal.acreageLotSize = initialData.lotSize;
      sellHomeModal.price = initialData.price;
      sellHomeModal.floorNumber = initialData.floorsNumber;
      sellHomeModal.totalFloorInBuilding = initialData.totalBuildingFloors;
      sellHomeModal.longitude = initialData.longitude;
      sellHomeModal.latitude = initialData.latitude;
      sellHomeModal.isSubmit = initialData.isSubmit;
      sellHomeModal.isCommercial = initialData.isCommercial;
  }

  const sellHomeModal2 = sellHomeModal;
}

function resetSellHomeModal() {
  sellHomeModal = { ...defaultSellHomeModal };
}

const notRequiredField: string[] = [
  "addVideoLink",
  "addVirtualVideoLink",
  "ward",
  "street",
  "project",
  "addressShowEN",
  "floorNumber",
  "totalFloorInBuilding",
  "amenitiesNearby",
];

export {
  sellHomeModal,
  notRequiredField,
  resetSellHomeModal,
  initExistedSellHomeModal,
};
export type { SellHomeModal };
