import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderBar from "../../components/header-bar";
import FooterComponent from "../../components/footer-component";
import AppBreadcrumbs from "../../components/app-breadcrumbs";
import { useEffect, useState } from "react";
import NewsRepositories from "./news-controllers/news-list-repositories";
import { appLoading, currentLanguage } from "../../helpers/utils";
import { CustomText } from "../../components/styled-custom";
import CurrencyFormat from "react-currency-format";
import PropertyDetailSeller from "../property-detail/property-detail-seller";
import MultiSlides from "../../components/multi-slides";

function NewsRelatedScreen(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const local = location?.state as any;
  const [page, setPage] = React.useState(1);
  const [isload, setLoad] = React.useState<boolean>(false);
  const [resData, setMasterData] = useState<any>({});
  const [dataRelateds, setMasterDataRelateds] = useState<any>({});

  const [resDataRelated, setMasterDataRelated] = useState<any>({});

  async function getDatasRelated() {
    // appLoading.show();
    let pagingModel = {
      pageNumber: 1,
      pageSize: 12,
    };
    let id = props.resDataRelated?.categoryId;
    const dataRelateds = await NewsRepositories.getRelatedNews(id, pagingModel);
    setLoad(true);
    setMasterDataRelateds(dataRelateds);
    appLoading.dismiss();
  }

  useEffect(() => {
    getDatasRelated();
    setMasterDataRelated(props.resDataRelated);
  }, [local?.id, props.resDataRelated]);

  const onNavigate = (slug: string) => {
    navigate("/news/" + slug);
    window.scrollTo(0, 0);
  };

  return dataRelateds ? (
    <div className="news-detail-container w100 ">
      <div className="card-item">
        <CardContent>
          <CustomText className="fontMP semiBold">
            {t("relatedPosts")}
          </CustomText>
          <div className="luxury-slides-container">
            {dataRelateds?.items && (
              <MultiSlides
                showDot
                responsive={{
                  desktop: 3,
                  tabletL: 3,
                  tabletM: 2,
                  mobile: 1,
                }}
              >
                {dataRelateds?.items.map((item: any, index: number) => (
                  <div key={index} className="handle-hover">
                    <div onClick={() => onNavigate(item.slug)}
                      // to={"/news/" + item.id}
                      // onClick={() => chooseProperty(item.id || "")}
                      style={{ textDecoration: "none" }}
                    >
                      <Button className="luxury-slides-item">
                        <div className="image-item" style={{ height: 150 }}>
                          <img
                            src={item.imagePathUrl}
                            alt=""
                            draggable={false}
                          />
                        </div>
                        <div className="content-item">
                          <CustomText className="fontM semiBold ">
                            {currentLanguage.isEN ? item.titleEn : item.titleVi}
                          </CustomText>
                        </div>
                      </Button>
                    </div>
                  </div>
                ))}
              </MultiSlides>
            )}
          </div>
        </CardContent>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default NewsRelatedScreen;
