import DevelopBG from "../assets/images/comming-soon.jpeg";
import FooterComponent from "./footer-component";
import HeaderBar from "./header-bar";

function DevelopScreen() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <HeaderBar />
      <div
        style={{
          backgroundImage: `url(${DevelopBG})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "contain",
          height: "100%",
          width: "100%",
        }}
      ></div>
      <FooterComponent />
    </div>
  );
}

export default DevelopScreen;
