import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Tab,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import HeaderBar from "../../components/header-bar";
import FooterComponent from "../../components/footer-component";
import AppBreadcrumbs from "../../components/app-breadcrumbs";
import { useEffect, useState } from "react";
import NewsRepositories from "./news-controllers/news-list-repositories";
import { appLoading, currentLanguage, getSiteDomain } from "../../helpers/utils";
import { CustomText } from "../../components/styled-custom";

import NewsRelatedScreen from "./news-related";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import WellReadScreen from "./wellread-lastest";
import LatestScreen from "./news-latest";
import NextNewsScreen from "./next-news";
import moment from "moment";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { NewsModel } from "../../apis/general-client";
import LazyLoad from "react-lazy-load";

function NewsDetailScreen() {
  const { t } = useTranslation();
  let location = useLocation();
  const { slug } = useParams();
  const domain = getSiteDomain();

  const local = location?.state as any;
  const [page, setPage] = React.useState(1);
  const [isload, setLoad] = React.useState<boolean>(false);
  const [resData, setMasterData] = useState<NewsModel>();
  const [resDataRelateds, setMasterDataRelateds] = useState<any>({});
  const [value, setValue] = React.useState("1");
  const [dataCountViews, setDataCountViews] = useState<any>({});

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  async function getDatasDetail() {
    appLoading.show();
    const resData = await NewsRepositories.getNewsDetailBySlug(slug);
    setMasterData(resData);
    if (resData) {
      const dataCountView = await NewsRepositories.viewCountNews(resData.id || "");
      setDataCountViews(dataCountView);
    }
    setLoad(true);
    appLoading.dismiss();
  }

  async function getDatasRelated() {
    appLoading.show();
    let pagingModel = {
      pageNumber: page,
      pageSize: 3,
    };
    let id = resData?.categoryId;
    const resDataRelateds = await NewsRepositories.getRelatedNews(
      id,
      pagingModel
    );
    setLoad(true);
    setMasterDataRelateds(resDataRelateds);
    appLoading.dismiss();
  }

  useEffect(() => {
    getDatasDetail();
    getDatasRelated();
    setDataCountViews(dataCountViews);
    // getCountViews();
  }, [slug]);

  return resData ? (
    <div className="news-detail-container w100">
      {local?.index !== 4 ? <HeaderBar /> : <HeaderBar isCommercial />}
      <Container>
        <div className="breadcrumb-detail">
          <AppBreadcrumbs
            detailTitle={
              currentLanguage.isEN ? resData.titleEn : resData.titleVi
            }
          />
        </div>
        <div className="property-content">
          <div className="section">
            <CustomText className="txt-des-detail">
              {currentLanguage.isEN ? resData.titleEn : resData.titleVi}{" "}
            </CustomText>
          </div>
          <div className="section ">
            <Grid container spacing={12}>
              <Grid item xs={12} md={12} lg={8.5}>
                <div className="date-views">
                  <CustomText className="txt-date mr12">
                    {moment(resData.createTime).format("DD/MM/YYYY")}
                  </CustomText>

                  {resData.viewCount && (
                    <CustomText>
                      {resData.viewCount} {t("views")}
                    </CustomText>
                  )}
                </div>
                <div className="share-new-detail">
                  <CustomText className="txt-share"> {t("share")} </CustomText>
                  <div className="share">
                    <FacebookShareButton
                      url={
                        domain + "/news/" +
                        resData.slug
                      }
                      className="Demo__some-network__share-button"
                    >
                      <Facebook />
                    </FacebookShareButton>

                    <TwitterShareButton
                      // title={"test"}
                      url={
                        domain + "/news/" +
                        resData.slug
                      }
                      hashtags={["vietnamhome"]}
                    >
                      <Twitter />
                    </TwitterShareButton>
                  </div>
                </div>
                <div className="news-detail">
                  {/* Description */}
                  <div className="fontM property-descriptions">
                    {currentLanguage.isEN
                      ? resData.descriptionsEn
                      : resData.descriptionsVi}
                  </div>
                  {/* contentEn */}
                  {currentLanguage.isEN ? (
                    <div
                      className="fontM property-descriptions"
                      dangerouslySetInnerHTML={{
                        __html: (resData && resData.contentEn) || "",
                      }}
                    ></div>
                  ) : (
                    <div
                      className="fontM property-descriptions"
                      dangerouslySetInnerHTML={{
                        __html: (resData && resData.contentVi) || "",
                      }}
                    ></div>
                  )}
                </div>
                <hr className="mb20 line-news" />
                {/* Similar Home */}
                <LazyLoad offset={1}>
                <NewsRelatedScreen resDataRelated={resData} />
                </LazyLoad>
              </Grid>
              <Grid item xs={12} md={12} lg={3.5}>
                <NextNewsScreen />
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        centered
                        aria-label="lab API tabs example"
                      >
                        {currentLanguage.isEN ? (
                          <Tab label="Well-read" value="1" />
                        ) : (
                          <Tab label="Đọc Nhiều" value="1" />
                        )}
                        {currentLanguage.isEN ? (
                          <Tab label="Latest" value="2" />
                        ) : (
                          <Tab label="Mới Nhất" value="2" />
                        )}
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <WellReadScreen />
                    </TabPanel>
                    <TabPanel value="2">
                      <LatestScreen />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
      <FooterComponent />
    </div>
  ) : (
    <></>
  );
}

export default NewsDetailScreen;
