import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import {
  currentLanguage,
  formatLotSize,
  formatNumber,
  getPrice,
  getToken,
  getUser,
} from "../../../helpers/utils";
import { AppRepositories } from "../../../apis/app-repositories";
import { CreatePropertyHeartModel } from "../../../apis/general-client";
import { ReactComponent as Heart } from "../../../assets/icons/heart-2.svg";
import { ReactComponent as HeartActive } from "../../../assets/icons/Heart.svg";
import StyledVariables from "../../../helpers/materials/_variables";

function PropertyListScreen(props: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [dataFilter, setDataFilter] = useState<any>({});
  const [page, setPage] = React.useState(1);
  const [masterData, setMasterData] = useState<any>({});
  const [hearData, setHearData] = useState<any>({});

  useEffect(() => {
    async function getMasterData() {
      setMasterData(props.masterData);
    }

    async function getDatas() {
      await getMasterData();
      await getIsPropertyHeart();
      setHearData(masterData);
    }

    getDatas();
  }, [page, props.changePage, props.masterData]);

  // paginate
  const handleChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    props.changePage(value);
    window.scrollTo(0, 0);
  };

  const onNavigate = (transactionTypeId?: string | undefined, slug?: string) => {
    if (transactionTypeId === "004" || transactionTypeId === "005") {
      navigate("/commercial/" + slug, { state: { index: 3 } });
    } else {
      navigate("/property/" + slug, { state: { id: transactionTypeId } });
    }
  };
  
  async function getIsPropertyHeart() {
    const token = getToken() != null;
    if (token) {
      const user = getUser();
      if (props.masterData.items && props.masterData.items.length > 0) {
        const propertyIds = props.masterData.items.map((x: any) => x.id);
        const res = await AppRepositories.isManyPropertyHeart(
          user.id,
          propertyIds
        );
        res.forEach((x, index) => {
          props.masterData.items[index]["isHeart"] = x;
        });
      }
    }
  }

  const sendPropertyHeart = async (item: any, index: number) => {
    const token = getToken() != null;
    if (token) {
      const user = getUser();
      const model = {
        propertyId: item.id,
        userId: user.id,
      } as CreatePropertyHeartModel;

      const res = await AppRepositories.sendPropertyHeart(model);
      if (res.succeeded) {
        const isHeart = await AppRepositories.isPropertyHeart(model);
        let temp = { ...masterData };
        temp.items[index]["isHeart"] = isHeart;
        setMasterData(temp);
      }
    } else {
      props.setSignInModel(true);
    }
  };

  return (
    <div>
      {masterData?.items && (
        <Container className="container">
          <div className="gridProperty">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 4, md: 4 }}
            >
              {masterData.items.map((item: any, index: number) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <div className="borderNone">
                    <Card className="cardProperty property-item">
                      <CardMedia
                        component="img"
                        height="237"
                        image={item.coverImageUrl}
                        alt="Image"
                        onClick={() => onNavigate(item.transactionTypeId, item.slug)}
                      />
                      <CardContent>
                        <div className="titleProperty">
                          <span
                            className="txtProperty"
                            onClick={() => onNavigate(item.transactionTypeId, item.slug)}
                          >
                            {item.title}
                          </span>
                          <div>
                            <Button
                              onClick={() => {
                                sendPropertyHeart(item, index);
                              }}
                            >
                              {item["isHeart"] ? (
                                <HeartActive
                                  className="iconHear-1"
                                  fill={StyledVariables.colors.red400}
                                />
                              ) : (
                                <Heart
                                  className="iconHear-1"
                                  fill={StyledVariables.colors.red400}
                                />
                              )}
                            </Button>
                          </div>
                        </div>
                        <div onClick={() => onNavigate(item.transactionTypeId, item.slug)}>
                          <div className="txtType">
                            {currentLanguage.isEN
                              ? item.propertyTypeEn
                              : item.propertyTypeVi}
                          </div>
                          <div className="txtAddress">
                            {currentLanguage.isEN
                              ? item.propertyAddressEn
                              : item.propertyAddressVi}
                            {/* {currentLanguage.isEN ? item.districtNameEn : item.districtName}, {currentLanguage.isEN ? item.provinceNameEn : item.provinceName} */}
                          </div>
                          <div className="txtPrice">
                            <span>
                              {getPrice(
                                item.usdPrice!,
                                item.currencyNotation!,
                                item.price!
                              )}
                            </span>
                            {(item.transactionTypeId === "001" || item.transactionTypeId === "004") ? (
                              <span> / {t("daily")}</span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="txtQuantity">
                            <span className="txtLot">{formatLotSize(item.lotSizeFeet || 0)} ft2 </span>
                            {props.index !== 3 &&
                              (item.transactionTypeId !== "004" && item.transactionTypeId !== "005") && (
                                <span>{item.bedroomEn + " " + t("beds")}</span>
                              )}
                            {props.index !== 3 &&
                              (item.transactionTypeId !== "004" && item.transactionTypeId !== "005") && (
                                <span className="txtBath">
                                  {item.bathroomEn + " " + t("baths")}
                                </span>
                              )}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
          <div className="btnPagination">
            <Stack spacing={2}>
              <Pagination
                showFirstButton
                showLastButton
                count={masterData.totalPages}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </Container>
      )}
    </div>
  );
}

export default PropertyListScreen;
