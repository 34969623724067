import { Loader } from "@googlemaps/js-api-loader";
import { sellHomeModal } from "../views/sell-home/controllers/sell-home-modal";

const mapConfig = {
  apiKey: process.env.REACT_APP_apiKey ? process.env.REACT_APP_apiKey : "",
  version: process.env.REACT_APP_version,
  mapId: process.env.REACT_APP_mapId,
};

const mapFunctions = {
  mapInstance: {
    map: null as any,
    placesService: null as any,
    markers: [] as any,
  },
  initMap(onPicker: (lat: number, lng: number) => void) {
    let self = this;
    const checkElement = setInterval(() => {
      if (document.getElementById("render-map")) {
        clearInterval(checkElement);
        //load map
        const loader = new Loader({
          apiKey: mapConfig.apiKey,
          version: mapConfig.version,
          libraries: ["places"],
        });

        loader.load().then(() => {
          mapFunctions.mapInstance.map = new google.maps.Map(
            document.getElementById("render-map") as HTMLElement,
            {
              center: { lat: 16.483508761800792, lng: 105.88848826567461 },
              zoom: 5,
              minZoom: 5,
              restriction: {
                latLngBounds: {
                  north: 23.587157297711677,
                  south: 8.418653118028859,
                  east: 109.49200389067461,
                  west: 101.4645587031746,
                },
              },
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              gestureHandling: "greedy",
            }
          );
          navigator.geolocation.getCurrentPosition(
            (position: GeolocationPosition) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              mapFunctions.mapInstance.map.setCenter(pos);
            }
          );

          //add event click to map
          // Configure the click listener.
          mapFunctions.mapInstance.map.addListener(
            "click",
            (mapsMouseEvent: any) => {
              const _lat = mapsMouseEvent.latLng.lat();
              const _lng = mapsMouseEvent.latLng.lng();
              onPicker(_lat, _lng);
              mapFunctions.addMarker({ lat: _lat, lng: _lng }, false);
            }
          );

          window["googleInstance" as any] = mapFunctions.mapInstance as any;
        });
      }
    })


  },
  focusMap(province: string, district: string, ward: string, street: string) {
    let self = this;
    let provinceName = province;
    let districtName = district;
    let wardName = ward;
    let streetName = street;

    let searchString = `${provinceName}, ${districtName}, ${wardName}, ${streetName}`;
    const request = {
      query: searchString,
      fields: ["geometry"],
    };
    this.mapInstance.placesService = new google.maps.places.PlacesService(
      this.mapInstance.map
    );
    this.mapInstance.placesService.findPlaceFromQuery(
      request,
      (results: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          self.mapInstance.map.setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          //set zoom
          self.mapInstance.map.zoom = 15;
        }
      }
    );
  },
  addMarker(
    position: google.maps.LatLng | google.maps.LatLngLiteral,
    zoom: boolean
  ) {
    let mapInstance = window["googleInstance" as any] as any;
    mapFunctions.removeAllMarker();
    if (zoom) {
      mapInstance.map.setZoom(15);
      mapInstance.map.setCenter({
        lat: position.lat,
        lng: position.lng,
      });
    }
    let map = mapInstance.map;
    const marker = new google.maps.Marker({
      position,
      map,
    });

    mapInstance.markers.push(marker);
  },
  removeAllMarker() {
    let mapInstance = window["googleInstance" as any] as any;
    for (let i = 0; i < mapInstance.markers.length; i++) {
      mapInstance.markers[i].setMap(null);
    }
  },
};

export default mapFunctions;
