import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTextField from "../../../components/custom-text-field";
import { CustomText } from "../../../components/styled-custom";
import { AuthRepositories } from "../auth-controllers/auth-repositories";
import Logo from "../../../assets/images/Logo-Black-White-with-text.png";
import { appLoading, allCases } from "../../../helpers/utils";
import validator from "validator";
import { useLocation, useNavigate } from "react-router-dom";

type LoginScreenType = {
  goToSignUp: () => void;
  onClose: () => void;
};

function LoginScreen(props: LoginScreenType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [wrongAccount, setWrongAccount] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [emailErr, setEmailErr] = useState<string>("");
  const [password, setPassword] = useState<string>();
  const [passErr, setPassErr] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  let location = useLocation();

  const onRememberMe = (e: boolean) => {
    setRememberMe(e);
  };

  const onEmailInput = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailErr("");
    setEmail(e.target.value);
  };

  const onPasswordInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPassErr("");
    setPassword(e.target.value);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (email && password) {
      if (password.length < 6) {
        setPassErr("passMoreThanSixCharacter");
      }
      // else if (!allCases(password)) {
      //   setPassErr("passAllCase");
      // }
      else if (!validator.isEmail(email)) {
        setEmailErr("emailInvalidate");
      } else {
        appLoading.show();
        const res = await AuthRepositories.login(email, password, rememberMe);
        if (res) {
          navigate("/", { replace: true });
          window.location.reload();
        } else {
          setWrongAccount(true);
        }
        appLoading.dismiss();
      }
    } else {
      !email && setEmailErr("requiredField");
      !password && setPassErr("requiredField");
    }
  };

  function goToForgotPassword() {
    if (location.pathname == "/forgot-password") {
      props.onClose();
    } else {
      navigate("/forgot-password");
    }
  }

  return (
    <div className="login-screen">
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <img className="logo-left" src={Logo} alt="" />
        </Grid>
        <Grid item lg={6} xs={12}>
          <div className="login-form-container">
            <div className="auth-title">
              <CustomText>{t("signIn")}</CustomText>
            </div>
            <form onSubmit={onSubmit}>
              <div className="login-content">
                <div className="login-form">
                  <CustomTextField
                    label=""
                    className="auth-text-field"
                    containerClassName="mb32"
                    inputProps={{
                      placeholder: t("email"),
                      error: emailErr.length > 0,
                    }}
                    helperText={t(emailErr)}
                    onChange={onEmailInput}
                  />
                  <CustomTextField
                    label=""
                    className="auth-text-field"
                    inputProps={{
                      placeholder: t("password"),
                      error: passErr.length > 0,
                    }}
                    helperText={t(passErr)}
                    onChange={onPasswordInput}
                    type="password"
                  />
                  <FormControlLabel
                    className="remember-me"
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={(e) => onRememberMe(e.target.checked)}
                      />
                    }
                    label={t("rememberMe")}
                  />
                  <CustomText
                    className="wrong-account"
                    style={{ visibility: wrongAccount ? "visible" : "hidden" }}
                  >
                    {t("wrongAccount")}
                  </CustomText>
                  <CustomText
                    className="forgot-pass"
                    onClick={goToForgotPassword}
                  >
                    {t("forgotPass")}
                  </CustomText>
                  <Button className="submit-btn" type="submit">
                    {t("next")}
                  </Button>
                  <div className="not-have-account">
                    <CustomText>{t("notHaveAccount")}</CustomText>
                    <CustomText
                      className="sign-up-btn"
                      onClick={props.goToSignUp}
                    >
                      {t("signUp")}
                    </CustomText>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginScreen;
