import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { memo, useMemo, useRef, useState } from "react";
import SlideShow, { ResponsiveType } from "react-multi-carousel";

type MultiSlidesType = {
  children: any;
  className?: string;
  responsive: {
    desktop: number;
    tabletM: number;
    tabletL: number;
    mobile: number;
  };
  showDot?: boolean;
  showNavigation?: boolean;
  dotSize?: number;
  dotListClass?: string;
  imageData?: any;
};

function MultiSlides(props: MultiSlidesType) {
  const slideRef = useRef<SlideShow>(null);
  const [prevDisabled, setPrevDisabled] = useState<boolean>(true);
  const [nextDisabled, setNextDisabled] = useState<boolean>(false);

  const slidesResponsive: ResponsiveType = useMemo(
    () => ({
      superLargeDesktop: {
        breakpoint: {
          max: 6000,
          min: 3000,
        },
        items: props.responsive.desktop,
        slidesToSlide: props.responsive.desktop,
        partialVisibilityGutter: 40,
      },
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1200,
        },
        items: props.responsive.desktop,
        slidesToSlide: props.responsive.desktop,
        partialVisibilityGutter: 40,
      },
      tabletL: {
        breakpoint: {
          max: 1200,
          min: 1024,
        },
        items: props.responsive.tabletL,
        slidesToSlide: props.responsive.tabletL,
        partialVisibilityGutter: 30,
      },
      tabletM: {
        breakpoint: {
          max: 1024,
          min: 768,
        },
        items: props.responsive.tabletM,
        slidesToSlide: props.responsive.tabletM,
        partialVisibilityGutter: 30,
      },
      mobile: {
        breakpoint: {
          max: 768,
          min: 0,
        },
        items: props.responsive.mobile,
        slidesToSlide: props.responsive.mobile,
        partialVisibilityGutter: 30,
      },
    }),
    [
      props.responsive.desktop,
      props.responsive.mobile,
      props.responsive.tabletL,
      props.responsive.tabletM,
    ]
  );

  const onArrowClick = (e: React.MouseEvent) => {
    const isPrev = e.currentTarget.className.includes("prev");
    const currentSlideIndex = slideRef.current?.state.currentSlide!;
    const slideToShow = slideRef.current?.state.slidesToShow!;
    const slideDataLength = slideRef.current?.state.totalItems!;
    const prevIndex = currentSlideIndex - slideToShow;
    const nextIndex = currentSlideIndex + slideToShow;
    const maxIndex = slideDataLength - slideToShow;
    const _slideIndex = isPrev
      ? prevIndex < 0
        ? 0
        : prevIndex
      : nextIndex > maxIndex
      ? maxIndex
      : currentSlideIndex + slideToShow;
    slideToShow < slideDataLength &&
      slideRef.current?.goToSlide(_slideIndex, true);
    setPrevDisabled(_slideIndex === 0 || slideDataLength <= slideToShow);
    setNextDisabled(
      _slideIndex === maxIndex || slideDataLength === slideToShow
    );
  };

  const onBeforeSlideChange = (index: number) => {
    const slideToShow = slideRef.current?.state.slidesToShow!;
    const slideDataLength = slideRef.current?.state.totalItems!;
    setPrevDisabled(index === 0);
    setNextDisabled(index === slideDataLength - slideToShow);
  };

  return (
    <div className={`multi-slides-container ${props.className || ""}`}>
      {(slideRef.current?.state.totalItems! > 4 || props.showNavigation || props.imageData?.images?.length > 4) && (
        <Button
          className="slide-button prev"
          disabled={prevDisabled}
          onClick={onArrowClick}
        >
          <ArrowBack />
        </Button>
      )}

      <SlideShow
        containerClass="luxury-slides"
        ssr
        ref={slideRef}
        responsive={slidesResponsive}
        additionalTransfrom={0}
        arrows={false}
        centerMode={false}
        draggable
        focusOnSelect={false}
        infinite={false}
        keyBoardControl
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        showDots={props.showDot}
        autoPlay={false}
        shouldResetAutoplay={false}
        swipeable={true}
        beforeChange={onBeforeSlideChange}
        children={props.children}
        dotListClass={props.dotListClass}
        minimumTouchDrag={50}
      />
      {(slideRef.current?.state.totalItems! > 4 || props.showNavigation || props.imageData?.images?.length > 4) && (
        <Button
          className="slide-button next"
          disabled={nextDisabled}
          onClick={onArrowClick}
        >
          <ArrowForward />
        </Button>
      )}
    </div>
  );
}

export default memo(MultiSlides);
