import { SearchOutlined } from "@mui/icons-material";
import {
  Button,
  Container,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect, { SelectItemType } from "../../components/custom-select";
import MasterData from "../../apis/master-data";
import { CustomText } from "../../components/styled-custom";
import { currentLanguage } from "../../helpers/utils";
import CustomTextField from "../../components/custom-text-field";
import ProjectModal, {
  SearchProjectModel,
} from "./project-controllers/project-modal";
import { SuggestSearchProjectModel } from "../../apis/general-client";
import ProjectRepositories from "./project-controllers/project-repositories";
import ProductDetailRepositories from "../project-detail/project-detail-controller/project-detail-repositories";

type SearchBarType = {
  isMappage?: boolean;
  onSearch?: () => void;
  locationData: any;
  projectData: any;
};

function SearchBarProject(props: SearchBarType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [keywordValue, setKeywordValue] = useState<string>("");

  const [location, setLocation] = useState<any>({});
  const [project, setProject] = useState<any>({});
  const [sortModelState, setSortModelState] = useState<{
    openForSale?: boolean;
    commingSoon?: boolean;
    almostSoldOut?: boolean;
  }>({
    openForSale: false,
    commingSoon: false,
    almostSoldOut: false,
  });

  useEffect(() => {
    getDatas();

    ProjectModal.searchingProjectModel.projectName = "";
    ProjectModal.searchingProjectModel.location = "";
    ProjectModal.searchingProjectModel.propertyTypeId = "";
    ProjectModal.searchingProjectModel.status = undefined;
    
  }, [props.locationData, props.projectData]);

  async function getDatas() {
    setLocation(props.locationData);
    setProject(props.projectData);
  }

  const onFilterStatus = async (sort: number) => {
    const sortModelState = {
      openForSale: sort === 1,
      commingSoon: sort === 2,
      almostSoldOut: sort === 3,
    };
    setSortModelState(sortModelState);
  };

  const data: SelectItemType[] = [
    {
      label: "location",
      name: "locationTypes",
      data: [
        {
          code: "default",
          name: t("all"),
          nameWithType: t("all"),
        },
        ...props?.locationData,
      ],
    },
    {
      label: "propertyType",
      name: "propertyType",
      data: MasterData.properties ? [
        {
          id: "default",
          propertyTypeVi: t("all"),
          propertyTypeEn: t("all"),
        },
        ...MasterData.properties,
      ] : [
        {
          id: "default",
          propertyTypeVi: t("all"),
          propertyTypeEn: t("all"),
        },
      ],
    },
    {
      label: "status",
      name: "statusTypes",
      data: [
        {
          code: "default",
          name: t("all"),
          nameWithType: t("all"),
        },
        {
          code: 1,
          name: t("openForSale"),
          nameWithType: "openForSale",
        },
        {
          code: 2,
          name: t("commingSoon"),
          nameWithType: "commingSoon",
        },
        {
          code: 3,
          name: t("almostSoldOut"),
          nameWithType: "almostSoldOut",
        },
      ],
    },
  ];

  const [suggestProject, setSuggestProject] =
    useState<SuggestSearchProjectModel[]>([]);
  const [selectedValues, setSelectedValues] = useState<any>({
    locationTypes: data[0].data[0].code,
    propertyType: data[1].data[0].id,
    statusTypes: data[2].data[0].code,
  });

  const handleChange = (event: SelectChangeEvent) => {
    selectedValues[event.target.name] = event.target.value;

    setSelectedValues({ ...selectedValues });
  };

  const filterProject = (event: SelectChangeEvent) => {
    selectedValues[event.target.name] = event.target.value;

    setSelectedValues({ ...selectedValues });
  };

  const onSearchInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setKeywordValue(value);

    if (keywordValue.length >= 2) {
      const resData = await ProjectRepositories.getSuggestProject(
        keywordValue
      );
      setSuggestProject(resData);
    } else {
      closeSuggestDropdown();
    }

    setSelectedValues({ ...selectedValues });
  };

  const onSearchProject = () => {
    ProjectModal.searchingProjectModel = {} as SearchProjectModel;
    ProjectModal.searchingProjectModel.projectName = keywordValue;
    ProjectModal.searchingProjectModel.location = selectedValues.locationTypes;
    ProjectModal.searchingProjectModel.propertyTypeId = selectedValues.propertyType;
    ProjectModal.searchingProjectModel.status = selectedValues.status;

    setAppModalSearchValue();
  };

  const setAppModalSearchValue = () => {
    ProjectModal.searchingProjectModel.location =
      selectedValues.locationTypes !== "default"
        ? selectedValues.locationTypes
        : "";
    ProjectModal.searchingProjectModel.propertyTypeId =
      selectedValues.propertyType !== "default"
        ? selectedValues.propertyType
        : "";
    ProjectModal.searchingProjectModel.status =
      selectedValues.statusTypes !== "default"
        ? selectedValues.statusTypes
        : "";

    props.onSearch && props.onSearch();
  };

  const closeSuggestDropdown = async () => {
    const resData = await ProjectRepositories.getSuggestProject("");
    setSuggestProject(resData);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onSearchProject();
    }
  }

  const onNavigate = async (projectId: string) => {
    const data = await ProductDetailRepositories.getProductDetail(projectId || "");
    navigate("/project/" + data.slug);
  };

  const SuggestProjectDropdown = () => {
    return suggestProject.length > 0 ? (
      <div className="suggest-dropdown" id="suggest-dropdown">
        <div className="suggest-dropdown-section">
          <div className="dFlex itemsCenter">
            <CustomText className="semiBold">{t("listings")}</CustomText>
          </div>
          <div className="suggest-dropdown-items">
            {suggestProject &&
              suggestProject.map(
                (item: SuggestSearchProjectModel, index: number) => (
                  <div key={index} className="suggest-dropdown-item" onClick={() => onNavigate(item.projectId || "")}>
                    <CustomText>
                      {currentLanguage.isEN ? item.titleEn : item.titleVi}
                    </CustomText>
                  </div>
                )
              )}
          </div>
        </div>
        <div
          className="suggest-dropdow-overplay"
          onClick={closeSuggestDropdown}
        ></div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <Container className="search-bar-container">
      <div className="search-bar">
        <div className="search-box">
          <CustomTextField
            iconStart={<SearchOutlined />}
            label=""
            inputProps={{
              onChange: onSearchInput,
              placeholder: t("searchProject"),
              value: keywordValue,
              onKeyDown: handleKeyDown
            }}
          />
          <SuggestProjectDropdown />
        </div>
        <div className="dDesktop-project">
          {data.map((item, index) => (
            <div key={index} className="select-box-group search-bar-item">
              <div className="select-box">
                <CustomText className="select-label">
                  {t(item.label)}
                </CustomText>
                <Select
                  className="select-box-item"
                  value={selectedValues[item.name]}
                  name={item.name}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {item.data.map((value, valueIndex) => (
                    <MenuItem key={valueIndex} value={index === 1 ? value.id : value.code}>
                      {index !== 1 && value.name}
                      { index === 1 &&
                        value[
                          `${item.name}${
                            currentLanguage.isEN ? "En" : "Vi"
                          }`
                        ]
                      }
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          ))}
        </div>
        <div className="select-button-group search-bar-item">
          <Button variant="outlined" onClick={onSearchProject}>
            {t("search")}
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default SearchBarProject;
