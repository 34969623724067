import { Grid, SelectChangeEvent } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import { useLocation } from "react-router-dom";
import CustomCard from "../../components/custom-card";
import CustomSelect, { SelectItemType } from "../../components/custom-select";
import { CustomText } from "../../components/styled-custom";
import { appLoading } from "../../helpers/utils";
import { notRequiredField, sellHomeModal } from "./controllers/sell-home-modal";
import SellHomeRepositories from "./controllers/sell-home-repositories";

function SellPropertyAddress(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const local = location?.state as any;
  const [items, setItems] = useState<any>({});

  const item = local?.item;
  // initExistedSellHomeModal(item);

  const [value, setValue] = useState<any>({
    // street: "",
    // addressShowVI: "",
    // addressShowEN: "",
    // proviceCity: "default",
    // district: "default",
    // ward: "default",
    // project: "default",

    street: sellHomeModal?.street != null ? sellHomeModal?.street : "",
    addressShowVI:
      sellHomeModal?.propertyAddressVi != null
        ? sellHomeModal?.propertyAddressVi
        : "",
    addressShowEN:
      sellHomeModal?.propertyAddressEn != null
        ? sellHomeModal?.propertyAddressEn
        : "",
    // proviceCity: sellHomeModal?.districtName != null? sellHomeModal?.districtName : "",
    proviceCity:
      sellHomeModal?.provinceCode != null
        ? {
            id: sellHomeModal?.provinceCode,
            proviceCityEn: sellHomeModal?.provinceName,
            proviceCityVi: sellHomeModal?.provinceName,
          }
        : { id: "default", proviceCityEn: "", proviceCityVi: "" },
    district:
      sellHomeModal?.districtCode != null
        ? {
            id: sellHomeModal?.districtCode,
            districtEn: sellHomeModal?.districtName,
            districtVi: sellHomeModal?.districtName,
          }
        : { id: "default", districtEn: "", districtVi: "" },
    // ward:
    //   sellHomeModal?.wardCode != null
    //     ? {
    //         id: sellHomeModal?.wardCode,
    //         wardEn: sellHomeModal?.wardName,
    //         wardVi: sellHomeModal?.wardName,
    //       }
    //     : { id: "default", wardEn: "", wardVi: "" },
    ward: sellHomeModal?.wardCode != null ? sellHomeModal?.wardCode : "default",
    project:
      sellHomeModal?.projectId != null ? sellHomeModal?.projectId : "default",
  });
  const [proviceCity, setProviceCity] = useState<any[]>([]);
  const [district, setDistrict] = useState<any[]>([]);
  const [ward, setWard] = useState<any[]>([
    () => {
      if (sellHomeModal?.wardCode) {
        let ward = sellHomeModal?.wardCode;
        return ward;
      } else {
        return ["default"];
      }
    },
  ]);
  const [project, setProject] = useState<any[]>(() => {
    if (sellHomeModal?.projectId) {
      let project = sellHomeModal?.projectId;
      return project;
    } else {
      return [];
    }
  });

  const data: SelectItemType[] = useMemo(
    () => [
      {
        label: t("proviceCity") + " *",
        placeholder: "chooseProviceCity",
        name: "proviceCity",
        data: [
          {
            id: "default",
            proviceCityVi: t("chooseProviceCity"),
            proviceCityEn: t("chooseProviceCity"),
          },
          ...proviceCity,
        ],
      },
      {
        label: t("district") + " *",
        placeholder: "chooseDistrict",
        name: "district",
        data: [
          {
            id: "default",
            districtVi: t("chooseDistrict"),
            districtEn: t("chooseDistrict"),
          },
          ...district,
        ],
      },
      {
        label: t("communeWard"),
        placeholder: "chooseCommuneWard",
        name: "ward",
        data: [
          {
            id: "default",
            wardVi: t("chooseCommuneWard"),
            wardEn: t("chooseCommuneWard"),
          },
          ...ward,
        ],
      },
      {
        label: t("street"),
        placeholder: "streetEx",
        name: "street",
        data: [],
      },
      {
        label: t("project"),
        placeholder: "chooseProject",
        name: "project",
        data: [
          {
            id: "default",
            projectVi: t("chooseProject"),
            projectEn: t("chooseProject"),
          },
          ...project,
        ],
      },
      {
        label: t("addressShowVI") + " *",
        placeholder: "",
        name: "addressShowVI",
        data: [],
      },
      {
        label: t("addressShowEN"),
        placeholder: "",
        name: "addressShowEN",
        data: [],
      },
    ],
    [t, proviceCity, district, ward]
  );

  useEffect(() => {
    async function getProvinceCity() {
      Promise.all([
        SellHomeRepositories.getProviceCity(),
        SellHomeRepositories.getProject(),
      ]).then((item) => {
        const _proviceCityData = formatSelectData(item[0], "proviceCity");
        const _projectData = item[1].map((item: any, index: number) => ({
          id: item.id,
          projectEn: item.projectEn,
          projectVi: item.projectVi,
        }));
        setProviceCity(_proviceCityData);
        setProject(_projectData);
      });
    }
    getProvinceCity();

    const getValueItem = async () => {
      if (sellHomeModal?.provinceCode) {
        appLoading.show();
        const _district = await SellHomeRepositories.getDistrict(
          sellHomeModal?.provinceCode
        );
        const _districtData = formatSelectData(_district, "district");
        setDistrict(_districtData);
        appLoading.dismiss();

        const _ward = await SellHomeRepositories.getWard(
          sellHomeModal?.districtCode
        );
        const _wardData = formatSelectData(_ward, "ward");
        setWard(_wardData)
        
        getProvinceCity();

      }
    };

    getValueItem();
    setValue(value);
  }, [local?.item]);

  const formatSelectData = (data: any, key: string) => {
    const _data = data.map((item: any, index: number) => ({
      id: item.code,
      [`${key}En`]: item.nameWithType,
      [`${key}Vi`]: item.nameWithType,
    }));
    return _data;
  };

  const getValue = async (e: SelectChangeEvent) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    sellHomeModal[targetName] = targetValue === "default" ? "" : targetValue;
    props.required[targetName] && props.validator(targetName);
    value[e.target.name] = targetValue;
    setValue({ ...value });
    focusMapHandler();
  };

  const getValueAutoComplete = async (e: any, targetName: string) => {
    const targetValue = e.id;
    if (targetName === "proviceCity") {
      appLoading.show();
      const _district = await SellHomeRepositories.getDistrict(targetValue);
      const _districtData = formatSelectData(_district, "district");
      setDistrict(_districtData);
      appLoading.dismiss();
    }
    if (targetName === "district") {
      appLoading.show();
      const _ward = await SellHomeRepositories.getWard(targetValue);
      const _wardData = formatSelectData(_ward, "ward");
      setWard(_wardData);

      appLoading.dismiss();
    }

    sellHomeModal[targetName] = targetValue === "default" ? "" : targetValue;

    // sellHomeModal[targetName] = "default" ?  {id: "default", proviceCityEn: t("chooseDistrict"), proviceCityVi: t("chooseDistrict"),}
    // : {id: sellHomeModal?.targetValue, proviceCityEn: proviceCity.find(v => v.id === targetValue).proviceCityEn, proviceCityVi: proviceCity.find(v => v.id === targetValue).proviceCityVi};

    props.required[targetName] && props.validator(targetName);

    let myOb;
    if (targetName === "proviceCity") {
      myOb = {
        id: targetValue,
        proviceCityEn: proviceCity.find((v) => v.id === targetValue)
          .proviceCityEn,
        proviceCityVi: proviceCity.find((v) => v.id === targetValue)
          .proviceCityVi,
      };
    } else if (targetName === "district") {
      myOb = {
        id: targetValue,
        districtEn: district.find((v) => v.id === targetValue).districtEn,
        districtVi: district.find((v) => v.id === targetValue).districtVi,
      };
    } else if (targetName === "ward") {
      myOb = {
        id: targetValue,
        wardEn: ward.find((v) => v.id === targetValue).wardEn,
        wardVi: ward.find((v) => v.id === targetValue).wardVi,
      };
    }
    value[targetName] = myOb;

    setValue({ ...value });
    focusMapHandler();
  };

  const focusMapHandler = () => {
    let mapInstance = window["googleInstance" as any] as any;
    let provinceCode = value["proviceCity"];
    let districtCode = value["district"];
    let wardCode = value["ward"];
    let streetCode = value["street"];

    if (provinceCode.id !== "default" && districtCode.id != "default") {
      let provinceName = proviceCity.find((e) => {
        return e.id === provinceCode.id;
      }).proviceCityEn;

      let districtName = district.find((e) => {
        return e.id === districtCode.id;
      }).districtEn;

      let wardName = ward.find((e) => {
        return e.id == wardCode;
      });

      if (wardName) {
        wardName = `, ${wardName.wardEn}`;
      } else {
        wardName = "";
      }

      let streetName = value["street"] == "" ? "" : `, ${value["street"]}`;

      let searchString = `${provinceName}, ${districtName}${wardName}${streetName}`;
      const request = {
        query: searchString,
        fields: ["geometry"],
      };

      let placesService = new google.maps.places.PlacesService(mapInstance.map);
      placesService.findPlaceFromQuery(request, (results: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          mapInstance.map.setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          if (streetName != "") {
            mapInstance.map.zoom = 20;
          } else {
            mapInstance.map.zoom = 15;
          }
        }
      });
    }
  };

  return (
    <CustomCard className="sell-property-address" title={t("propertyAddress")}>
      <LazyLoad offset={1}>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid
            key={index}
            item
            md={index === 5 || index === 6 ? 12 : 6}
            xs={12}
          >
            <CustomSelect
              error={
                !notRequiredField.some((e) => e === item.name) &&
                props.required[item.name]
              }
              autoComplete={index < 2}
              isInput={index === 3 || index === 5 || index === 6}
              placeholder={item.placeholder && t(item.placeholder)}
              name={item.name}
              value={value[item.name]}
              label={item.label}
              data={item.data}
              onChange={
                index < 2 ? (e) => getValueAutoComplete(e, item.name) : getValue
              }
            />
            {item.label === "Project" && (
              <CustomText className="mb12"><small>{t('chooseBlankProject')}</small></CustomText>
            )}
          </Grid>
        ))}
      </Grid>
      </LazyLoad>
    </CustomCard>
  );
}

export default SellPropertyAddress;
