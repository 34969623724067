import axiosClient from "../../../apis/axios-client";
import MasterData from "../../../apis/master-data";
import { generalClient, getToken, getUser } from "../../../helpers/utils";
import { resetSellHomeModal, sellHomeModal } from "./sell-home-modal";

const getUSDPrice = async (currencyId: string, price: string) => {
  const currencyCode = MasterData.currency.find(
    (e) => e.id === currencyId
  )?.notation;
  const isUSD = currencyCode === "USD";
  let rate: any;
  rate = isUSD
    ? 1
    : await axiosClient.get(
        `https://currency-exchange.p.rapidapi.com/exchange?from=USD&to=${currencyCode}&q=1.0`,
        {
          headers: {
            "X-RapidAPI-Key":
              "825559b860msh6ee3c9f9fad4855p15c90ejsnd265e56c10a1",
            "X-RapidAPI-Host": "currency-exchange.p.rapidapi.com",
          },
        }
      );

  const usdPrice = rate * parseFloat(price);

  return usdPrice;
};

const SellHomeRepositories = {
  getProviceCity: async () => {
    return await generalClient.map_GetProvincesData();
  },

  getDistrict: async (proviceCode: string) => {
    return await generalClient.map_FilterDisctrict(proviceCode);
  },

  getWard: async (districtCode: string) => {
    return await generalClient.map_FilterWard(districtCode);
  },

  getProject: async () => {
    return await generalClient.project_GetAll();
  },

  createProperty: async () => {
    const sellHomeModal2 = sellHomeModal;
    const avatarData = sellHomeModal.avatarFile
      ? {
          data: sellHomeModal.avatarFile,
          fileName: sellHomeModal.avatarFile.name,
        }
      : { data: null, fileName: "" };

    let avatar = "";
    const photo: any[] = [];

    const photoData = sellHomeModal.photoFileList.map(
      (item: any, index: number) => ({ data: item, fileName: item.name })
    );

    const _propertyViews = sellHomeModal.view.map(
      (item: any, index: number) => ({ viewId: item.viewId })
    );
    const _propertyAmenitiesNearbys = sellHomeModal.amenitiesNearby.map(
      (item: any, index: number) => ({
        amenitiesNearbyId: item.amenitiesNearbyId,
      })
    );

    await Promise.all([
      sellHomeModal.avatarFile
        ? generalClient.photo_PostPhotoAttachment(
            avatarData,
            "photo",
            undefined,
            "property"
          )
        : null,
      photoData.length > 0
        ? generalClient.photo_PostMultiplePhotoAttachment(
            photoData,
            "photo",
            null,
            "property"
          )
        : null,
    ]).then(async (value) => {
      avatar = value[0]?.objectReturn.filePath;
      value[1]?.objectReturn.forEach((item: any) => {
        photo.push({
          name: item.id,
          imagesPath: item.filePath,
          notes: null,
        });
      });
    });

    let supplierId = "";
    const accessToken = getToken() != null;
    if (accessToken) {
      const user = getUser();
      supplierId = user ? user.id : "00000000-0000-0000-0000-000000000000";
    }

    const usdPrice = await getUSDPrice(
      sellHomeModal.currency,
      sellHomeModal.price
    );

    const data: any = {
      transactionTypeId: sellHomeModal.transactionType,
      propertyTypeId: sellHomeModal.propertyType,
      coverImage: avatar ? avatar : "",
      videoLink: sellHomeModal.addVideoLink,
      virtualVideoLink: sellHomeModal.addVirtualVideoLink,
      // address: null,
      provinceCode: sellHomeModal.proviceCity,
      districtCode: sellHomeModal.district,
      wardCode: sellHomeModal.ward,
      street: sellHomeModal.street,
      projectId: sellHomeModal.project
        ? sellHomeModal.project
        : "00000000-0000-0000-0000-000000000000",
      propertyAddressVi: sellHomeModal.addressShowVI,
      propertyAddressEn: sellHomeModal.addressShowEN,
      title: sellHomeModal.title,
      descriptions: sellHomeModal.descriptions,
      supplierId: supplierId,
      lotSize: parseInt(sellHomeModal.acreageLotSize),
      price: parseFloat(sellHomeModal.price),
      currencyId: sellHomeModal.currency,
      usdPrice: usdPrice,
      bedroomId: sellHomeModal.bedroomTypes,
      bathroomId: sellHomeModal.bathroomTypes,
      floorsNumber: parseInt(sellHomeModal.floorNumber),
      totalBuildingFloors: parseInt(sellHomeModal.totalFloorInBuilding),
      yearCompleted: sellHomeModal.yearCompleted,
      longitude: sellHomeModal.longitude,
      latitude: sellHomeModal.latitude,
      propertyImages: photo.length > 0 ? photo : [],
      propertyViews: _propertyViews,
      propertyAmenitiesNearbys: _propertyAmenitiesNearbys,
    };

    const res = await generalClient.property_Create(data);
    if (res.succeeded) {
      resetSellHomeModal();
    }
    return res.succeeded;
  },

  updateProperty: async (propertyId: string) => {
    const avatarData = sellHomeModal.avatarFile
      ? {
          data: sellHomeModal.avatarFile,
          fileName: sellHomeModal.avatarFile.name,
        }
      : { data: null, fileName: "" };

    let avatar = sellHomeModal.coverImage;
    const photo: any[] =
      sellHomeModal?.propertyImages?.length > 0
        ? sellHomeModal?.propertyImages
        : [];

    const photoData = sellHomeModal.photoFileList.map(
      (item: any, index: number) => ({ data: item, fileName: item.name })
    );

    const _propertyViews = sellHomeModal.view.map(
      (item: any, index: number) => ({ viewId: item.viewId })
    );
    const _propertyAmenitiesNearbys = sellHomeModal?.amenitiesNearby?.map(
      (item: any, index: number) => ({
        amenitiesNearbyId: item.amenitiesNearbyId,
      })
    );

    await Promise.all([
      sellHomeModal.avatarFile
        ? generalClient.photo_PostPhotoAttachment(
            avatarData,
            "photo",
            undefined,
            "property"
          )
        : null,
      photoData.length > 0
        ? generalClient.photo_PostMultiplePhotoAttachment(
            photoData,
            "photo",
            null,
            "property"
          )
        : null,
    ]).then(async (values) => {
      if (values[0]) {
        avatar = values[0]?.objectReturn.filePath;
      }
      if (values[1]) {
        values[1]?.objectReturn.forEach((item: any) => {
          photo.push({
            name: item.id,
            imagesPath: item.filePath,
            notes: null,
          });
        });
      }
    });

    let supplierId = "";
    const accessToken = getToken() != null;
    if (accessToken) {
      const user = getUser();
      supplierId = user ? user.id : "00000000-0000-0000-0000-000000000000";
    }

    const usdPrice = await getUSDPrice(
      sellHomeModal.currency,
      sellHomeModal.price
    );

    const data: any = {
      isSubmit: sellHomeModal.isSubmit,
      transactionTypeId: sellHomeModal.transactionType,
      propertyTypeId: sellHomeModal.propertyType,
      coverImage: avatar ? avatar : "",
      videoLink: sellHomeModal.addVideoLink,
      virtualVideoLink: sellHomeModal.addVirtualVideoLink,
      // address: null,
      provinceCode: sellHomeModal.proviceCity,
      districtCode: sellHomeModal.district,
      wardCode: sellHomeModal.ward,
      street: sellHomeModal.street,
      projectId: sellHomeModal.project
        ? sellHomeModal.project
        : "00000000-0000-0000-0000-000000000000",
      propertyAddressVi: sellHomeModal.addressShowVI,
      propertyAddressEn: sellHomeModal.addressShowEN,
      title: sellHomeModal.title,
      descriptions: sellHomeModal.descriptions,
      supplierId: supplierId,
      lotSize: parseInt(sellHomeModal.acreageLotSize),
      price: parseFloat(sellHomeModal.price),
      currencyId: sellHomeModal.currency,
      usdPrice: usdPrice,
      bedroomId: sellHomeModal.bedroomTypes,
      bathroomId: sellHomeModal.bathroomTypes,
      floorsNumber: parseInt(sellHomeModal.floorNumber),
      totalBuildingFloors: parseInt(sellHomeModal.totalFloorInBuilding),
      yearCompleted: sellHomeModal.yearCompleted,
      longitude: sellHomeModal.longitude,
      latitude: sellHomeModal.latitude,
      propertyImages: photo.length > 0 ? photo : [],
      propertyViews: _propertyViews,
      propertyAmenitiesNearbys: _propertyAmenitiesNearbys,
    };

    const res = await generalClient.property_UpdatePropertyByUser(
      propertyId,
      data
    );
    if (res.succeeded) {
      resetSellHomeModal();
    }
    return res.succeeded;
  },

  saveDraftProperty: async () => {
    const avatarData = sellHomeModal.avatarFile
      ? {
          data: sellHomeModal.avatarFile,
          fileName: sellHomeModal.avatarFile.name,
        }
      : { data: null, fileName: "" };

    let avatar = "";
    const photo: any[] = [];

    const photoData = sellHomeModal.photoFileList.map(
      (item: any, index: number) => ({ data: item, fileName: item.name })
    );

    const _propertyViews = sellHomeModal.view.map(
      (item: any, index: number) => ({ viewId: item.viewId })
    );
    const _propertyAmenitiesNearbys = sellHomeModal.amenitiesNearby.map(
      (item: any, index: number) => ({
        amenitiesNearbyId: item.amenitiesNearbyId,
      })
    );
    await Promise.all([
      sellHomeModal.avatarFile
        ? generalClient.photo_PostPhotoAttachment(
            avatarData,
            "photo",
            undefined,
            "property"
          )
        : null,
      photoData.length > 0
        ? generalClient.photo_PostMultiplePhotoAttachment(
            photoData,
            "photo",
            null,
            "property"
          )
        : null,
    ]).then(async (value) => {
      avatar = value[0]?.objectReturn.filePath;
      value[1]?.objectReturn.forEach((item: any) => {
        photo.push({
          name: item.id,
          imagesPath: item.filePath,
          notes: null,
        });
      });
    });

    let supplierId = "";
    const accessToken = getToken() != null;
    if (accessToken) {
      const user = getUser();
      supplierId = user ? user.id : "00000000-0000-0000-0000-000000000000";
    }

    const usdPrice = await getUSDPrice(
      sellHomeModal.currency,
      sellHomeModal.price
    );

    const data: any = {
      transactionTypeId: sellHomeModal.transactionType,
      propertyTypeId: sellHomeModal.propertyType,
      coverImage: avatar ? avatar : "",
      videoLink: sellHomeModal.addVideoLink,
      virtualVideoLink: sellHomeModal.addVirtualVideoLink,
      // address: null,
      provinceCode: sellHomeModal.proviceCity,
      districtCode: sellHomeModal.district,
      wardCode: sellHomeModal.ward,
      street: sellHomeModal.street,
      projectId: sellHomeModal.project
        ? sellHomeModal.project
        : "00000000-0000-0000-0000-000000000000",
      propertyAddressVi: sellHomeModal.addressShowVI,
      propertyAddressEn: sellHomeModal.addressShowEN,
      title: sellHomeModal.title,
      descriptions: sellHomeModal.descriptions,
      supplierId: supplierId,
      lotSize: parseInt(sellHomeModal.acreageLotSize),
      price: parseFloat(sellHomeModal.price),
      currencyId: sellHomeModal.currency,
      usdPrice: usdPrice,
      bedroomId: sellHomeModal.bedroomTypes,
      bathroomId: sellHomeModal.bathroomTypes,
      floorsNumber: parseInt(sellHomeModal.floorNumber),
      totalBuildingFloors: parseInt(sellHomeModal.totalFloorInBuilding),
      yearCompleted: sellHomeModal.yearCompleted,
      longitude: sellHomeModal.longitude,
      latitude: sellHomeModal.latitude,
      propertyImages: photo.length > 0 ? photo : [],
      propertyViews: _propertyViews,
      propertyAmenitiesNearbys: _propertyAmenitiesNearbys,
    };
    const res = await generalClient.property_SaveTemp(data);
    if (res.succeeded) {
      resetSellHomeModal();
    }
    return res.succeeded;
  },
};

export default SellHomeRepositories;
