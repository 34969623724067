import { SearchOutlined } from "@mui/icons-material";
import {
  Button,
  Container,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CustomSelect, { SelectItemType } from "../../components/custom-select";
import MasterData from "../../apis/master-data";
import { CustomText } from "../../components/styled-custom";
import { currentLanguage } from "../../helpers/utils";
import CustomTextField from "../../components/custom-text-field";

import {
  PaginatedListOfListNewsModel,
  SuggestSearchProjectModel,
} from "../../apis/general-client";
import ProjectModal, {
  SearchProjectModel,
} from "../project/project-controllers/project-modal";
import ProjectRepositories from "../project/project-controllers/project-repositories";
import NewsRepositories from "./news-controllers/news-list-repositories";
import NewsModal, { SearchingNewsModel } from "./news-modal";

type SearchBarType = {
  isMappage?: boolean;
  onSearch?: () => void;
  //   locationData: any;
  //   projectData: any;
};

function NewsSearch(props: SearchBarType) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [keywordValue, setKeywordValue] = useState<any>(
    NewsModal.searchingNewsModel.title
  );

  useEffect(() => {
    getDatas();
  }, []);
  //   props.locationData, props.projectData
  async function getDatas() {
    // setLocation(props.locationData);
    // setProject(props.projectData);
  }

  const onFilterStatus = async (sort: number) => {
    const sortModelState = {
      openForSale: sort === 1,
      commingSoon: sort === 2,
      almostSoldOut: sort === 3,
    };
  };

  const [suggestNews, setSuggestNews] = useState<any>({});

  const [selectedValues, setSelectedValues] = useState<any>({
    title: NewsModal.searchingNewsModel.title
      ? NewsModal.searchingNewsModel.title
      : "",
  });

  const handleChange = (event: SelectChangeEvent) => {
    selectedValues[event.target.name] = event.target.value;

    setSelectedValues({ ...selectedValues });
  };

  const filterProject = (event: SelectChangeEvent) => {
    selectedValues[event.target.name] = event.target.value;

    setSelectedValues({ ...selectedValues });
  };

  const onSearchInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    selectedValues.title = value;
    setKeywordValue(value);
    NewsModal.searchingNewsModel.title = value;

    if (keywordValue.length >= 2) {
      const resData = await NewsRepositories.getSuggestNews(selectedValues);
      setSuggestNews(resData);
    } else {
      closeSuggestDropdown();
    }

    setSelectedValues({ ...selectedValues });
  };

  const onSearchProject = () => {
    NewsModal.searchingNewsModel = {} as SearchingNewsModel;
    NewsModal.searchingNewsModel.title = selectedValues.title;

    setAppModalSearchValue();
  };

  const setAppModalSearchValue = () => {
    NewsModal.searchingNewsModel.title =
      selectedValues.title !== "default" ? selectedValues.title : "";

    !props.isMappage ? navigate("/news") : props.onSearch && props.onSearch();
  };

  const closeSuggestDropdown = async () => {
    setSuggestNews({});
  };


  const onNavigate = (slug: string) => {
    navigate("/news/" + slug);
    window.scrollTo(0, 0);
  };


  const SuggestProjectDropdown = () => {
    return suggestNews.totalCount > 0 ? (
      <div className="suggest-dropdown" id="suggest-dropdown">
        <div className="suggest-dropdown-section">
          <div className="dFlex itemsCenter">
            <CustomText className="semiBold">{t("listings")}</CustomText>
          </div>
          <div className="suggest-dropdown-items">
            {suggestNews &&
              suggestNews?.items.map((item: any, index: number) => (
                <div
                  key={index}
                  onClick={() => onNavigate(item.slug)}
                  className="borderNone"
                >
                  <div className="suggest-dropdown-item">
                    <CustomText>
                      {currentLanguage.isEN ? item.titleEn : item.titleVi}
                    </CustomText>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div
          className="suggest-dropdow-overplay"
          onClick={closeSuggestDropdown}
        ></div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <Container className="search-bar-container">
      <div className="search-bar-news">
        <div className="search-box-news">
          <CustomTextField
            iconStart={<SearchOutlined />}
            label=""
            inputProps={{
              onChange: onSearchInput,
              placeholder: t("searchPost"),
              value: keywordValue,
              // onKeyDown: handleKeyDown,
            }}
          />
          <SuggestProjectDropdown />
        </div>
      </div>
    </Container>
  );
}

export default NewsSearch;
