import { ArrowForwardIos } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import StyledVariables from "../helpers/materials/_variables";
import { currentLanguage } from "../helpers/utils";
import { CustomText } from "./styled-custom";

type BreadcrumbsType = {
  label: string;
  value: string;
  detailTitle?: string;
  id?: string;
};

function AppBreadcrumbs(props: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const fullPathName = location.pathname.split("/");
  const state = location.state as any;

  const breadcrumbsData: BreadcrumbsType[] = [
    {
      label: t("VietnamHomes"),
      value: "/",
      detailTitle: "",
      id: props.transactionTypeId,
    },
    {
      label: t("listingProperty"),
      value: "/sell-home",
      detailTitle: "",
      id: props.transactionTypeId,
    },
    {
      label: t("listingCommercial"),
      value: "/sell-commercial",
      detailTitle: "",
      id: props.transactionTypeId,
    },
    {
      label: t(
        props.transactionTypeId === "002"
          ? "homesForSale"
          : props.transactionTypeId === "001"
          ? "homesForrent"
          : "commercialProperties"
      ),
      value: "/property",
      detailTitle: props.detailTitle,
      id: props.transactionTypeId,
    },
    {
      label: t("commercialProperties"),
      value: "/commercial",
      detailTitle: props.detailTitle,
      id: props.transactionTypeId,
    },
    {
      label: t("exclusiveProject"),
      value: "/project",
      detailTitle: props.detailTitle,
      id: props.transactionTypeId,
    },
    {
      label: t("news"),
      value: "/news",
      detailTitle: props.detailTitle,
      id: props.transactionTypeId,
    },
    {
      label: t("news"),
      value: "/news/news-list",
      detailTitle: props.detailTitle,
      id: props.transactionTypeId,
    },
  ];

  const handleBreadcumsClick = (e: any) => {
    if (e.value === "/") {
      navigate(e.value);
    } else {
      navigate(e.value, { state: { id: e.id } });
    }
  };

  return (
    <Breadcrumbs separator={false}>
      {fullPathName.map((item, index) => {
        const breadcumsItem = breadcrumbsData.find(
          (e) => e.value === `/${item}`
        );

        const breadcumsLength = 2;

        return (
          <div key={index}>
            {breadcumsItem?.value && (
              <div className="breadcrumbs">
                <CustomText
                  onClick={() =>
                    index !== breadcumsLength &&
                    handleBreadcumsClick(breadcumsItem)
                  }
                  style={
                    index === breadcumsLength
                      ? {
                          cursor: "default",
                          textDecoration: "none",
                          fontWeight: StyledVariables.fontWeight.semiBold,
                        }
                      : {}
                  }
                >
                  {breadcumsItem?.label !== "/" && breadcumsItem.label}
                </CustomText>
                {((props.detailTitle && index < 2) ||
                  (!props.detailTitle && index < 1)) && <ArrowForwardIos />}
              </div>
            )}
            {index === 2 && (
              <div className="breadcrumbs">
                <CustomText
                  style={{ cursor: "default", textDecoration: "none" }}
                >
                  {props.detailTitle}
                </CustomText>
              </div>
            )}
          </div>
        );
      })}
    </Breadcrumbs>
  );
}

export default AppBreadcrumbs;
