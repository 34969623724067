import { Card, CardContent } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import VerifySuccessfulIcon from "../../assets/icons/verify-successful.svg";
import VerifyEmailRepositories from "./verify-account-controller/verify-account-repositories";
import { VerifyAccountModel } from "../../apis/general-client";
import { getUser } from "../../helpers/utils";

function VerifyAccountScreen() {
  const { t } = useTranslation();
  const user = getUser();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const email = params.get("email");
  const token = params.get("token");

  const verifyAccount = async () => {
    if (user && !user.emailConfirmed) {
      const requestModel = {
        email: email,
        token: token,
      } as VerifyAccountModel;
  
      await VerifyEmailRepositories.verifyAccount(requestModel);
    }
  };

  useEffect(() => {
    verifyAccount();
  }, []);

  return (
    <div className="verify-account-container">
      <HeaderBar />
      <Container>
        <Card className="card">
          <CardContent className="textCenter">
            <CustomText className="fontL greenLight semiBold uppercase">
              {t("verifyAccount")}
            </CustomText>
            <div className="mt20 verify-success-icon">
              <img src={VerifySuccessfulIcon} alt="" />
            </div>
            {user && !user.emailConfirmed ? (
              <CustomText className="mt20">
                {t("verifyAccountSuccessfully")}
              </CustomText>
            ) : (
              <CustomText className="mt20">
                {t("accountWasVerified")}
              </CustomText>
            )}
          </CardContent>
        </Card>
      </Container>
      <FooterComponent />
    </div>
  );
}

export default VerifyAccountScreen;
