import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import AppModal from "../apis/app-modal";
import LicenceErrorModalComponent from "./licence-error-modal";

function AppLoading() {
  return (
    <div id="app-loading" className="dNone app-loading">
      <CircularProgress />
    </div>
  );
}

export default AppLoading;
