import { Grid } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CustomText } from "../../components/styled-custom";
import VNMap from "../../assets/images/Homepage_popularcity_map_EN.png";
import VNMapCiti from "../../assets/images/Map2.png";
import AppModal, { SearchingPropertyType } from "../../apis/app-modal";
import { useNavigate } from "react-router-dom";
import BGCITY from "../../assets/images/bg-popularcity.png";

type HomeTrustedType = {
  title: string;
  content: ChooseAdministrativeType[];
};

type ChooseAdministrativeType = {
  label: string;
  code: string;
};

function HomeTrusted() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const trustedData: HomeTrustedType[] = useMemo(
    () => [
      {
        title: "northernVN",
        content: [
          {
            label: "haNoi",
            code: "01",
          },
          {
            label: "haLong",
            code: "193",
          },
          {
            label: "ninhBinh",
            code: "369",
          },
          {
            label: "saPa",
            code: "088",
          },
        ],
      },
      {
        title: "centralVN",
        content: [
          {
            label: "daNang",
            code: "48",
          },
          {
            label: "hoiAn",
            code: "503",
          },
          {
            label: "hue",
            code: "474",
          },
          {
            label: "nhaTrang",
            code: "568",
          },
          {
            label: "daLat",
            code: "672",
          },
          {
            label: "phanThiet",
            code: "593",
          },
        ],
      },
      {
        title: "southernVN",
        content: [
          {
            label: "hcmc",
            code: "79",
          },
          {
            label: "phuQuoc",
            code: "911",
          },
          {
            label: "conDao",
            code: "755",
          },
          {
            label: "vungTau",
            code: "747",
          },
        ],
      },
    ],
    []
  );

  const onSearchProperty = (code: string) => {
    AppModal.searchingPropertyModel = {} as SearchingPropertyType;
    AppModal.searchingPropertyModel.administrativeCode = code;

    navigate("/property");
  };

  return (
    <div className="home-trusted">
      <Grid container spacing={8}>
        <Grid item lg={7} xs={12}>
          <div className="content">
            {trustedData.map((item, index) => (
              <div key={index} className="content-item">
                <CustomText className="title">{t(item.title)}</CustomText>
                {item.content.map((value, valueIndex) => (
                  <CustomText
                    key={valueIndex}
                    className="text"
                    onClick={() => onSearchProperty(value.code)}
                  >
                    {t(value.label)}
                  </CustomText>
                ))}
              </div>
            ))}
          </div>
          <img className="background-img-city" src={BGCITY} alt="citi" />
        </Grid>
        <Grid item lg={5} xs={12}>
          <div className="vn-map">
            <img src={VNMapCiti} alt="image map" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default HomeTrusted;
