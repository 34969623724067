import Cookies from "js-cookie";
import CurrencyFormat from "react-currency-format";
import { GeneralClient } from "../apis/general-client";
import MasterData from "../apis/master-data";
import i18n from "../i18n";
import { renderToString } from "react-dom/server";

const generalClient = new GeneralClient(process.env.REACT_APP_API_URL);
const currentLanguageCode = Cookies.get("i18next") || "en";
const currentLanguage = {
  isEN: currentLanguageCode === "en",
  isVI: currentLanguageCode === "vi",
};
const changeLanguage = (lang?: string) => {
  const _currentLanguage = currentLanguage.isEN ? "en" : "vi";
  Cookies.set("i18next", lang || _currentLanguage);
  i18n.changeLanguage(lang || _currentLanguage);
  window.location.reload();
};

const appLoading = {
  show: () => {
    document.getElementById("app-loading")?.classList.remove("dNone");
  },
  dismiss: () => {
    document.getElementById("app-loading")?.classList.add("dNone");
  },
};

const getToken = () => {
  const isRemember = localStorage.getItem("rememberMe") === "true";
  return isRemember
  ? localStorage.getItem("accessToken") || null
  : sessionStorage.getItem("accessToken") || null;

};

const getUser = () => {
  const isRemember = localStorage.getItem("rememberMe") === "true";
  return JSON.parse(
    isRemember
      ? localStorage.getItem("user") || "{}"
      : sessionStorage.getItem("user") || "{}"
  );
};

const getImageWidthWithRef = (ref: HTMLDivElement, ratio: number) => {
  const calWidth = ref.offsetWidth;
  const calHeight = calWidth / ratio;
  return calHeight;
};

const basicHTML = (e: string) => {
  return <div dangerouslySetInnerHTML={{ __html: e }} />;
};

const recentPropertyIds = localStorage.getItem("recentPropertyIds");
const recentlyProperty = {
  set: (id: string) => {
    const limitItems = 16;
    const idsStorage = recentPropertyIds && JSON.parse(recentPropertyIds);
    const lastList = idsStorage ? JSON.parse(JSON.stringify(idsStorage)) : [];
    const idIndex =
      lastList.length > 0 && idsStorage.findIndex((x: any) => x === id);
    lastList.length === limitItems && idIndex < 0 && lastList.pop();
    idIndex >= 0 && lastList.splice(idIndex, 1);
    lastList.unshift(id);
    localStorage.setItem("recentPropertyIds", JSON.stringify(lastList));
  },
  get: () => recentPropertyIds && JSON.parse(recentPropertyIds),
};

const hasUppercase = (text: string) => {
  const upper = /[A-Z]/.test(text);
  return upper;
};

const hasLowerCase = (text: string) => {
  const lower = /[a-z]/.test(text);
  return lower;
};

const hasSpecialChar = (text: string) => {
  const special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(text);
  return special;
};

const hasNumber = (text: string) => {
  const number = /\d/.test(text);
  return number;
};

const allCases = (text: string) => {
  return (
    hasUppercase(text) &&
    hasLowerCase(text) &&
    hasSpecialChar(text) &&
    hasNumber(text)
  );
};

const cleanAccents = (str: string): string => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Combining Diacritical Marks
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // huyền, sắc, hỏi, ngã, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // mũ â (ê), mũ ă, mũ ơ (ư)

  return str;
};

const getPrice = (
  value: number,
  currencyOrigin: string,
  priceOrigin: number
) => {
  const price =
    currencyOrigin === MasterData.currencyValue
      ? priceOrigin
      : MasterData.currencyRate * value;
  const symbol = MasterData.currency && MasterData.currency.find(
    (e) => e.notation === MasterData.currencyValue
  )?.symbol;
  return (
    <>
      {symbol}
      <CurrencyFormat
        className="currencyFormatPrice"
        value={price.toFixed(0)}
        displayType={"text"}
        thousandSeparator={true}
      />
      {" (" + MasterData.currencyValue + ")"}
    </>
  );
};

const getTextPrice = (
  value: number,
  currencyOrigin: string,
  priceOrigin: number
) => {
  const price =
    currencyOrigin === MasterData.currencyValue
      ? priceOrigin
      : MasterData.currencyRate * value;
  const symbol = MasterData.currency.find(
    (e) => e.notation === MasterData.currencyValue
  )?.symbol;

  const result = renderToString(
    <CurrencyFormat
      value={price.toFixed(0)}
      displayType={"text"}
      thousandSeparator={true}
    />
  );
  return `${symbol}${result} (${MasterData.currencyValue})`;
};

const formatNumber = (value: number, separator: string = ",") => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

const getSiteDomain = () => {
  return process.env.REACT_APP_DOMAIN;
};

const formatLotSize = (value: number) => {
  return Math.round(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export {
  changeLanguage,
  currentLanguage,
  appLoading,
  getUser,
  getToken,
  getImageWidthWithRef,
  basicHTML,
  generalClient,
  recentlyProperty,
  hasUppercase,
  hasLowerCase,
  hasSpecialChar,
  hasNumber,
  allCases,
  cleanAccents,
  getPrice,
  getTextPrice,
  // getUSDPrice,
  formatNumber,
  getSiteDomain,
  formatLotSize
};
