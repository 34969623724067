import { Container, MenuItem, Select } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CarouselComponent from "../../components/carousel-component";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import { CustomText } from "../../components/styled-custom";
import GlobalVariables from "../../context/global-variables";
import { appLoading, basicHTML } from "../../helpers/utils";
import ProjectModal, {
  SearchProjectModel,
} from "./project-controllers/project-modal";
import ProjectRepositories from "./project-controllers/project-repositories";
import ProjectListScreen from "./project-list";
import SearchBarProject from "./search-bar-project";
import LazyLoad from "react-lazy-load";

function ProjectScreen(props: any) {
  const { t } = useTranslation();
  let location = useLocation();

  const [isload, setLoad] = React.useState<boolean>(false);
  const local = location?.state as any;
  const [page, setPage] = React.useState(GlobalVariables.projectPageNum);
  const [masterData, setMasterData] = useState<any>({});
  const [getLocation, setLocation] = useState<any[]>([]);
  const [sortModelState, setSortModelState] = useState<{
    latest?: boolean;
    oldest?: boolean;
    mostView?: boolean;
    leastView?: boolean;
  }>({
    latest: true,
    oldest: false,
    mostView: false,
    leastView: false,
  });

  const [sortModel, changeSortModelEvent] = useState<boolean>(false);

  async function getDataProjectList() {
    appLoading.show();
    let overrideSearchingPropertyModel = ProjectModal.searchingProjectModel;
    let val = {
      pageNumber: page,
      pageSize: 8,
      // projectName: "",
      sortingModel: sortModelState,
      ...overrideSearchingPropertyModel,
    };
    const resData = await ProjectRepositories.getProjectList(val);
    setLoad(true);
    setMasterData(resData);
    appLoading.dismiss();
  }

  async function getLocationProjectList() {
    const resDataLocation = await ProjectRepositories.getLocationProject();
    setLocation(resDataLocation);
  }

  useEffect(() => {
    getDataProjectList();
    getLocationProjectList();
    window.addEventListener("popstate", () => {
      ProjectModal.searchingProjectModel = {} as SearchProjectModel;
    });
  }, [page, local?.id, local?.sort, sortModelState]);

  // paginate
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    GlobalVariables.projectPageNum = value;
    setPage(value);
  };

  const onFilter = async (sort: number) => {
    const sortModelState = {
      latest: sort === 1,
      oldest: sort === 2,
      mostView: sort === 3,
      leastView: sort === 4,
    };
    setSortModelState(sortModelState);
  };

  const onSearch = () => {
    setPage(1);
    getDataProjectList();
  };

  return (
   

    <div className="project-container w100">
      <HeaderBar />
      <CarouselComponent isProjects />
      <div style={{ marginTop: -50 }}>
        <SearchBarProject
          locationData={getLocation}
          projectData={masterData}
          isMappage
          onSearch={onSearch}
        />
      </div>
      {isload && (
        <Container className="container">
          <div className="header-project-list">
            {/* <CustomText className="txt-exclusive">
             {t("exclusiveProject")}
            </CustomText> */}
            <div className="content-count header-project-item">
              <CustomText>
                {basicHTML(
                  t("totalBuilderProject", { number: masterData.totalCount })
                )}
              </CustomText>
            </div>
            <div className="content-sort header-project-item">
              <CustomText>{t("sortBy")} </CustomText>
              <Select
                className="form-select"
                id="demo-simple-select-standard"
                onChange={(e: any) => onFilter(e.target.value)}
                defaultValue={1}
              >
                <MenuItem value={1}>
                  <CustomText>{t("lastestPost")}</CustomText>
                </MenuItem>
                <MenuItem value={2}>
                  <CustomText>{t("oldest")}</CustomText>
                </MenuItem>
                <MenuItem value={3}>
                  <CustomText>{t("mostView")}</CustomText>
                </MenuItem>
                <MenuItem value={4}>
                  <CustomText>{t("leastView")}</CustomText>
                </MenuItem>
              </Select>
            </div>
          </div>
          <hr className="mb20 mt28" />
        </Container>
      )}

    <LazyLoad offset={1}>
      <ProjectListScreen
        number={masterData.totalCount}
        changeSortModelEvent={setSortModelState}
        masterData={masterData}
        changePage={(e: any) => {
          GlobalVariables.projectPageNum = e;
          setPage(e);
        }}
      />
        </LazyLoad>
      <FooterComponent />
    </div>
  
  );
}

export default ProjectScreen;
