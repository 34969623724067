import { Button, Grid } from "@mui/material";
import { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";
import CustomTextField from "../../../components/custom-text-field";
import { CustomText } from "../../../components/styled-custom";
// import validator from "validator";
import { appLoading, allCases } from "../../../helpers/utils";
import { AuthRepositories } from "../auth-controllers/auth-repositories";

type RegisterScreenType = {
  goToSignIn: () => void;
  goToSuccess: () => void;
};

function RegisterScreen(props: RegisterScreenType) {
  const { t } = useTranslation();
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const textFieldData = useMemo(
    () => [
      "firstName",
      "lastName",
      "password",
      "rePassword",
      "email",
      "phoneNumber",
    ],
    []
  );
  const [inputValue, setInputValue] = useState<any>({
    firstName: { value: "", error: "" },
    lastName: { value: "", error: "" },
    password: { value: "", error: "" },
    rePassword: { value: "", error: "" },
    email: { value: "", error: "" },
    phoneNumber: { value: "", error: "" },
  });

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsShowError(false)
    const targetName = e.target.name;
    const targetValue = e.target.value;
    inputValue[targetName].value = targetValue;
    inputValue[targetName].error = "";
    setInputValue({ ...inputValue });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    onValidate();
    if (
      !inputValue["firstName"].error &&
      !inputValue["lastName"].error &&
      !inputValue["password"].error &&
      !inputValue["rePassword"].error &&
      !inputValue["email"].error
    ) {
      appLoading.show();
      const res = await AuthRepositories.register(inputValue);
      if (res.succeeded) {
        props.goToSuccess();
      } else {
        let error = res.errors ? res.errors[0] : "";
        setIsShowError(true);
        setErrorMessage(error);
      }
      appLoading.dismiss();
    }
  };

  const onValidate = () => {
    Object.keys(inputValue).forEach((key: any, index: number) => {
      const _inputValue = inputValue[key];

      if (key !== "phoneNumber" && _inputValue.value.length === 0) {
        _inputValue.error = "requiredField";
      } else {
        if (key === "email") {
          _inputValue.error = validator.isEmail(_inputValue.value)
            ? ""
            : "emailInvalidate";
        }

        if (
          key === "rePassword" &&
          _inputValue.value !== inputValue["password"].value
        ) {
          _inputValue.error = "passwordNotMatch";
        }

        if (key === "password") {
          if (_inputValue.value.length < 6) {
            _inputValue.error = "passMoreThanSixCharacter";
          }
          // if (!allCases(_inputValue.value)) {
          //   _inputValue.error = "passAllCase";
          // }
        }
      }
    });

    setInputValue({ ...inputValue });
  };

  return (
    <div className="register-screen">
      <div className="auth-title">
        <CustomText>{t("signUp")}</CustomText>
      </div>
      <div className="register-form">
        <form onSubmit={onSubmit}>
          <Grid container spacing={2}>
            {textFieldData.map((item, index) => {
              const lastItem = index === textFieldData.length - 1;
              const inputType =
                // index === 2 || index === 3
                //   ? "text"
                   index === 5
                  ? "number"
                  : "text";

              return (
                <Grid key={index} item lg={6} xs={12}>
                  <div className="register-text-field">
                    <div className="form-label">
                      <CustomText>{t(item)}</CustomText>
                      {!lastItem && (
                        <CustomText className="label-mark">*</CustomText>
                      )}
                    </div>
                    <CustomTextField
                      label=""
                      type={inputType}
                      name={item}
                      onChange={onInputChange}
                      className="auth-text-field"
                      inputProps={{
                        placeholder: lastItem
                          ? ""
                          : index === 3 ||index===2
                          ? t("mini6Characters")
                          : t(item),
                        error: inputValue[item].error.length > 0,
                      }}
                      helperText={
                        inputValue[item].error && t(inputValue[item].error)
                      }
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div className="bottom-field">
            <div className="error-message" 
              style={{ visibility: isShowError ? "visible" : "hidden" }}
            >
              <CustomText
                className="wrong-account">
                {t("UserNameIsExist")}
              </CustomText>
            </div>
            <Button className="submit-btn" type="submit">
              {t("confirm")}
            </Button>
            <div className="have-account">
              <CustomText>{t("alreadyRegistered")}</CustomText>
              <CustomText className="sign-in-btn" onClick={props.goToSignIn}>
                {t("signIn")}
              </CustomText>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterScreen;
