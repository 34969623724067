import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazy-load";
import { useLocation } from "react-router-dom";
import CustomCard from "../../components/custom-card";
import CustomSelect from "../../components/custom-select";
import { CustomText } from "../../components/styled-custom";
import { sellHomeModal } from "./controllers/sell-home-modal";

function SellHomePropertyDescription(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const local = location?.state as any;
  const item = local?.item;

  const limitText = 150;
  const [title, setTitle] = useState<string>(() => {
    if (sellHomeModal?.title) {
      let title = sellHomeModal?.title;
      return title;
    } else {
      return [];
    }
  });
  const [editorValue, setEditorValue] = useState<any>(() => {
    if (sellHomeModal?.descriptions) {
      let photos = sellHomeModal?.descriptions;
      return photos;
    } else {
      return [];
    }
  });

  const onTitleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const targetValue =
      e.target.value.length <= limitText
        ? e.target.value
        : e.target.value.slice(0, limitText);
    sellHomeModal.title = targetValue;
    props.required["title"] && props.validator("title");
    setTitle(targetValue);
  };

  const onTextEditor = (e: any, editor: any) => {
    const dataEditor = editor.getData();
    sellHomeModal.descriptions = dataEditor;
    props.required["descriptions"] && props.validator("descriptions");
    setEditorValue(dataEditor);
  };

  useEffect(() => {}, [local?.item]);

  return (
    <LazyLoad offset={1}>

    <CustomCard
      title={t("propertyDescription")}
      className="sell-home-description"
    >
      <CustomSelect
        error={props.required.title}
        isInput
        name="title"
        label={t("title") + " *"}
        value={title}
        onChange={onTitleInput}
        inputProps={{
          label: "",
          iconEnd: (
            <CustomText className="fontSP gray">{`${title.length}/${limitText}`}</CustomText>
          ),
        }}
      />
      <div
        className={`text-editor ${props.required.descriptions ? "error" : ""}`}
      >
        <CustomText className="fontMP mb16 des-title">
          {t("descriptions") + " *"}
        </CustomText>
        <CKEditor
          editor={ClassicEditor}
          config={{
            placeholder: t("descriptions"),
            toolbar: {
              items: [
                "heading",
                "|",
                "alignment",
                "|",
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "subscript",
                "superscript",
                "|",
                "link",
                "|",
                "bulletedList",
                "numberedList",
                "todoList",
                "fontfamily",
                "fontsize",
                "fontColor",
                "fontBackgroundColor",
                "|",
                "code",
                "codeBlock",
                "|",
                "|",
                "outdent",
                "indent",
                "|",
                "|",
                "undo",
                "redo",
              ],
              shouldNotGroupWhenFull: true,
            },
          }}
          data={editorValue}
          value={editorValue}
          onChange={onTextEditor}
        />
      </div>
    </CustomCard>
    </LazyLoad>
  );
}

export default SellHomePropertyDescription;
