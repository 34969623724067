import anime from "animejs";
import { memo, useEffect, useLayoutEffect, useRef, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useLocation } from "react-router-dom";
import { AppRepositories } from "../apis/app-repositories";
import { BannerModel, BannerTypes } from "../apis/general-client";
import StyledVariables from "../helpers/materials/_variables";
import { getImageWidthWithRef } from "../helpers/utils";
import { CustomText } from "./styled-custom";

function CarouselComponent(props: any) {
  const carouselRef = useRef<HTMLDivElement>(null);
  let location = useLocation();
  const local = location?.state as any;
  const [carouselHeight, setCarouselHeight] = useState<number>(0);
  const [bannerData, setBannerData] = useState<BannerModel[]>();
  const [textTopSize, setTextTopSize] = useState<number>(0);
  const [textBottomSize, setTextBottomSize] = useState<number>(0);
  const [carouselInterval, setCarouselInterval] = useState<number>(5000);

  useEffect(() => {
    
    async function getDatas() {
      if (props.isProjects) {
        const resData = await AppRepositories.getAllBanners(
          BannerTypes.ProjectPage
        );
        setBannerData(resData);
        
      } else {
        const resData = await AppRepositories.getAllBanners(
          BannerTypes.HomePage
        );
        setBannerData(resData);
      }
    }
    getDatas();
  }, [local?.id]);

  const handleCarouselWidth = () => {
    if (carouselRef.current) {
      const offsetWidth = carouselRef.current.offsetWidth;
      const isTabletVertical = offsetWidth <= 1200;
      const isTabletHorizontal = offsetWidth <= 1024;
      const isMobile = offsetWidth <= 768;
      let _textTopSize = 118;
      let _textBottomSize = 112;
      let ratio = 192 / 75;

      if (isMobile) {
        ratio = 1;
        _textTopSize = 56;
        _textBottomSize = 43;
      } else if (isTabletHorizontal) {
        ratio = 2.0;
        _textTopSize = 88;
        _textBottomSize = 76;
      } else if (isTabletVertical) {
        ratio = 2.2;
        _textTopSize = 96;
        _textBottomSize = 88;
      }
      const calHeight = getImageWidthWithRef(carouselRef.current, ratio);
      setCarouselHeight(calHeight);
      setTextTopSize(_textTopSize);
      setTextBottomSize(_textBottomSize);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", handleCarouselWidth, false);
    handleCarouselWidth();
    return () =>
      window.removeEventListener("resize", handleCarouselWidth, false);
  }, []);

  const onCarouselChange = (now?: number, prev?: number) => {
    now === 1 && setCarouselInterval(3000);
    if (now === 0) {
      carouselInterval === 3000 && setCarouselInterval(5000);
      const textBottomClass = `.bottom-carousel-text-${now}`;
      const textWrapper = document.querySelector(textBottomClass)!;
      if (textWrapper && bannerData && bannerData.length > 0) {
        textWrapper.innerHTML = textWrapper.textContent!.replace(
          /\S/g,
          "<span class='letter'>$&</span>"
        );
        textWrapper.style.opacity = "0";
        setTimeout(() => {
          textWrapper.style.opacity = "1";
          anime.timeline().add({
            targets: `${textBottomClass} .letter`,
            opacity: [0, 1],
            easing: "easeInOutQuad",
            duration: 500,
            delay: (el, i) => 100 * (i + 1),
          });
        }, 1000);
      }
    }
  };

  return (
    <div
      ref={carouselRef}
      className="carousel-component-container"
      style={{height: !bannerData ? "750px" : "", backgroundColor: !bannerData ? "#747474" : ""}}
      onClick={(e) => e.stopPropagation()}
    >
      {bannerData && (
        <Carousel
          className="carousel-component"
          height={carouselHeight}
          indicatorContainerProps={{
            className: "carousel-indicator",
            style: {
              display: bannerData.length > 1 ? "" : "none",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: StyledVariables.colors.greenLight,
            },
          }}
          stopAutoPlayOnHover={false}
          interval={carouselInterval}
          animation="fade"
          duration={1000}
          onChange={onCarouselChange}
          changeOnFirstRender
        >
          {bannerData.map((item, index) => (
            <div className="carousel-image" key={index}>
              <img src={item.imagePathUrl} alt={`Home Carousel ${index}`} />
              <div
                className={`carousel-text ${
                  index === 0 ? "" : "carousel-text-bottom"
                }`}
              >
                <CustomText
                  className={index === 0 ? "top-text-first" : ""}
                  style={{
                    fontFamily: StyledVariables.fontFamily.font2,
                    fontSize: textTopSize,
                    color: StyledVariables.colors.white,
                  }}
                >
                  {item.bannerName}
                </CustomText>
                <CustomText
                  className={
                    index === 0
                      ? `bottom-carousel-text-${index} letter`
                      : "bottom-text"
                  }
                  style={{
                    fontFamily: StyledVariables.fontFamily.font3,
                    fontSize: textBottomSize,
                    color: StyledVariables.colors.white,
                  }}
                >
                  {item.descriptions}
                </CustomText>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
}

export default memo(CarouselComponent);
