import { Alert, Button, Grid, Snackbar } from "@mui/material";
import { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import HomeRepositories from "../views/home/home-controllers/home-repositories";
import CustomTextField from "./custom-text-field";
import validator from "validator";
import { appLoading } from "../helpers/utils";
import { CreateContactModel } from "../apis/general-client";
import { CustomText } from "./styled-custom";

type HomeContactType = {
  subject: string;
  name: string;
  email: string;
  phone?: number;
  message: string;
};

function ContactForm(props: any) {
  const { t } = useTranslation();

  const initialInput: string[] = useMemo(
    () => [
      "subject",
      "nameAndSurname",
      "email",
      "phone",
      "message",
    ],
    []
  );

  const [dataContact, setDataContact] = useState<any>({
    subject: { value: "", error: "" },
    nameAndSurname: { value: "", error: "" },
    email: { value: "", error: "" },
    phone: { value: "", error: "" },
    message: { value: "", error: "" },
  });

  const [disable, setDisable] = useState<boolean>(true);

  const getDataContact = (e: ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    dataContact[targetName].value = targetValue;
    dataContact[targetName].error = "";
    // dataContact[e.target.name] = e.target.value;
    setDataContact({ ...dataContact });
    const objContact = Object.entries(dataContact).filter(
      ([object, key]) => key === "" && object !== "phone"
    );

    setDisable(objContact.length !== 0);
  };

  const [open, setOpenNoti] = useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenNoti(false);
  };
  async function onSent() {
    onValidate();

    let contact: any = {
      subject: dataContact.subject.value,
      name: dataContact.nameAndSurname.value,
      phone: dataContact.phone.value,
      email: dataContact.email.value,
      message: dataContact.message.value,
      propertyId: "",
      projectId:"",
      contactType: props.type,
    };

    if (
      !dataContact["subject"].error &&
      !dataContact["nameAndSurname"].error &&
      !dataContact["email"].error &&
      !dataContact["message"].error
    ) {
      appLoading.show();
      const resData = await HomeRepositories.createContact(contact);
      resData &&
        setDataContact({
          subject: { value: "", error: "" },
          nameAndSurname: { value: "", error: "" },
          email: { value: "", error: "" },
          phone: { value: "", error: "" },
          message: { value: "", error: "" },
        });
      setOpenNoti(true);
      setDisable(true);
      appLoading.dismiss();
    }
  }
  const onValidate = () => {
    Object.keys(dataContact).forEach((key: any, index: number) => {
      const _inputValue = dataContact[key];

      if (key !== "phone" && _inputValue.value.length === 0) {
        _inputValue.error = "requiredField";
      } else {
        if (key === "email") {
          _inputValue.error = validator.isEmail(_inputValue.value)
            ? ""
            : "emailInvalidate";
        }
      }
    });

    setDataContact({ ...dataContact });
  };
  return (
    <div className="input-group">
      <Grid container spacing={2}>
        {initialInput.map((item, index) => (
          <Grid
            key={index}
            item
            md={index === 2 || index === 3 ? 6 : 12}
            xs={12}
          >
            <div className={index === 4 ? "" : "h-input"}>
              <CustomTextField
                label=""
                inputProps={{
                  placeholder: t(item) + (index === 3 ? "" : " *"),
                  value: dataContact[item].value,
                  error: dataContact[item].error?.length > 0,
                }}
                helperText={
                  dataContact[item].error && t(dataContact[item].error)
                }
                multiline={index === initialInput.length - 1}
                row={7}
                onChange={getDataContact}
                name={item}
                type={index === 3 ? "number" : "text"}
              />
            </div>
          </Grid>
        ))}
      </Grid>
      <Button
        disabled={disable}
        className="submit-btn"
        onClick={() => onSent()}
      >
        {t("send")}
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {props.type === 1 ? t("contactUsSuccess") : t("yourQuestionSent")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ContactForm;
