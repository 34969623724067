import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { ProjectFeatureModel } from "../../apis/general-client";
import { CustomText } from "../../components/styled-custom";
import { ReactComponent as CheckMarkLineIcon } from "../../assets/icons/check-mark-line.svg";
import { currentLanguage } from "../../helpers/utils";

type ProjectDetailFeaturesType = {
  projectFeatures: ProjectFeatureModel[] | undefined;
};

function ProjectDetailFeatures(props: ProjectDetailFeaturesType) {
  const [projectFeaturesData, setProjectFeaturesData] =
    useState<ProjectDetailFeaturesType>();

  useEffect(() => {
    getProjectFeatures();
  }, [props]);

  function getProjectFeatures() {
    setProjectFeaturesData(props);
  }

  return projectFeaturesData ? (
    <div className="project-features-container">
      <Grid item container spacing={4} className="project-feature-items">
        {/* <div className="project-feature-items"> */}
          {projectFeaturesData.projectFeatures?.map(
            (item: ProjectFeatureModel, index: number) => (
              <Grid key={index} item xs={6} sm={6} md={3}>
                <div className="dFlex itemsCenter mr20">
                  <div className="check-icon mr8">
                    <CheckMarkLineIcon className="check-mark-icon" />
                  </div>
                  <CustomText>
                    {currentLanguage.isEN
                      ? item.projectFeatureEn
                      : item.projectFeatureVi}
                  </CustomText>
                </div>
              </Grid>
            )
          )}
        {/* </div> */}
      </Grid>
    </div>
  ) : (
    <> </>
  );
}

export default ProjectDetailFeatures;
