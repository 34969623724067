import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import SlideShow, { ResponsiveType } from "react-multi-carousel";
import MultiSlides from "./multi-slides";

type ViewImageComponentType = {
  images: any[] | undefined;
  imageVariable: string;
};

function ViewImageComponent(props: ViewImageComponentType) {
  const [imageData, setImageData] = useState<ViewImageComponentType>();
  const [imageView, setImageView] = useState<string>();
  console.log(imageData?.images, "imageDatawwwwww33333");

  useEffect(() => {
    getImageData();
  }, [props]);

  function getImageData() {
    setImageData(props);
  }

  const getImageView = (imageUrl: string) => {
    setImageView(imageUrl);
  };

  const closeViewImage = () => {
    setImageView("");
  };

  const onArrowClick = (e: React.MouseEvent) => {
    const isPrev = e.currentTarget.className.includes("prev");
    const imageIndex =
      imageView &&
      props.images?.findIndex((x) => x[props.imageVariable] === imageView);
    const imageCount = props.images ? props.images.length : 0;

    if (isPrev) {
      if (imageIndex && imageIndex > 0) {
        const item =
          props.images && props.images[imageIndex - 1][props.imageVariable];
        setImageView(item);
      }
    } else {
      if (imageIndex && imageIndex < imageCount - 1) {
        const item =
          props.images && props.images[imageIndex + 1][props.imageVariable];
        setImageView(item);
      } else if (imageIndex === 0) {
        const item = props.images && props.images[1][props.imageVariable];
        setImageView(item);
      }
    }
  };

  return (
    <div className="view-images-container">
      {imageData && (
        <MultiSlides
          imageData={imageData}
          responsive={{ desktop: 4, tabletL: 3, tabletM: 2, mobile: 1 }}
        >
          {imageData?.images?.sort((a, b) => a.orderBy - b.orderBy)?.map((item: any, index: number) => (
            <div key={index} className="view-images-item">
              <div
                className="image-item"
                style={{ height: 230 }}
                onClick={() => getImageView(item[props.imageVariable])}
              >
                <img src={item[props.imageVariable]} alt="" draggable={false} />
              </div>
            </div>
          ))}
        </MultiSlides>
      )}
      {imageView && (
        <div className="image-detail">
          <div className="dFlex justifyCenter h100">
            <Button className="slide-button prev" onClick={onArrowClick}>
              <ArrowBack />
            </Button>
            <div className="image-frame">
              <div className="image-box">
                <Close onClick={closeViewImage} />
                <img src={imageView} alt="" />
              </div>
            </div>
            <Button className="slide-button next" onClick={onArrowClick}>
              <ArrowForward />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(ViewImageComponent);
