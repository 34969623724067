export type AppStateType = {
  type: AppActionStates;
  payload: any;
};

export type InitAppStateType = {
  isLogged: boolean;
  userInfo:UserInfo;
  count:number;
};
 
export type UserInfo={
  firstName:string;
  lastName:string;
  avartarUrl:string;
};
// Initial state
const initAppState: InitAppStateType = {
  isLogged: false,
  userInfo:{
    firstName:"",
    avartarUrl:"",
    lastName:""
  },
  count:0,
};

// Actions state
enum AppActionStates {
  LOGGED,
  EDIT_USER,
  NOTI
}

export type ActionUserChange ={
  isChange: false,
}

const appSetState = (type: AppActionStates, payload: any) => {
  return {
    type,
    payload,
  };
};

export { initAppState, appSetState, AppActionStates };
