import CarouselComponent from "../../components/carousel-component";
import FooterComponent from "../../components/footer-component";
import HeaderBar from "../../components/header-bar";
import SearchBar from "../../components/search-bar";
import HomeClients from "./home-clients";
import HomeQuestions from "./home-question";
import HomeSaleBanner from "./home-sale-banner";
import HomeSearchLuxury from "./home-search-luxury";
import LazyLoad from 'react-lazy-load';

function Home() {
  return (
    <div className="home-page w100">
      <HeaderBar isHomePage />
      <CarouselComponent />
      <div style={{ marginTop: -50 }}>
        <SearchBar />
      </div>
      <div style={{ marginTop: 180, marginBottom: 100 }}>
        <HomeSearchLuxury />
      </div>
      <HomeClients />
      <HomeSaleBanner />
      <HomeQuestions />
      <FooterComponent />
    </div>
  );
}

export default Home;
