import { SearchingNewsModel } from "../../../apis/general-client";
import { generalClient } from "../../../helpers/utils";

const NewsRepositories = {
  getNewsList: async (data: any) => {
    const resData = await generalClient.news_GetHotNews(data);
    return resData;
  },

  getNewsCategory: async () => {
    const resData = await generalClient.news_GetNewsGroupByCategory();
    return resData;
  },

  getNewsDetail: async (id: any) => {
    const resData = await generalClient.news_GetById(id);
    return resData;
  },

  getNewsDetailBySlug: async (slug: any) => {
    const resData = await generalClient.news_GetBySlug(slug);
    return resData;
  },

  getRelatedNews: async (id: any, dataRelated: any) => {
    if (id) {
      const resData = await generalClient.news_GetByCategoryId(id, dataRelated);
      return resData;
    }
    return null;
  },

  getNextNews: async (id: any) => {
    const resData = await generalClient.news_GetNextNews(id);
    return resData;
  },

  getWellReadNews: async (dataWellRead: any) => {
    const resData = await generalClient.news_GeWellReadNews(dataWellRead);
    return resData;
  },

  getLatestNews: async (dataLatest: any) => {
    const resData = await generalClient.news_PagingNews(dataLatest);
    return resData;
  },
  
  getSuggestNews: async (keyword: any) => {
    const res = await generalClient.news_Searching(keyword);
    return res;
  },

  viewCountNews: async (newsId: string) => {
    const res = await generalClient.news_CountViewNews(newsId);
    return res;
  },
};

export default NewsRepositories;
