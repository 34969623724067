import { Container, Grid } from "@mui/material";
import Logo from "../../assets/images/Logo2-Black-Green.png";
import { ReactComponent as Mail } from "../../assets/icons/Mail.svg";
import { ReactComponent as Phone } from "../../assets/icons/Phone.svg";
import { ReactComponent as Send } from "../../assets/icons/Send.svg";
import { CustomText } from "../../components/styled-custom";
import { useTranslation } from "react-i18next";
import BG from "../../assets/images/bg-ask-question.png";
import ContactForm from "../../components/contact-form";
import LazyLoad from "react-lazy-load";

function HomeQuestions() {
  const { t } = useTranslation();

  return (
    <div className="home-question limit-width">
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={5} xs={12}>
          <LazyLoad offset={1}>
            <div className="left-side">
              <img src={Logo} alt="" />
              <CustomText className="email">
                <Mail className="mr12" /> Info@VietnamHomes.ca
                <Send className="ml12" />
              </CustomText>
              <CustomText className="title">{t("canBrokerage")}</CustomText>
              <CustomText className="phone">
                <Phone />  +1 (437) 254-4663 (WhatsApp)
              </CustomText>
              <CustomText className="title">{t("vnBrokerage")}</CustomText>
              <CustomText className="phone">
                <Phone /> +84 (0) 363 551 799
              </CustomText>
              <CustomText className="fontMP">
                (WhatsApp/Zalo/Viber)
              </CustomText>
            </div>
          </LazyLoad>
          </Grid>
          <Grid item lg={7} xs={12}>
          <LazyLoad offset={1}>
            <div className="right-side">
              <CustomText className="title">{t("askQuestion")}</CustomText>
              <ContactForm type={2}/>
            </div>
          </LazyLoad>
          </Grid>
        </Grid>
      </Container>
      <LazyLoad offset={1}>
      <img className="background-img" src={BG} alt="" />
      </LazyLoad>
    </div>
  );
}

export default HomeQuestions;
