import DialogComponent from "../../components/dialog-component";
import AuthBG from "../../assets/images/Signin.jpg";
import { useEffect, useState } from "react";
import LoginScreen from "./login/login";
import RegisterScreen from "./register/register";
import StyledVariables from "../../helpers/materials/_variables";
import AuthSuccessDialog from "./auth-success-dialog";

type AuthDialogType = {
  open: boolean;
  onClose: () => void;
  type: "signUp" | "signIn";
};

function AuthDialog(props: AuthDialogType) {
  const typeSign = props.type;
  const [type, setType] = useState<string>(typeSign);

  useEffect(() => {
    setType(typeSign);
  }, [typeSign]);

  const onClose = () => {
    props.onClose();
    setType("signIn");
  };
  const goToSignUp = () => {
    setType("signUp");
    
  };
  return (
    <DialogComponent
      open={props.open}
      onClose={onClose}
      className="auth-dialog"
      backgroundImage={AuthBG}
      closeButtonColor={StyledVariables.colors.white}
    >
      {type === "signIn" && <LoginScreen onClose={props.onClose} goToSignUp={goToSignUp} />}
      {type === "signUp" && (
        <RegisterScreen
          goToSignIn={() => setType("signIn")}
          goToSuccess={() => setType("registerSuccess")}
        />
      )}
      {type === "registerSuccess" && (
        <AuthSuccessDialog goToSignIn={() => setType("signIn")} />
      )}
    </DialogComponent>
  );
}

export default AuthDialog;
