import { Grid } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MasterData from "../../apis/master-data";
import CustomCard from "../../components/custom-card";
import CustomSelect, { SelectItemType } from "../../components/custom-select";
import { CustomText } from "../../components/styled-custom";
import { currentLanguage, getImageWidthWithRef } from "../../helpers/utils";
import {
  initExistedSellHomeModal,
  sellHomeModal,
} from "./controllers/sell-home-modal";
import moment from "moment";
import mapFunctions from "../../helpers/init-maps";
import { useLocation } from "react-router-dom";
import LazyLoad from "react-lazy-load";

function SellHomePropertyInfomation(props: any) {
  const { t } = useTranslation();
  let location = useLocation();
  const local = location?.state as any;
  const mapRef = useRef<HTMLDivElement>(null);
  const item = local?.item;
  // initExistedSellHomeModal(item);
  const data: SelectItemType[] = useMemo(
    () => [
      {
        label: t("view") + " *",
        name: "view",
        data: [
          {
            id: "default",
            viewVi: t("chooseView"),
            viewEn: t("chooseView"),
          },
          ...MasterData.view,
        ],
      },
      {
        label: t("acreageLotSize") + " *",
        placeholder: "",
        name: "acreageLotSize",
        data: [],
        type: "number",
      },
      {
        label: t("price") + " *",
        placeholder: "price",
        name: "price",
        data: [],
        type: "number",
      },
      {
        label: t("currency") + " *",
        name: "currency",
        data: [
          {
            id: "default",
            currencyVi: t("currency"),
            currencyEn: t("currency"),
          },
          ...MasterData.currency.map((item: any, index: number) => ({
            id: item.id,
            currencyVi: item.notation,
            currencyEn: item.notation,
          })),
        ],
      },
      {
        label: t("bedRooms") + " *",
        name: "bedroomTypes",
        data: [
          {
            id: "default",
            bedroomTypesVi: t("bedRooms"),
            bedroomTypesEn: t("bedRooms"),
          },
          ...MasterData.bedroom,
        ],
      },
      {
        label: t("bathRooms") + " *",
        name: "bathroomTypes",
        data: [
          {
            id: "default",
            bathroomTypesVi: t("bathRooms"),
            bathroomTypesEn: t("bathRooms"),
          },
          ...MasterData.bathroom,
        ],
      },
      {
        label: t("floorNumber"),
        placeholder: "floorNumber",
        name: "floorNumber",
        data: [],
        type: "number",

      },
      {
        label: t("totalFloorInBuilding"),
        placeholder: "totalFloor",
        name: "totalFloorInBuilding",
        data: [],
        type: "number",

      },
      {
        label: t("amenitiesNearby"),
        name: "amenitiesNearby",
        data: [
          {
            id: "default",
            amenitiesNearbyVi: t("chooseAmenitiesNearby"),
            amenitiesNearbyEn: t("chooseAmenitiesNearby"),
          },
          ...MasterData.amenitiesNearby,
        ],
      },
      {
        label: t("yearCompleted"),
        name: "yearCompleted",
        placeholder: t("chooseYearCompleted"),
        data: [],
      },
      {
        label: t("latitude") + " *",
        name: "latitude",
        data: [],
      },
      {
        label: t("longitude") + " *",
        name: "longitude",
        data: [],
      },
    ],
    [t]
  );

  const [value, setValue] = useState<any>({
    // view: ["default"],
    // currency: "002",
    // bedroomTypes: "default",
    // bathroomTypes: "default",
    // amenitiesNearby: ["default"],
    // acreageLotSize: "",
    // price: "",
    // floorNumber: "",
    // totalFloorInBuilding: "",

    view:
      sellHomeModal?.propertyViews != null
        ? sellHomeModal?.propertyViews.map((x: any) => x.viewId)
        : ["default"],
    currency: "002",
    bedroomTypes:
      sellHomeModal?.bedroomId != null ? sellHomeModal?.bedroomId : "default",
    bathroomTypes:
      sellHomeModal?.bathroomId != null ? sellHomeModal?.bathroomId : "default",
    amenitiesNearby:
      sellHomeModal?.propertyAmenitiesNearbys != null
        ? sellHomeModal?.propertyAmenitiesNearbys.map(
            (x: any) => x.amenitiesNearbyId
          )
        : ["default"],
    acreageLotSize:
      sellHomeModal?.lotSize != null ? sellHomeModal?.lotSize : "",
    price: sellHomeModal?.price != null ? sellHomeModal?.price : "",
    floorNumber:
      sellHomeModal?.floorsNumber != null ? sellHomeModal?.floorsNumber : "",
    totalFloorInBuilding:
      sellHomeModal?.totalBuildingFloors != null
        ? sellHomeModal?.totalBuildingFloors
        : "",
  });

  const [coordinates, setCoordinates] = useState<any>({
    longitude: sellHomeModal?.longitude != null ? sellHomeModal?.longitude : "",
    latitude: sellHomeModal?.latitude != null ? sellHomeModal?.latitude : "",
  });

  const [dateValue, setDateValue] = useState<any>("");

  const [mapHeight, setMapHeight] = useState<number>(0);

  const handleMapWidth = () => {
    if (mapRef.current) {
      const ratio = mapRef.current.offsetWidth < 769 ? 1 / 1 : 25 / 13;
      const calHeight = getImageWidthWithRef(mapRef.current, ratio);
      setMapHeight(calHeight);
    }
  };

  const handleDateChange = (e: any) => {
    const newDate = moment(e).toISOString();
    setDateValue(newDate);
    // sellHomeModal.yearCompleted = dateValue;
    sellHomeModal.yearCompleted = newDate;
  };

  const getYearcompleted = () => {
    // const sellHomeModal2 = sellHomeModal;
    if (sellHomeModal?.yearCompleted !== null) {
      sellHomeModal.yearCompleted =
        sellHomeModal?.yearCompleted !== null
          ? sellHomeModal?.yearCompleted
          : dateValue;
      const newDate = sellHomeModal.yearCompleted;
      setDateValue(newDate);
    }
    // const newDate = (sellHomeModal.yearCompleted = moment().toISOString());
    // setDateValue(newDate);
  };

  const getValue = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    const targetType = e.target.type;
    const targetValid = e.currentTarget?.validity?.valid;

    if(targetType =="number"){
      if (!targetValid) {
        value[targetName] = "";
        setValue({ ...value });
        return;
      }
    }

    if (targetName === "view" || targetName === "amenitiesNearby") {
      targetValue.some((e: string) => e === "default") && targetValue.shift();
    }

    value[targetName] = targetValue;
    if (targetName === "view" || targetName === "amenitiesNearby") {
      const data = targetValue.map((val: any, index: number) => {
        return { viewId: val, amenitiesNearbyId: val };
      });
      sellHomeModal[targetName] = data;
    } else {
      sellHomeModal[targetName] = targetValue === "default" ? "" : targetValue; // else
    }

    props.required[targetName] && props.validator(targetName);
    setValue({ ...value });
  };

  const getCoordinates = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    coordinates[targetName] = targetValue;
    sellHomeModal[targetName] = targetValue === "default" ? "" : targetValue;
    props.required[targetName] && props.validator(targetName);
    setCoordinates({ ...coordinates });
  };

  const handleMapAction = () => {
    if (coordinates["longitude"] && coordinates["latitude"]) {
      //pick
      mapFunctions.addMarker(
        {
          lat: parseFloat(coordinates["latitude"]),
          lng: parseFloat(coordinates["longitude"]),
        },
        true
      );
    }
  };

  useEffect(() => {
    const handleMapPicker = (lat: number, lng: number) => {
      const _lat = lat.toString();
      const _lng = lng.toString();
      coordinates["latitude"] = _lat;
      coordinates["longitude"] = _lng;
      sellHomeModal["latitude"] = _lat;
      sellHomeModal["longitude"] = _lng;
      props.required["latitude"] && props.validator("latitude");
      props.required["longitude"] && props.validator("longitude");
      setCoordinates({ ...coordinates });
    };

    window.addEventListener("resize", handleMapWidth, false);
    handleMapWidth();
    mapFunctions.initMap(handleMapPicker);
    getYearcompleted();
    return () => window.removeEventListener("resize", handleMapWidth, false);
    setValue(value);
  }, [local?.item]);
  return (
    <LazyLoad offset={1} onContentVisible={() => handleMapWidth()}>

    <CustomCard
      title={t("propertyInfomations")}
      className="sell-home-infomation"
    >
      <Grid container spacing={2}>
        {data.map((item, index) => {
          const isInput =
            index === 1 ||
            index === 2 ||
            index === 6 ||
            index === 7 ||
            index === 10 ||
            index === 11;
          const isDatePicker = index === 9;
          const isLatLong = index === 10 || index === 11;
          const isMultiple = index === 0 || index === 8;

          return (
            item && (
              <Grid key={index} item lg={index === 0 ? 9 : 3} xs={12}>
                {
                  <CustomSelect
                    error={props.required[item.name]}
                    disabled={
                      props.isCommercial && (index === 4 || index === 5)
                    }
                    isInput={isInput}
                    isDatePicker={isDatePicker}
                    name={item.name}
                    label={item.label}
                    value={
                      isDatePicker
                        ? dateValue
                        : isLatLong
                        ? coordinates[item.name]
                        : value[item.name]
                    }
                    multiple={isMultiple}
                    data={item.data}
                    type={item.type}
                    min={0}
                    placeholder={item.placeholder && t(item.placeholder)}
                    onChange={
                      isDatePicker
                        ? handleDateChange
                        : isLatLong
                        ? getCoordinates
                        : getValue
                    }
                    onBlur={
                      isLatLong &&
                      coordinates["longitude"] &&
                      coordinates["latitude"]
                        ? handleMapAction
                        : () => {}
                    }
                  />
                }
              </Grid>
            )
          );
        })}
      </Grid>
      <CustomText className="property-informations-note">
        {t("tooltipMap")}
      </CustomText>
      <CustomText className="map-text">{t("map")}</CustomText>
      <div
        ref={mapRef}
        style={{ height: mapHeight }}
        className="render-map-container"
      >
        <div className="render-map" id="render-map"></div>
      </div>
    </CustomCard>
    </LazyLoad>
  );
}

export default SellHomePropertyInfomation;
