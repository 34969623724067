import {
  Avatar,
  Badge,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  MenuItem,
  MenuList,
  Popover,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import Logo from "../assets/images/Logo-Footer.svg";
import Person from "../assets/icons/person.svg";
import VI from "../assets/icons/Vietnam-Flag.png";
import EN from "../assets/icons/English-Flag.png";
import { ReactComponent as Notification } from "../assets/icons/Notification.svg";
import { ReactComponent as Heart } from "../assets/icons/Heart.svg";
import { ReactComponent as Bookmark } from "../assets/icons/Bookmark.svg";
import { useTranslation } from "react-i18next";
import { CustomText } from "./styled-custom";
import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "@mui/icons-material";
import {
  appLoading,
  changeLanguage,
  currentLanguage,
  getToken,
  getUser,
} from "../helpers/utils";
import Cookies from "js-cookie";
import AuthDialog from "../views/auth/auth-dialog";
import StyledVariables from "../helpers/materials/_variables";
import AppModal, { SearchingPropertyType } from "../apis/app-modal";
import DialogComponent from "./dialog-component";
import { AppContext } from "../context/app-context";
import MyAccountRepositories from "../views/auth/my-account/controllers/my-account-repositories";
import moment from "moment";
import { AppActionStates, appSetState } from "../context/states";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MasterData from "../apis/master-data";
import axiosClient from "../apis/axios-client";
import React from "react";
import { CookieName } from "../types/cookie.types";

type CustomizedState = {
  id: string;
};

type HeaderBarType = {
  isHomePage?: boolean;
  setIndex?: (e: number) => void;
  transactionId?: string;
  isOpenAuthDialog?: boolean;
  setIsOpenAuthDialog?: (e: boolean) => void;
  isCommercial?: boolean;
  isopen?: boolean;
  setSignInModel?: (e: boolean) => void;
};

function HeaderBar(props: HeaderBarType) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElMobile, setAnchorElMobile] =
    useState<HTMLButtonElement | null>(null);
  const [anchorElAccount, setAnchorElAccount] =
    useState<HTMLButtonElement | null>(null);
  const [openDialog, setOpenSignIn] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const openMobile = Boolean(anchorElMobile);
  const openAccount = Boolean(anchorElAccount);
  const [currencyValue, setCurrencyValue] = useState<string>(
    MasterData.currencyValue
  );

  var notiCount = 0;
  const id = open ? "simple-popover" : undefined;
  const idMobile = openMobile ? "simple-popover" : undefined;
  const idAccount = openAccount ? "simple-popover" : undefined;
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const user = getUser();

  const token = getToken();

  const [lang, setLang] = useState<string>(Cookies.get("i18next") || "en");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openAuthDialog, setOpenAuthDialog] = useState<boolean>(false);
  const [menuIndex, setMenuIndex] = useState<number>();
  const [isCommercial, setCommercial] = useState<boolean>();
  const [noti, setNoti] = useState<any>({});

  if (state.userInfo && state.userInfo.firstName !== "") {
    user.personalInformation = state.userInfo;
  }

  if (state.count && state.count !== 0) {
    notiCount = state.count;
  }
  const iconsColor: string[] = useMemo(
    () => [
      "rgba(239, 182, 26, 1)",
      "rgba(255, 25, 25, 1)",
      "rgba(106, 165, 16, 1)",
    ],
    []
  );
  const [menuBgOpacity, setMenuBgOpacity] = useState<number>(
    props.isHomePage ? 0 : 1
  );
  const [menuTextColor, setMenuTextColor] = useState<number>(
    props.isHomePage ? 255 : 0
  );
  const [menuNotiIconColor, setMenuNotiIconColor] = useState<string>(
    props.isHomePage ? "rgba(255, 255, 255, 1)" : iconsColor[0]
  );
  const [menuHeartIconColor, setMenuHeartIconColor] = useState<string>(
    props.isHomePage ? "rgba(255, 255, 255, 1)" : iconsColor[1]
  );
  const [menuBookmarkIconColor, setMenuBookmarkIconColor] = useState<string>(
    props.isHomePage ? "rgba(255, 255, 255, 1)" : iconsColor[2]
  );
  const [shadowOpacity, setShadowOpacity] = useState<number>(
    props.isHomePage ? 0 : 0.16
  );
  const handleClickMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMobile(event.currentTarget);
  };
  const handleClickAccount = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAccount(event.currentTarget);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    getNoti();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseMobile = () => {
    setAnchorElMobile(null);
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };
  const menuList: any[] = [
    { text: "homesForSale", path: "/property" },
    { text: "homesForrent", path: "/property" },
    { text: "exclusiveProject", path: "/project" },
    { text: "commercialProperties", path: "/commercial" },
    { text: "news", path: "/news" },
    { text: "contactUs", path: "/contact-us" },
    { text: "adventureTour" },
    <div className="icon-group">
      <div onClick={() => navigateAccount(3)}>
        <Badge color="success" badgeContent={notiCount}>
          <Button aria-describedby={id} onClick={handleClick}>
            <Notification fill={menuNotiIconColor} width={24} height={24} />
          </Button>
        </Badge>
      </div>

      <div onClick={() => navigateAccount(1)}>
        <Button>
          <Heart fill={menuHeartIconColor} width={24} height={24} />
        </Button>
      </div>

      <div onClick={() => navigateAccount(2)}>
        <Button>
          <Bookmark fill={menuBookmarkIconColor} width={24} height={24} />
        </Button>
      </div>
      {token ? (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Typography>
            <div className="pt16 pb12 textCenter gray">{t("notification")}</div>
            <div className="pointer">
              {noti.items?.map((item: any, index: number) => (
                <div
                  onClick={() => unSeen(item.id)}
                  className={
                    "dFlex justifyBetween h-noti py12 border-t-noti py8  w-noti " +
                    (item.isSeen ? "bg-noti-unseen" : "bg-noti-seen")
                  }
                >
                  <a href={item.link} className="dFlex px8">
                    <div className="dFlex flexCol justifyCenter dot-noti ">
                      <Badge
                        color="success"
                        variant="dot"
                        invisible={item.isSeen}
                      >
                        <Button id="abc" className="p0">
                          <Notification
                            fill={StyledVariables.colors.red400}
                            width={48}
                            height={48}
                          />
                        </Button>
                      </Badge>
                    </div>
                    <div className="pl16 dFlex flexCol justifyCenter">
                      <CustomText className="semiBold whiteSpaceNowrap threedot">
                        {currentLanguage.isEN ? item.title : item.titleVi}
                      </CustomText>
                      <CustomText className="fontSP whiteSpaceNowrap threedot py8">
                        {currentLanguage.isEN ? item.content : item.contentVi}
                      </CustomText>
                      <CustomText className="gray whiteSpaceNowrap threedot fontSP">
                        {moment(item.postedTime).format("DD/MM/YYYY")}
                      </CustomText>
                    </div>
                  </a>
                </div>
              ))}
              {noti.totalCount === 0 ? (
                <CustomText className="textCenter bold h-noti py8 py12 border-t-noti">
                  {t("noNoti")}
                </CustomText>
              ) : (
                ""
              )}
            </div>
            <div className="py12 textCenter h-noti py12 border-t-noti py8  w-noti greenLight border-t-noti semiBold">
              <Link
                className="greenLight"
                // onClick={() => navigateAccount(3)}
                to={token ? "/my-account" : ""}
                state={{ tabIndex: 3 }}
              >
                {t("wiewAll")}
              </Link>
            </div>
          </Typography>
        </Popover>
      ) : (
        ""
      )}
    </div>,
  ];

  const unSeen = async (id: any) => {
    var ids = [];
    ids.push(id);
    const res = await MyAccountRepositories.seenNoti(ids);
    if (res.succeeded) {
      getCountNoti();
    }
    getNoti();
  };
  const closeDrawer = () => {
    setOpenDrawer(false);
  };
  const handleMyAccount = () => {
    setOpenDrawer(false);
    navigateAccount(3);
  };
  const onCloseDialogSignIn = () => {
    setOpenSignIn(false);
    if (props.setSignInModel) {
      props.setSignInModel(false);
    }
    // setTimeOutToken(false)
  };
  async function getCountNoti() {
    const res = await MyAccountRepositories.getCountNoti(false);
    state.count = res;
    dispatch(appSetState(AppActionStates.NOTI, state.count));
  }

  async function getNoti() {
    let val = {
      pageNumber: 1,
      pageSize: 4,
    };
    const res = await MyAccountRepositories.getNoti(val);
    setNoti(res);
  }

  const navigateAccount = async (tabIndex: any) => {
    const token = getToken() !== null;
    if (token) {
      if (tabIndex === 3 && location.pathname === "/my-account") {
        navigate("/my-account", { state: { tabIndex: tabIndex } });
      }
      if (tabIndex === 1 || tabIndex === 2) {
        navigate("/my-account", { state: { tabIndex: tabIndex } });
      }
      setOpenDrawer(false);
      window.scrollTo(0, 0);
    } else {
      setOpenSignIn(true);
    }
  };

  const navigateNoti = async (tabIndex: any) => {
    const token = getToken() !== null;

    if (token) {
      navigate("/my-account", { state: { tabIndex: tabIndex } });
    }
  };
  useEffect(() => {
    const token = getToken() !== null;
    if (token) {
      getCountNoti();
    }
    // getCountNoti(); no thừa
    let _commercial = false;
    if (location.pathname === "/sell-commercial") {
      _commercial = true;
    }
    let _menuIndex = -1;
    const state = location.state as CustomizedState;
    if (location.pathname.includes("/property")) {
      if (props.transactionId === "001" || (state && state.id === "001")) {
        _menuIndex = 1;
      }
      if (props.transactionId === "002" || (state && state.id === "002")) {
        _menuIndex = 0;
      }
    } else if (location.pathname.includes("/commercial")) {
      // if (
      //   (state && (state.id === "004" || state.id === "005")) ||
      //   props.transactionId === "004" ||
      //   props.transactionId === "005"
      // ) {
      // }
      _menuIndex = 3;
      if (props.setIndex) {
        props.setIndex(3);
      }
    } else if (location.pathname.includes("/project")) {
      // GlobalVariables.projectPageNum = 1;
      _menuIndex = 2;
    } else if (location.pathname.includes("/news")) {
      _menuIndex = 4;
    } else if (location.pathname === "/contact-us") {
      _menuIndex = 5;
    }

    setMenuIndex(_menuIndex);
    setCommercial(_commercial);

    if (props.isHomePage) {
      window.addEventListener("scroll", handleScroll, false);
      return () => window.removeEventListener("scroll", handleScroll, false);
    }

    if (props.isOpenAuthDialog) {
      setOpenAuthDialog(props.isOpenAuthDialog);
      setType("signIn");
    }

    if (props.isopen === true) {
      setOpenSignIn(true);
    }
  }, [props.isOpenAuthDialog, props.isopen]);
  // const token = getToken() !== null;

  // useEffect(() => {

  //   if (AppModal.isLogin.login) {
  //     setOpenAuthDialog(true);
  //     setType("signIn");
  //   }

  // },[AppModal.isLogin.login]);
  const toggleDrawer = (type: boolean) => {
    setOpenDrawer(type);
    setAnchorElMobile(null);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 255) {
      setMenuBgOpacity(1);
      setMenuTextColor(0);
      setMenuNotiIconColor(iconsColor[0]);
      setMenuHeartIconColor(iconsColor[1]);
      setMenuBookmarkIconColor(iconsColor[2]);
      setShadowOpacity(0.16);
    } else {
      const opacity = position / 255;
      const shadow = position * (0.16 / 510);
      const notiR = 255 - position * (1 / (255 / (255 - 239)));
      const notiG = 255 - position * (1 / (255 / (255 - 182)));
      const notiB = 255 - position * (1 / (255 / (255 - 26)));

      const heartR = 255 - position * (1 / (255 / (255 - 255)));
      const heartG = 255 - position * (1 / (255 / (255 - 25)));
      const heartB = 255 - position * (1 / (255 / (255 - 25)));

      const bookmarkR = 255 - position * (1 / (255 / (255 - 106)));
      const bookmarkG = 255 - position * (1 / (255 / (255 - 165)));
      const bookmarkB = 255 - position * (1 / (255 / (255 - 16)));

      setMenuBgOpacity(opacity);
      setMenuTextColor(255 - position);
      setMenuNotiIconColor(`rgba(${notiR}, ${notiG}, ${notiB}, 1)`);
      setMenuHeartIconColor(`rgba(${heartR}, ${heartG}, ${heartB}, 1)`);
      setMenuBookmarkIconColor(
        `rgba(${bookmarkR}, ${bookmarkG}, ${bookmarkB}, 1)`
      );
      setShadowOpacity(shadow);
    }
  };

  const handleChangeUnit = async (e: any) => {
    appLoading.show();
    const currencyCode = e.target.value;
    const res: any = await axiosClient.get(
      `https://currency-exchange.p.rapidapi.com/exchange?from=USD&to=${currencyCode}&q=1.0`,
      {
        headers: {
          "X-RapidAPI-Key": "825559b860msh6ee3c9f9fad4855p15c90ejsnd265e56c10a1",
          "X-RapidAPI-Host": "currency-exchange.p.rapidapi.com"
        },
      }
    );

    MasterData.currencyRate = res;
    MasterData.currencyValue = currencyCode;
    sessionStorage.setItem("currency", currencyCode);
    sessionStorage.setItem("currencyRate", res);
    setCurrencyValue(currencyCode);
    appLoading.dismiss();
    window.location.reload();
  };

  const handleChangeLanguage = (e: SelectChangeEvent<string>) => {
    const targetValue = e.target.value;
    if (targetValue !== lang) {
      setLang(targetValue);
      changeLanguage(targetValue);
    }
  };

  const onNavigate = (path: string, index: number) => {
    setMenuIndex(index);

    let id;
    let isManyCommercialProperty = false;
    if (index === 0) {
      id = "002";
    } else if (index === 1) {
      id = "001";
    } else if (index === 2) {
      id = "003";
    } else if (index === 3) {
      id = "004";
      isManyCommercialProperty = true;
    } else if (index === 6) {
      window.location.href = "http://vietnamadventuretours.ca/";
    }
    // switch (index) {
    //   case (index = 0):
    //     id = "002";
    //     break;
    //   case (index = 1):
    //     id = "001";
    //     break;
    //   case (index = 2):
    //     id = "003";
    //     break;
    //   case (index = 3):
    //     id = "004";
    //     break;
    //   case (index = 6):
    //     break;
    //   default:
    //     id = "";
    // }
    navigate(path, {
      state: { id: id, isManyCommercialProperty: isManyCommercialProperty },
    });
    window.location.reload();
    if (index === 3) {
      if (props.setIndex) {
        props?.setIndex(3);
      }
    } else {
      if (props.setIndex) {
        props?.setIndex(0);
      }
    }
  };

  const onSellProperty = (path: string) => {
    const token = getToken() !== null;
    if (token) {
      navigate(path);
      window.scrollTo(0, 0);
    } else {
      setOpenSignIn(true);
    }
  };
  const [type, setType] = useState<"signUp" | "signIn">("signUp");

  const setDialogSignIn = () => {
    setOpenSignIn(false);
    setOpenAuthDialog(true);
    setType("signIn");
  };

  const setDialogSignUp = () => {
    setOpenSignIn(false);
    setType("signUp");
    setOpenAuthDialog(true);
  };

  const onSignOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove(CookieName.email);
    Cookies.remove(CookieName.password);
    navigate("/", { replace: true });
    window.location.reload();
  };

  const clearData = () => {
    AppModal.searchingPropertyModel = {} as SearchingPropertyType;
    navigate("/", { replace: true });
    window.location.reload();
  };
  const signIn = () => {
    setOpenAuthDialog(true);
    setType("signIn");
    setOpenSignIn(false);
  };
  const onCloseAuthDialog = () => {
    setOpenSignIn(false);
    if (props.setSignInModel) {
      props.setSignInModel(false);
    }
    setOpenAuthDialog(false);
    props.setIsOpenAuthDialog && props.setIsOpenAuthDialog(false);
  };

  return (
    <>
      <div className="header">
        <div className="header-bar">
          <Container>
            <img className="logo" src={Logo} alt="" onClick={clearData} />
            <div className="header-right desktop-mode">
              <Select
                className="unit-select"
                value={currencyValue}
                onChange={handleChangeUnit}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {MasterData.currency && MasterData.currency.map((item: any, index: number) => (
                  <MenuItem key={index} value={item.notation}>
                    {item.notation !== "USDT" && <> {item.notation}</>}
                  </MenuItem>
                ))}
              </Select>
              <div className="user-avatar">
                <Avatar
                  className="user-avatar"
                  alt="User Avatar"
                  src={
                    (token && user?.personalInformation?.avatarUrl) || Person
                  }
                />
                {user &&
                user.personalInformation &&
                token &&
                (user.personalInformation.firstName ||
                  user.personalInformation.lastName) ? (
                  <div>
                    <Button
                      aria-describedby={idAccount}
                      variant="contained"
                      onClick={handleClickAccount}
                      className="user-name"
                    >
                      <CustomText className="white ml12">
                        {user.personalInformation.firstName +
                          " " +
                          user.personalInformation.lastName}
                      </CustomText>
                      {openAccount ? (
                        <ArrowDropUpIcon />
                      ) : (
                        <ArrowDropDownIcon />
                      )}
                      {/* <Button className="sign-out-btn" onClick={onSignOut}>
                        {t("signOut")}
                      </Button> */}
                    </Button>
                    <Popover
                      id={idAccount}
                      open={openAccount}
                      anchorEl={anchorElAccount}
                      onClose={handleCloseAccount}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        <Link
                          onClick={() => window.scrollTo(0, 0)}
                          to={"/my-account"}
                        >
                          <CustomText className="item-account bold px8 py8">
                            {t("myAccount")}
                          </CustomText>
                        </Link>
                        <Button
                          className="sign-out-btn bold"
                          onClick={onSignOut}
                        >
                          <CustomText className="item-account bold px8 py8">
                            {t("signOut")}
                          </CustomText>
                        </Button>
                      </Typography>
                    </Popover>
                  </div>
                ) : (
                  <Button className="sign-in-btn" onClick={signIn}>
                    <CustomText className="white">{t("signIn")}</CustomText>
                  </Button>
                )}
              </div>
              <div className="sell-btn">
                {isCommercial || menuIndex === 3 || props.isCommercial ? (
                  <Button
                    variant="outlined"
                    onClick={() => onSellProperty("/sell-commercial")}
                  >
                    {t("listYourProperty")}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => onSellProperty("/sell-home")}
                  >
                    {t("listYourProperty")}
                  </Button>
                )}
              </div>
              <Select
                className="language-select"
                value={lang}
                onChange={handleChangeLanguage}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="vi">
                  <div className="flag-icon">
                    <img src={VI} alt="VI Flag" />
                  </div>
                </MenuItem>
                <MenuItem value="en">
                  <div className="flag-icon">
                    <img src={EN} alt="VI Flag" />
                  </div>
                </MenuItem>
              </Select>
            </div>
            <div className="header-right mobile-mode">
              <Button
                className="menu-button"
                onClick={() => toggleDrawer(true)}
              >
                <Menu />
              </Button>
              <Drawer
                className="menu-drawer-container"
                anchor="right"
                open={openDrawer}
                onClose={() => toggleDrawer(false)}
              >
                <div className="menu-drawer">
                  <div className="avatar-group">
                    <Avatar
                      src={user?.personalInformation?.avatarUrl || Person}
                      sx={{ width: 68, height: 68 }}
                    />
                    {user &&
                    user.personalInformation &&
                    (user.personalInformation.firstName ||
                      user.personalInformation.lastName) ? (
                      <div className="user-name">
                        <Button
                          aria-describedby={id}
                          variant="contained"
                          onClick={handleClickMobile}
                        >
                          <CustomText className="white ml12">
                            {user.personalInformation.firstName +
                              " " +
                              user.personalInformation.lastName}
                          </CustomText>
                        </Button>
                        <Popover
                          id={idMobile}
                          open={openMobile}
                          anchorEl={anchorElMobile}
                          onClose={handleCloseMobile}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Typography sx={{ p: 2 }}>
                            <Link
                              onClick={handleMyAccount}
                              to={"/my-account"}
                              state={{ tabIndex: 0 }}
                            >
                              <CustomText className="item-account px8 py8">
                                {t("accountInfomation")}
                              </CustomText>
                            </Link>
                            <Link
                              onClick={closeDrawer}
                              to={"/my-account"}
                              state={{ tabIndex: 1 }}
                            >
                              <CustomText className="item-account px8 py8">
                                {t("myFavourites")}
                              </CustomText>
                            </Link>
                            <Link
                              onClick={closeDrawer}
                              to={"/my-account"}
                              state={{ tabIndex: 2 }}
                            >
                              <CustomText className="item-account px8 py8">
                                {t("mySavedSearchs")}
                              </CustomText>
                            </Link>
                            <Link
                              onClick={closeDrawer}
                              to={"/my-account"}
                              state={{ tabIndex: 3 }}
                            >
                              <CustomText className="item-account px8 py8">
                                {t("notification")}
                              </CustomText>
                            </Link>
                            <Link
                              onClick={closeDrawer}
                              to={"/my-account"}
                              state={{ tabIndex: 4 }}
                            >
                              <CustomText className="item-account px8 py8">
                                {t("changePassword")}
                              </CustomText>
                            </Link>

                            <Link
                              onClick={closeDrawer}
                              to={"/my-account"}
                              state={{ tabIndex: 5 }}
                            >
                              <CustomText className="item-account px8 py8">
                                {t("listingManagement")}
                              </CustomText>
                            </Link>

                            <CustomText
                              className="item-account px8 py4"
                              onClick={onSignOut}
                            >
                              {t("signOut")}
                            </CustomText>
                          </Typography>
                        </Popover>
                      </div>
                    ) : (
                      <CustomText
                        className="sign-in-btn white"
                        onClick={signIn}
                      >
                        {t("signIn")}
                      </CustomText>
                    )}
                  </div>
                  <div className="icon-group">
                    <div onClick={() => navigateNoti(3)}>
                      <Badge color="success" badgeContent={notiCount}>
                        <Button>
                          <Notification fill={menuNotiIconColor} />
                        </Button>
                      </Badge>
                    </div>

                    <div onClick={() => navigateAccount(1)}>
                      <Button>
                        <Heart />
                      </Button>
                    </div>

                    <div onClick={() => navigateAccount(2)}>
                      <Button>
                        <Bookmark />
                      </Button>
                    </div>
                  </div>
                  <div className="borderNone">
                    {isCommercial || menuIndex === 3 || props.isCommercial ? (
                      <Button
                        className="sell-btn"
                        variant="outlined"
                        onClick={() => onSellProperty("/sell-commercial")}
                      >
                        {t("listYourProperty")}
                      </Button>
                    ) : (
                      <Button
                        className="sell-btn"
                        variant="outlined"
                        onClick={() => onSellProperty("/sell-home")}
                      >
                        {t("listYourProperty")}
                      </Button>
                    )}
                  </div>
                  <div className="select-group">
                    <div className="select-item">
                      <CustomText>{t("currency")}:</CustomText>
                      <Select
                        className="select-btn"
                        value={currencyValue}
                        onChange={handleChangeUnit}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {MasterData.currency && MasterData.currency.map((item: any, index: number) => (
                          <MenuItem key={index} value={item.notation}>
                            {item.notation}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="select-item mt12">
                      <CustomText>{t("language")}:</CustomText>
                      <Select
                        className="select-btn"
                        value={lang}
                        onChange={handleChangeLanguage}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="vi">
                          <div className="flag-icon">
                            <img src={VI} alt="VI Flag" />
                          </div>
                        </MenuItem>
                        <MenuItem value="en">
                          <div className="flag-icon">
                            <img src={EN} alt="VI Flag" />
                          </div>
                        </MenuItem>
                      </Select>
                    </div>
                  </div>
                  <List className="menu-list">
                    <Divider />
                    {menuList.map(
                      (el, index) =>
                        index < menuList.length - 1 && (
                          <div key={index}>
                            <ListItem
                              className="menu-item"
                              button
                              onClick={() => onNavigate(el.path, index)}
                            >
                              {index !== 6 ? (
                                <CustomText
                                  style={{
                                    color:
                                      menuIndex === index
                                        ? `rgba(${menuTextColor}, ${menuTextColor}, ${menuTextColor}, 1)`
                                        : `white`,
                                  }}
                                >
                                  {t(el.text)}
                                </CustomText>
                              ) : (
                                <a
                                  className="linkvietnamhomes"
                                  style={{
                                    color:
                                      menuIndex === index
                                        ? `rgba(${menuTextColor}, ${menuTextColor}, ${menuTextColor}, 1)`
                                        : `white`,
                                  }}
                                  href="http://vietnamadventuretours.ca/"
                                >
                                  {t(el.text)}
                                </a>
                              )}
                            </ListItem>
                            <Divider />
                          </div>
                        )
                    )}
                  </List>
                </div>
              </Drawer>
            </div>
          </Container>
        </div>
        <div
          className="header-menu-container"
          style={{
            backgroundColor: `rgba(255, 255, 255, ${menuBgOpacity})`,
            boxShadow: `0 0 15px 0 rgba(0, 0, 0, ${shadowOpacity})`,
          }}
        >
          <Container className="header-menu">
            <MenuList>
              {menuList.map((el, index) => (
                <MenuItem
                  className="menu-item"
                  style={{
                    borderColor: `rgba(${menuTextColor}, ${menuTextColor}, ${menuTextColor}, 1)`,
                  }}
                  key={index}
                  onClick={() => onNavigate(el.path, index)}
                >
                  {index < menuList.length - 1 ? (
                    <CustomText
                      style={{
                        color:
                          menuIndex === index
                            ? StyledVariables.colors.greenLight
                            : `rgba(${menuTextColor}, ${menuTextColor}, ${menuTextColor}, 1)`,
                        textDecoration:
                          menuIndex === index ? "underline" : "none",
                      }}
                    >
                      {t(el.text)}
                    </CustomText>
                  ) : (
                    el
                  )}
                </MenuItem>
              ))}
            </MenuList>
          </Container>
        </div>
      </div>
      <AuthDialog
        type={type}
        open={openAuthDialog}
        onClose={() => onCloseAuthDialog()}
      />
      <DialogComponent
        open={openDialog}
        onClose={() => onCloseDialogSignIn()}
        className="save-search-bar-dialog"
      >
        <CustomText className="title-save">
          {t("signInRequired")} <hr className="mb20 mt28" />
        </CustomText>

        <CustomText className="title-message">
          {t("pleaseRegisterOrSignInToContinue")}
        </CustomText>
        <Grid
          container
          spacing={2}
          className="dFlex justifyCenter dialog-save-search-button mt20 content-sign"
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="dFlex directionColumn h100 w100 verticalCenter justifyBetween">
              <CustomText className="txt-account">
                {t("createAFreeAccount")}
              </CustomText>
              <Button
                className="sign-up"
                variant="outlined"
                onClick={() => setDialogSignUp()}
              >
                {t("signUp")}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="dFlex directionColumn h100 w100 verticalCenter justifyBetween">
              <CustomText className="txt-rerister">
                {t("alreadyReristered")}
              </CustomText>
              <Button
                className="sign-in"
                variant="outlined"
                onClick={() => setDialogSignIn()}
              >
                {t("signIn")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogComponent>
      <div className={`header-spacing ${props.isHomePage ? "" : "child"}`} />
    </>
  );
}

export default HeaderBar;
